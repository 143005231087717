import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  height: 280,
  background: "#17181b",
  padding: "20px",
  fontSize: "10px",
  borderRadius: "5px",
};

export default function TransectionsPopup({
  text,
  id,
  topup,
  hash,
  currency,
  time,
  message,
  type,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  return (
    <div>
      <button className="text_color justified" onClick={handleOpen}>
        <span>{text}</span>
        <span className="justified">
          <ChevronRightIcon
            style={{
              height: "0.8em",
              width: "0.8em",
            }}
          />
        </span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="state-section width justified"
          >
            <div className="state-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>
                {t("header.wallet.transactionTab.status")}
              </span>
            </div>
            <div className="state-title-data secondary_text width-80">
              <span
                style={{
                  color: "#3bc117",
                  fontSize: "14px",
                }}
              >
                {message}
              </span>
            </div>
          </div>

          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="Txid-section width justified"
          >
            <div className="Txid-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>Txid</span>
            </div>
            <div className="Txid-title-data secondary_text width-80">
              <span style={{ fontSize: "14px" }}>{hash ? hash : "NA"}</span>
            </div>
          </div>

          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="order-id-section width justified"
          >
            <div className="order-id-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>
                {t("header.wallet.transactionTab.orderId")}
              </span>
            </div>
            <div className="order-id-title-data secondary_text width-80">
              <span style={{ fontSize: "14px" }}>{id}</span>
            </div>
          </div>

          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="currency-section width justified"
          >
            <div className="currency-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>
                {t("header.wallet.transactionTab.currency")}
              </span>
            </div>
            <div className="currency-title-data secondary_text width-80">
              <span style={{ fontSize: "14px" }}>
                {currency === "bcd" ? "bcw" : currency}
              </span>
            </div>
          </div>

          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="quantity-section width justified"
          >
            <div className="quantity-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>
                {t("header.wallet.transactionTab.quantity")}
              </span>
            </div>
            <div className="quantity-title-data secondary_text width-80">
              <span style={{ fontSize: "14px" }}>{topup}</span>
            </div>
          </div>

          <div
            style={{
              // height: "45px",
              marginBottom: "15px",
            }}
            className="time-section width justified"
          >
            <div className="time-title secondary_text width-40">
              <span style={{ fontSize: "14px" }}>
                {t("header.wallet.transactionTab.time")}
              </span>
            </div>
            <div className="time-title-data secondary_text width-80">
              <span style={{ fontSize: "14px" }}>{time}</span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
