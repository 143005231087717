import { useState, useContext } from "react";
import "./Data.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { betsControl } from "../../../Contexts/Bets";
import { constants } from "../../../../store/constants";
const Data = () => {
  const { bets, cashoutCount, betsCount, indices } = useContext(betsControl);
  const game_state = useSelector((state) => state.game?.game_state);
  const [betUpdated, setBetsUpdated] = useState(false);
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED) {
      Object.values(bets).map((betIndex) => {
        if (!betIndex?.cash_out) {
          betIndex.cash_out = "bang";
          betIndex.profit = betIndex.amount;
        }
        return betIndex;
      });
      betUpdated ? setBetsUpdated(false) : setBetsUpdated(true);
    }
  }, [game_state]);

  const { t } = useTranslation();
  return (
    <div className="playeres-data-main">
      <div className="players-data-inner">
        <div className="players-data-content">
          <div className="players-data-content-container justify">
            <div className="head-inner my_row_item">
              <div className="betData-head-left">
                <div className="player-name text-left">
                  <p className="padding-left-15">{t("allbets.player")}</p>
                </div>
                <div className="cashout">
                  <p>{t("allbets.cashout")}</p>
                </div>
                <div className="ammount">
                  <p>{t("allbets.amount")}</p>
                </div>
                <div className="profit text-right">
                  <p className="padding-right-15">{t("allbets.profit")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="players-game-data">
            <div style={{ overflow: "auto" }} className="bets-main-container">
              <div className="bets-inner-conteainer">
                {indices.map((betIndex) => {
                  let individual = bets[betIndex];
                  if (individual) {
                    return (
                      <div key={betIndex} className="individual-player-main">
                        <div className="individual-player-inner">
                          <div className="individual-player-left-container">
                            <div className="individual-player-NameImg-container my_row padding-left-15 padding-right-15">
                              <div className="individual-player-name-container center_align">
                                <div className="individual-player-img-container individualPlayer-imgs">
                                  <img
                                    src={individual?.user_img}
                                    alt="UserProfile"
                                  />
                                </div>
                                <p>
                                  {individual?.username?.substring(0, 10)}
                                  {individual?.username?.length > 10 && "..."}
                                </p>
                              </div>
                              <div className="individual-player-betStatus-container justified">
                                <p>
                                  {individual?.cash_out == "1" ||
                                  individual?.cash_out == "0"
                                    ? t("allbets.betting")
                                    : individual?.cash_out != "bang"
                                    ? individual?.cash_out + "x"
                                    : individual?.cash_out}
                                </p>
                              </div>
                              <div className="indivual-player-coinAmmount-containe my_row">
                                <div className="individual-player-coin-container individualPlayer-imgs justified">
                                  <img
                                    src={`/imgs/AcceptedNetworks/${individual?.network_id}.webp`}
                                    alt="Bet Coin"
                                  />
                                </div>
                                &nbsp;
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                  className="individual-player-ammount-container justified"
                                >
                                  {individual?.amount}
                                </div>
                              </div>
                              <div className="individual-player-profit-container justified">
                                {individual?.cash_out == "1" ||
                                individual?.cash_out == "0" ? (
                                  t("allbets.betting")
                                ) : (
                                  <>
                                    <img
                                      className="hide-on-500px"
                                      style={{
                                        height: "1.2em",
                                        width: "1.2em",
                                        marginRight: "5px",
                                      }}
                                      src={`/imgs/AcceptedNetworks/${individual?.network_id}.webp`}
                                      alt={individual?.network_id}
                                    />

                                    <span
                                      className={`${
                                        individual?.cash_out === "bang"
                                          ? "rc"
                                          : "gc"
                                      } bold`}
                                      style={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {individual?.cash_out === "bang"
                                        ? "-" + individual?.profit
                                        : "+" + individual?.profit}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="betData-bottomDiv-main absolute">
            <div className="betData-bottomDiv-inner my_row">
              <div className="betData-bottomDiv-left my_row center_align">
                <div className="green-dot"></div>
                <div className="betData-platersCount">
                  {cashoutCount}/{betsCount}
                  {t("allbets.player")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data;
