import Router from "./router/Router";
import { useContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { multiplierContext } from "./components/Contexts/Multiplier";
import { betStatusSubscribe } from "./channels/bet_status_channel";
import LogRocket from "logrocket";
import { useSelector } from "react-redux";

const CLIENT_ID =
  "913866069123-uk191o2493bl7casbtbhj5kdhnp0cpih.apps.googleusercontent.com";
const App = () => {
  const selected_user_profile = useSelector((state) => state.user.user_profile);

  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    LogRocket.init("nkucct/bcw");
    LogRocket.identify(selected_user_profile?.user, {
      name: selected_user_profile?.username,
      email: selected_user_profile?.email,

      // Add your own custom user variables here, ie:
      subscriptionType: "pro",
    });
  }

  const {
    clientSocket,
    setMyChannel,
    setCreateBetChannel,
    setUpdateBetChannel,
    myChannel,
  } = useContext(multiplierContext);
  (async () => {
    for await (let event of clientSocket.listener("connect")) {
      setMyChannel(clientSocket.subscribe("round-state"));
      setCreateBetChannel(clientSocket.subscribe("create-bet"));
      setUpdateBetChannel(clientSocket.subscribe("update-bet"));
      setTimeout(() => {
        betStatusSubscribe();
      }, 1000);
    }
  })();
  (async () => {
    await myChannel.listener("subscribe").once();
  })();
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Router />
    </GoogleOAuthProvider>
  );
};

export default App;
