import "./MyHistory.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import UserBetHistoryAPI from "../../../../api/user_bet_history";
import { setBetHistory } from "../../../../store/user";
import { constants } from "../../../../store/constants";
import { useTranslation } from "react-i18next";

const MyHistory = () => {
  const user_bets = useSelector((state) => state.user.betHistory);
  const game_state = useSelector((state) => state.game.game_state);
  const [fetchOnce, setFetchOnce] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
    if (game_state === constants.MULTIPLIER_STOPPED || fetchOnce) {
      if (user_id) {
        UserBetHistoryAPI.getUserBetHistory()
          .then((response) => {
            dispatch(
              setBetHistory({
                betHistory: response.data.user_bets,
              })
            );
            setFetchOnce(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [game_state]);

  const { t } = useTranslation();

  return isLoading && game_state === constants.MULTIPLIER_STOPPED ? (
    <div
      style={{
        top: "190px",
        left: "50%",
      }}
      className="lds-ellipsis relative"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <div className="my-history-main-class">
      <div className="myhistory-inner-class">
        <div className="my-history-content my_row ny_row space-between">
          <div className="mybets-id-time-conatiner my_row space-between width-30">
            <div className="my-bet-id my_row justify">
              <span className="secondary_text">{t("stats.mybets.betid")}</span>
            </div>
            <div className="my-bet-price my_row justify">
              <span className="secondary_text">{t("stats.mybets.bet")}</span>
            </div>
          </div>

          <div className="my-bet-time my_row justify hide-on-500px">
            <span className="secondary_text">{t("stats.mybets.time")}</span>
          </div>
          <div className="mybets-payout-profit-container my_row space-between width-30">
            <div className="my-bet-payout my_row justify">
              <span className="secondary_text">{t("stats.mybets.payout")}</span>
            </div>
            <div className="my-bet-profit my_row justify">
              <span className="secondary_text">{t("stats.mybets.profit")}</span>
            </div>
          </div>
        </div>

        <div
          style={{
            height: user_bets.length > 6 ? "405px" : "",
            overflowY: "auto",
          }}
          className="my-history-data-container my_row align-start"
        >
          {user_bets.length ? (
            user_bets?.map((individualHisory) => (
              <div
                style={{
                  width: "99%",
                }}
                className="my-bet-history-item my_row width space-between"
                key={individualHisory.id}
              >
                <div className="myBetsData-id-price-container my_row space-between width-30">
                  <div className="my-bet-id-container my_row justify">
                    <span className="secondary_text">
                      {individualHisory.id}
                    </span>
                  </div>

                  <div className="my-bet-price-container my_row justify space-between">
                    <span
                      className="bold white"
                      style={{
                        marginRight: "4px",
                      }}
                    >
                      {/* {individualHisory.amount.toFixed(9)} */}

                      {individualHisory.amount}
                    </span>
                    <div className="coin-icon-container">
                      <img
                        src={`/imgs/AcceptedNetworks/${individualHisory.network_id}.webp`}
                        alt={individualHisory.network_id}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-bet-time-container my_row justify hide-on-500px">
                  <span className="secondary_text">
                    {new Date(individualHisory.created_at).toLocaleTimeString()}
                  </span>
                </div>

                <div className="myBetsData-payout-profit-container my_row space-between width-30">
                  <div className="my-bet-payout-container my_row justify">
                    <span className="secondary_text">
                      {individualHisory.cash_out == 1
                        ? "0.0x"
                        : parseFloat(individualHisory.cash_out).toFixed(2) +
                          "x"}
                    </span>
                  </div>

                  <div
                    id={`${individualHisory.id}idhere`}
                    className="my-bet-profit-container my_row justify space-between"
                  >
                    <span
                      className="bold"
                      style={{
                        marginRight: "4px",

                        color:
                          individualHisory.win_status === false
                            ? "#f53202"
                            : "#3bc117",
                      }}
                    >
                      {individualHisory.win_status === true
                        ? " + " + individualHisory.profit
                        : " - " + individualHisory.amount}
                    </span>
                    <div className="coin-icon-container">
                      <img
                        src={`/imgs/AcceptedNetworks/${individualHisory.network_id}.webp`}
                        alt={individualHisory.network_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                flexDirection: "column",
                marginTop: "60px",
              }}
              className="showEmpty-containern justified width"
            >
              <div className="show-empty-img-container">
                <img
                  className="empty-max-width"
                  src="/imgs/empty.png"
                  alt="empty"
                />
              </div>

              <div className="show-empty-typo">
                <span style={{ fontSize: "12px" }} className="text_color">
                  {t("header.referral.oop")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyHistory;
