import { useState, createContext, useEffect, useContext } from "react";
import { betPriceContext } from "./BetPrice";

const modalsControl = createContext();
const ModalsControlContextProvider = ({ children }) => {
  const [mblTrends, setMblTrends] = useState(false);
  const [trendModal, setTrendsModal] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [utc, setUtc] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [verifyMailModal, setVerifyMailModal] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [playersModal, setPlayersModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [bcdModal, setBcdModal] = useState(false);
  const [wheelPrize, setWheelPrize] = useState([]);
  const [spinWheel, setSpinWheel] = useState(false);
  const [roundPlayerId, setRoundPlayerId] = useState(0);
  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [referalLinkModal, setReferalLinkModal] = useState(false);
  const [referalBonusSlide, setReferalBonusSlide] = useState(false);
  const [assetsPortfolio, setAssetsPortfolio] = useState(false);
  const [value, setValue] = useState(0);
  const [activeTab, setActiveTab] = useState("crypto");
  const { activatedCurrency } = useContext(betPriceContext);
  const [selector, setSelector] = useState(false);
  const [fiatWithdraw, setFiatWithdraw] = useState(false);
  const [fiatDepositMethod, setFiatDepositMethod] = useState(false);
  const [gettingRate, setGettingRate] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/signin") {
      setSignInOpen(true);
    } else if (window.location.pathname === "/signup") {
      setSignUpOpen(true);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (window.location.pathname === "/users/password/edit") {
      setResetPassModal(true);
    }
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/users/confirmation.json") {
      setVerifyMailModal(true);
    }
  }, []);
  useEffect(() => {
    setActiveTab(activatedCurrency.type);
  }, [walletOpen, selector]);
  return (
    <modalsControl.Provider
      value={{
        mblTrends,
        setMblTrends,
        utc,
        setUtc,
        resetPassModal,
        setResetPassModal,
        verifyMailModal,
        setVerifyMailModal,
        signInOpen,
        signUpOpen,
        setSignInOpen,
        setSignUpOpen,
        walletOpen,
        setWalletOpen,
        playersModal,
        setPlayersModal,
        trendModal,
        setTrendsModal,
        bcdModal,
        setBcdModal,
        spinWheel,
        setSpinWheel,
        wheelPrize,
        setWheelPrize,
        roundPlayerId,
        setRoundPlayerId,
        referalBonusSlide,
        setReferalBonusSlide,
        value,
        setValue,
        profileModal,
        setProfileModal,
        referalLinkModal,
        setReferalLinkModal,
        transactionsModal,
        setTransactionsModal,
        assetsPortfolio,
        setAssetsPortfolio,
        activeTab,
        setActiveTab,
        selector,
        setSelector,
        fiatWithdraw,
        setFiatWithdraw,
        fiatDepositMethod,
        setFiatDepositMethod,
        gettingRate,
        setGettingRate,
      }}
    >
      {children}
    </modalsControl.Provider>
  );
};

export { modalsControl, ModalsControlContextProvider };
