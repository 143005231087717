import { useState, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import CurrencyDropdownSelect from "../../../../../../CurrencyDropdownSelect/CurrencyDropdownSelect";
import { betPriceContext } from "../../../../../../../Contexts/BetPrice";
import ShowComponent from "../../../../../../ShowComponent/ShowComponent";

const Fiat = ({ activeWallets, walletType, handleClick }) => {
  const { optionState, setOptionState } = useContext(betPriceContext);
  const globalActiveWallet = activeWallets?.find(
    (currency) => currency.network_id == optionState
  );
  const indonesian_rupiah = activeWallets?.find(
    (currency) => currency.network_id == "indonesian_rupiah"
  );

  const activated =
    globalActiveWallet.type === "crypto"
      ? indonesian_rupiah
      : globalActiveWallet;

  const supported_deposit_methods = [
    {
      id: "idrWallet",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 20000,
      max: 50000000,
      differ: "idr",
    },
    {
      id: "idr",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 20000,
      max: 10000000,
      differ: "idr",
    },
    {
      id: "thb",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 50,
      max: 100000,
      differ: "thb",
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="Fiat-deposit-main-container">
      <CurrencyDropdownSelect activated={activated} />
      <div className="fiat-paymentMethods-container">
        <ShowComponent condition={activated.currency_configuration.deposit}>
          <div className="choose-method">
            <span className="secondary_text">{t("header.wallet.metod")}</span>
          </div>
          <div className="methods-container my_row">
            {optionState === "philippine_peso" || optionState === "thai_baht"
              ? supported_deposit_methods.map(
                  (depositMethod, index) =>
                    depositMethod.differ === "thb" && (
                      <button
                        name={depositMethod.differ}
                        id={depositMethod.id}
                        className="payment-method width-49 my_row column justified"
                        key={index}
                        onClick={(e) => handleClick(e)}
                      >
                        <div className="payment-method-img-container">
                          <img
                            name={depositMethod.differ}
                            id={depositMethod.id}
                            className="payment-method-img"
                            src={`/imgs/toppayBanks/${depositMethod.bank}.png`}
                            alt={depositMethod.method}
                          />
                        </div>

                        <ShowComponent condition={depositMethod.method}>
                          <div className="payment-method-value-conatiner">
                            <span className="text_color uppercase">
                              {depositMethod.method}
                            </span>
                          </div>
                        </ShowComponent>

                        <div
                          name={depositMethod.differ}
                          id={depositMethod.id}
                          className="payment-method-value-conatiner"
                        >
                          <span
                            name={depositMethod.differ}
                            id={depositMethod.id}
                            className="text_color uppercase"
                          >
                            {depositMethod.min} - {depositMethod.max}
                          </span>
                        </div>
                      </button>
                    )
                )
              : supported_deposit_methods.map(
                  (depositMethod, index) =>
                    depositMethod.differ === "idr" && (
                      <button
                        name={depositMethod.differ}
                        id={depositMethod.id}
                        className="payment-method width-49 my_row column justified"
                        key={index}
                        onClick={(e) => handleClick(e)}
                      >
                        <div className="payment-method-img-container">
                          <img
                            name={depositMethod.differ}
                            id={depositMethod.id}
                            className="payment-method-img"
                            src={`/imgs/toppayBanks/${depositMethod.bank}.png`}
                            alt={depositMethod.method}
                          />
                        </div>

                        <div
                          name={depositMethod.differ}
                          id={depositMethod.id}
                          className="payment-method-value-conatiner"
                        >
                          <span
                            name={depositMethod.differ}
                            id={depositMethod.id}
                            className="text_color uppercase"
                          >
                            {depositMethod.method}
                          </span>
                        </div>
                        <div
                          name={depositMethod.differ}
                          id={depositMethod.id}
                          className="payment-method-value-conatiner"
                        >
                          <span
                            name={depositMethod.differ}
                            id={depositMethod.id}
                            className="text_color uppercase"
                          >
                            {depositMethod.min} - {depositMethod.max}
                          </span>
                        </div>
                      </button>
                    )
                )}
          </div>
        </ShowComponent>
        <ShowComponent condition={!activated.currency_configuration.withdraw}>
          <span className="secondary_text orbitron-font">
            {t("header.referral.currencynotavailable")}
          </span>
        </ShowComponent>
      </div>
    </div>
  );
};

export default Fiat;
