import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Paginator from "../../../../Paginator/Paginator";
import AllDataWrapper from "../dataWrapper/AllDataWrapper";
import SwapDataWrapper from "../dataWrapper/SwapDataWrapper";

const AllSwapWrapper = ({
  children,
  AllButton,
  selectorHandle,
  SwapButton,
}) => {
  const [currentPage, handlePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setlastPage] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          marginTop: "15px",
        }}
        className="transections-history-bill-section"
      >
        <div className="transections-history-bill-section-inner">
          <div className="transections-history-bill-content my_row">
            <div
              style={{
                background: "#1a1b1e",
                height: "348px",
                boxSizing: "border-box",
                paddingTop: "60px",
                borderRadius: "3px",
              }}
              className="transections-history-bill-left width-25"
            >
              <div className="bill-left-selections container width">
                <div className="all-button-container">
                  <button
                    style={{
                      background: AllButton ? "#161616" : "transparent",
                      transition: ".5s",
                    }}
                    className="swap-all-selector selector width-90 text_color"
                    onClick={() => {
                      selectorHandle("all");
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      {t("header.wallet.transactionTab.all")}
                    </span>
                  </button>
                </div>

                <div className="swap-button-container">
                  <button
                    style={{
                      background: SwapButton ? "#161616" : "transparent",

                      transition: ".5s",
                    }}
                    className="swap-swap-selector selector width-90 text_color"
                    onClick={() => {
                      selectorHandle("swap");
                    }}
                  >
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>{t("header.wallet.swap")}</span>
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                background: "rgb(22, 22, 22)",
                height: "348px",
                borderRadius: "3px",
              }}
              className="tarnsections-history-bill-right width-75"
            >
              <div
                style={{
                  fontSize: "14px",
                  background: "#1e2024",
                  height: "41px",
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 10px",
                }}
                className="tarnsections-history-bill-right-top my_row my_row_item text_color"
              >
                <div className="tarnsections-bill-swap-type-time my_row_item_3 center_align">
                  <span>
                    {t("header.wallet.transactionTab.type")} /{" "}
                    {t("header.wallet.transactionTab.time")}
                  </span>
                </div>
                <div className="tarnsections-bill-swap-amount justified my_row_item_3 center_align">
                  <span style={{ fontSize: "14px" }}>
                    {t("header.wallet.transactionTab.time")}
                  </span>
                </div>
                <div className="tarnsections-bill-swap-balance my_row_item_3 my_row end center_align font-14-on-big-screen">
                  <span>{t("header.wallet.transactionTab.balance")}</span>
                </div>
              </div>

              <div
                style={{
                  height: "286px",
                  overflowY: "auto",
                }}
                className="tarnsections-history-bill-right-bottom"
              >
                {SwapButton ? (
                  <SwapDataWrapper
                    currentPage={currentPage}
                    handlePage={handlePage}
                    setTotalPages={setTotalPages}
                    setlastPage={setlastPage}
                  />
                ) : (
                  <AllDataWrapper
                    currentPage={currentPage}
                    handlePage={handlePage}
                    setTotalPages={setTotalPages}
                    setlastPage={setlastPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        lastPage={lastPage}
        handlePage={handlePage}
      />
    </>
  );
};

export default AllSwapWrapper;
