import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import "./CurrenciesPanel.css";
import Switch from "./Switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import UserWalletsAPI from "../../../../../api/user_wallets";
import { setDefaultWallet } from "../../../../../store/user";
import { setHideSmall } from "../../../../../store/game/index";
import { betPriceContext } from "../../../../Contexts/BetPrice";
import AssetsPortfolio from "../../../AssetsPortfolio/AssetsPortfolio";
import { modalsControl } from "../../../../Contexts/ModalsControl";
import { useTranslation } from "react-i18next";

const CurrenciesPanel = ({ isDisable, setSelector }) => {
  const { t } = useTranslation();
  const { activatedCurrency, setActivatedCurrency } =
    useContext(betPriceContext);
  const { activeTab, setActiveTab } = useContext(modalsControl);
  const dispatch = useDispatch();
  const wallets = useSelector((state) => state.user.wallets);
  const hide_Small = useSelector((state) => state.game.hide_Small);

  const activeCurrency = (id, is_usdt, currency) => {
    if (!isDisable) {
      setSelector(false);
      dispatch(
        setDefaultWallet({
          id: id,
          is_usdt: is_usdt,
        })
      );

      UserWalletsAPI.setDefaultUserWallets(id, {
        user_wallet: {
          is_usdt: currency.network_id == "usdt" ? true : false,
        },
      })
        .then((res) => {
          setActivatedCurrency(
            wallets?.find(
              (element) =>
                element.network_id === res.data.user_wallet.network_id
            )
          );
          setActiveTab(
            wallets?.find(
              (element) =>
                element.network_id === res.data.user_wallet.network_id
            ).type
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [focus, setFocus] = useState(false);

  const [filterId, setFilterId] = useState("");
  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };
  const handleInput = (e) => {
    let { value } = e.target;
    setFilterId(value);
  };

  const hideSmallHandle = () => {
    dispatch(
      setHideSmall({
        hide_Small: !hide_Small,
      })
    );
  };
  return (
    <div className="currency-selection-main absolute">
      <div className="currencies-selection-inner">
        <div className="search-head justified">
          <div className="ui-input search-input selection-search-field">
            <div
              className={`input-control my_row row_reverse ${
                focus ? "is-focus" : ""
              }`}
            >
              <input
                type="text"
                placeholder="Crypto | Fiat"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={filterId}
                onChange={(e) => handleInput(e)}
              />
              <SearchIcon className="selection-header-search-field" />
            </div>
          </div>
          <button className="ui-button button-normal selection-search-add-button text_color none">
            <AddIcon />
          </button>
        </div>
        <AssetsPortfolio activeCurrency={activeCurrency}>
          <div className="switches my_row">
            <Switch
              switchText={t("header.referral.hidempty")}
              onClickHandle={hideSmallHandle}
              activeStatus={hide_Small}
            />
          </div>
        </AssetsPortfolio>
      </div>
    </div>
  );
};

export default CurrenciesPanel;
