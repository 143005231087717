import { useState, useContext } from "react";
import "./fiat.css";
import { useTranslation, Trans } from "react-i18next";
import CurrencyDropdownSelect from "../../../../../../CurrencyDropdownSelect/CurrencyDropdownSelect";
import { betPriceContext } from "../../../../../../../Contexts/BetPrice";
import ShowComponent from "../../../../../../ShowComponent/ShowComponent";

const Fiat = ({ activeWallets, walletType, handleClick }) => {
  const { optionState, setOptionState } = useContext(betPriceContext);
  const globalActiveWallet = activeWallets?.find(
    (currency) => currency.network_id == optionState
  );
  const indonesian_rupiah = activeWallets?.find(
    (currency) => currency.network_id == "indonesian_rupiah"
  );

  const activated =
    globalActiveWallet.type === "crypto"
      ? indonesian_rupiah
      : globalActiveWallet;

  const supported_deposit_methods = [
    {
      bank: "toppay_bca",
      method: "BCA",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_bri",
      method: "BRI",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_bna",
      method: "BNI",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_cimb",
      method: "CIMB",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_permat",
      method: "PERMATA",
      min: 10000,
      max: 100000000,
    },
    {
      bank: "toppay_mandiri",
      method: "MANDIRI",
      min: 10000,
      max: 100000000,
    },

    {
      bank: "toppay_linkaja",
      method: "LINKAJA",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_ovo",
      method: "OVO",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_oris",
      method: "QRIS",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_dana",
      method: "DANA",
      min: 20000,
      max: 10000000,
    },
    {
      bank: "toppay_dana",
      method: "DANAMON",
      min: 10000,
      max: 100000000,
    },

    {
      bank: "toppay_alfamart",
      method: "ALFAMART",
      min: 20000,
      max: 10000000,
    },
  ];
  const supported_deposit_methods_others = [
    {
      bank: "toppay_oris",
      method: "QRIS",
      min: 1,
      max: 2000,
    },
    {
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 1,
      max: 2000,
    },
    {
      bank: "promptPay",
      method: "PROMPTPAY",
      min: 1,
      max: 2000,
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="Fiat-deposit-main-container">
      <CurrencyDropdownSelect activated={activated} />
      <div className="fiat-paymentMethods-container">
        <ShowComponent condition={activated.currency_configuration.deposit}>
          <div className="choose-method">
            <span className="secondary_text">{t("header.wallet.metod")}</span>
          </div>
          <div className="methods-container my_row">
            {optionState === "philippine_peso" || optionState === "thai_baht"
              ? supported_deposit_methods_others.map((depositMethod, index) => (
                  <button
                    name="bank"
                    className="payment-method width-49 my_row column justified"
                    id={depositMethod.method}
                    key={index}
                    onClick={(e) => handleClick(e)}
                  >
                    <div className="payment-method-img-container">
                      <img
                        id={depositMethod.method}
                        name="bank"
                        className="payment-method-img"
                        src={`/imgs/toppayBanks/${depositMethod.bank}.png`}
                        alt={depositMethod.method}
                      />
                    </div>

                    <ShowComponent condition={depositMethod.method}>
                      <div className="payment-method-value-conatiner">
                        <span className="text_color uppercase">
                          {depositMethod.method}
                        </span>
                      </div>
                    </ShowComponent>

                    <div className="payment-method-value-conatiner">
                      <span className="text_color uppercase">
                        {depositMethod.min} - {depositMethod.max}
                      </span>
                    </div>
                  </button>
                ))
              : supported_deposit_methods.map((depositMethod, index) => (
                  <button
                    name="bank"
                    className="payment-method width-49 my_row column justified"
                    id={depositMethod.method}
                    key={index}
                    onClick={(e) => handleClick(e)}
                  >
                    <div className="payment-method-img-container">
                      <img
                        id={depositMethod.method}
                        name="bank"
                        className="payment-method-img"
                        src={`/imgs/toppayBanks/${depositMethod.bank}.png`}
                        alt={depositMethod.method}
                      />
                    </div>

                    <div className="payment-method-value-conatiner">
                      <span className="text_color uppercase">
                        {depositMethod.method}
                      </span>
                    </div>
                    <div className="payment-method-value-conatiner">
                      <span className="text_color uppercase">
                        {depositMethod.min} - {depositMethod.max}
                      </span>
                    </div>
                  </button>
                ))}
          </div>
        </ShowComponent>
        <ShowComponent condition={!activated.currency_configuration.deposit}>
          <span className="secondary_text orbitron-font">
            {t("header.referral.currencynotavailable")}
          </span>
        </ShowComponent>
      </div>
    </div>
  );
};

export default Fiat;
