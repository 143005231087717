import { useState, useContext, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import UserWalletsAPI from "../../../../../api/user_wallets";
import "./Betit.css";
import BetPrice from "./BetPrice/BetPrice";
import BetAPI from "../../../../../api/bet";
import {
  setBetPlaced,
  setCashedOut,
  setBetLoading,
  setBetWallet,
} from "../../../../../store/user";
import game, { setNextRoundBetPlaced } from "../../../../../store/game";

import { constants } from "../../../../../store/constants";
import { betPriceContext } from "../../../../Contexts/BetPrice";
import { multiplierDistributor } from "../../../../Contexts/multiplierDistributor";
import {
  setCashedOutMultiplier,
  setBetAmount,
} from "../../../../../store/user";
import { setUserWallets } from "../../../../../store/user";
import {
  setLockedCashout,
  setLockedNetworkId,
  updateSingleBet,
  setLockedBetAmount,
  setBetId,
  setBetActive,
} from "../../../../../store/bet";
import { multiplierContext } from "../../../../Contexts/Multiplier";
import { modalsControl } from "../../../../Contexts/ModalsControl";
import { useTranslation } from "react-i18next";
import { betsControl } from "../../../../Contexts/Bets";
import { clientSocketContext } from "../../../../Contexts/SocketBets";

// import UserWalletWB from "../../../../../api/user_selectedWalletAmount";
// import { setWalletWB } from "../../../../../store/user";
const Betit = ({ setShowProfit }) => {
  var numberHelpers = require("number_helpers");
  const [buttonAimation, setButtonAnimation] = useState(false);

  const ButtonStyle = {
    width: "300px",
    scale: buttonAimation ? "98%" : "100%",
  };
  const locked_bet_amount = useSelector(
    (state) => state.bets.locked_bet_amount
  );
  const betActive = useSelector((state) => state.bets.betActive);

  const game_state = useSelector((state) => state.game.game_state);
  const bet_placed = useSelector((state) => state.user.betPlaced);
  const betLoading = useSelector((state) => state.user.betLoading);
  const cashed_out = useSelector((state) => state.user.cashedOut);
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  // const bets = useSelector((state) => state.bets.bets);
  const { bets, cashotCount, betsCount } = useContext(betsControl);

  const next_round_bet_placed = useSelector(
    (state) => state.game.next_round_bet_placed
  );

  const locked_auto_cashout = useSelector(
    (state) => state.bets.locked_auto_cashout
  );

  const defaultWallet = useSelector((state) => state.user.selectedWallet);

  const {
    betAmmount,
    autoCashout,
    activatedCurrency,
    autoProfit,
    setInsuficientWallet,
  } = useContext(betPriceContext);
  const { setSignInOpen } = useContext(modalsControl);
  const { multiplier, setMultiplier } = useContext(multiplierDistributor);
  const { myChannel, clientSocket } = useContext(multiplierContext);

  //User cashing out timer and multiplier management

  // const [multiplier, setMultiplier] = useState(0);
  const dispatch = useDispatch();
  const [myBet, setMyBet] = useState(bets[user_id]);

  //////////////////////////////////////////////////

  // const [myBet, setMyBet] = useState(
  //   bets?.find((bet) => bet.user_id === user_id)
  // );

  useEffect(() => {
    setMyBet(bets[user_id]);
  }, [myBet, betsCount, cashotCount]);
  // const [userCalculatedProfit, setUserCalculatedProfit] = useState(parseFloat(multiplier) * myBet?.amount);
  useEffect(() => {
    if (myBet?.status === "placed") {
      dispatch(
        setLockedBetAmount({
          locked_bet_amount: myBet.amount,
        })
      );

      dispatch(
        setLockedCashout({
          locked_auto_cashout: myBet.auto_cashout,
        })
      );

      dispatch(
        setLockedNetworkId({
          locked_network_id: myBet.network_id,
        })
      );

      dispatch(
        setBetWallet({
          network_id: myBet.network_id,
        })
      );

      dispatch(
        setBetPlaced({
          betPlaced: true,
        })
      );
      dispatch(
        setCashedOut({
          cashedOut: false,
        })
      );
    } else {
      dispatch(
        setLockedBetAmount({
          locked_bet_amount: locked_bet_amount,
        })
      );

      dispatch(
        setLockedCashout({
          locked_auto_cashout: locked_auto_cashout,
        })
      );

      dispatch(
        setBetPlaced({
          betPlaced: false,
        })
      );
      dispatch(
        setCashedOut({
          cashedOut: true,
        })
      );
    }
  }, [myBet, betsCount, cashotCount]);
  const [userCalculatedProfit, setUserCalculatedProfit] = useState(0);

  const preciseIt = (number) => {
    const preciseNumber = numberHelpers.number_with_precision(number, {
      significant: true,
      precision: 9,
    });

    return preciseNumber.substring(0, 10);
  };

  const handleCashOut = (maxProfitMultiplier) => {
    if (multiplier !== "1.00") {
      const cash_out =
        game_state === constants.MULTIPLIER_STOPPED
          ? myBet?.auto_cashout
          : multiplier;

      console.log(
        "type max profit multi ::: ",
        typeof maxProfitMultiplier,
        "type of cashout ::: ",
        typeof cash_out
      );
      clientSocket.transmit("update-bet", {
        [user_id]: {
          user_id: user_id,
          cash_out: maxProfitMultiplier
            ? maxProfitMultiplier.toFixed(2)
            : parseFloat(cash_out).toFixed(2),
          bet_id: myBet.bet_id,
          amount: locked_bet_amount,
        },
      });

      dispatch(
        setLockedBetAmount({
          locked_bet_amount: 0,
        })
      );

      dispatch(
        setLockedCashout({
          locked_auto_cashout: 0,
        })
      );

      dispatch(
        setBetPlaced({
          betPlaced: false,
        })
      );
      dispatch(
        setBetActive({
          betActive: false,
        })
      );
      dispatch(
        setCashedOut({
          cashedOut: true,
        })
      );
      dispatch(setNextRoundBetPlaced({ next_round_bet_placed: false }));
      dispatch(
        setBetActive({
          betActive: false,
        })
      );
      let multiplierToProfit = maxProfitMultiplier
        ? maxProfitMultiplier
        : cash_out;
      dispatch(
        setCashedOutMultiplier({
          cashedOutMultiplier:
            parseFloat(myBet.auto_cashout) < parseFloat(cash_out)
              ? myBet.auto_cashout
              : multiplierToProfit * parseFloat(myBet.amount),
        })
      );

      setShowProfit(true);
      setTimeout(() => {
        setShowProfit(false);
      }, 2000);

      setTimeout(() => {
        UserWalletsAPI.getUserWallets().then((response) => {
          dispatch(
            setUserWallets({
              wallets: response.data,
            })
          );
        });
      }, 3000);
    }
  };

  const betWallet = useSelector((state) => state.user.betWallet);

  const handleCreateBet = () => {
    if (!user_id) {
      setSignInOpen(true);
      return;
    }

    let priceWallet = defaultWallet;

    if (parseFloat(betAmmount) > parseFloat(priceWallet.fake_amount)) {
      setNextRoundBetPlaced();
      dispatch(
        setLockedBetAmount({
          locked_bet_amount: 0,
        })
      );
      dispatch(setNextRoundBetPlaced({ next_round_bet_placed: false }));
      dispatch(
        setBetActive({
          betActive: true,
        })
      );
      setInsuficientWallet(true);
      setTimeout(() => {
        setInsuficientWallet(false);
      }, 2000);
      return;
    }

    if (parseFloat(betAmmount) == 0) {
      return;
    }
    if (parseFloat(priceWallet.fake_amount) == 0) {
      return;
    }

    dispatch(
      setCashedOutMultiplier({
        cashedOutMultiplier: 0,
      })
    );
    dispatch(
      setBetLoading({
        betLoading: true,
      })
    );

    clientSocket.transmit("create-bet", {
      [user_id]: {
        user_id: user_id,
        amount: next_round_bet_placed
          ? parseFloat(locked_bet_amount)
          : parseFloat(betAmmount),
        auto_cashout: next_round_bet_placed ? locked_auto_cashout : autoCashout,
        is_max_bet:
          parseFloat(betAmmount) === parseFloat(activatedCurrency?.fake_amount)
            ? true
            : false,
        network_id: activatedCurrency?.network_id,
      },
    });

    dispatch(setNextRoundBetPlaced({ next_round_bet_placed: false }));
    dispatch(
      setBetActive({
        betActive: true,
      })
    );
    setTimeout(() => {
      UserWalletsAPI.getUserWallets().then((response) => {
        dispatch(
          setUserWallets({
            wallets: response.data,
          })
        );
      });
    }, 1500);
  };

  const final_multiplier = useSelector((state) => state.game.final_multiplier);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED) {
      if (!next_round_bet_placed) {
        dispatch(
          setBetActive({
            betActive: false,
          })
        );
      }
    }
    if (
      game_state === constants.MULTIPLIER_STOPPED &&
      myBet?.auto_cashout == final_multiplier
    ) {
      handleCashOut();
    }
  }, [game_state, final_multiplier]);

  useEffect(() => {
    if (
      parseFloat(multiplier) >= parseFloat(myBet?.auto_cashout) &&
      bet_placed &&
      !cashed_out
    ) {
      handleCashOut();
    }
  }, [multiplier]);

  useEffect(() => {
    // if (game_state === constants.MULTIPLIER_STARTED) {
    dispatch(
      setBetLoading({
        betLoading: false,
      })
    );
    // }
    if (game_state === constants.MULTIPLIER_STOPPED) {
      UserWalletsAPI.getUserWallets().then((response) => {
        dispatch(
          setUserWallets({
            wallets: response.data,
          })
        );
      });
    }
    if (game_state === constants.COUNTDOWN_STARTED && next_round_bet_placed) {
      let priceWallet = defaultWallet;

      if (
        parseFloat(priceWallet.fake_amount) > 0 &&
        parseFloat(priceWallet.fake_amount) >= parseFloat(betAmmount)
      ) {
        handleCreateBet();
      } else {
        dispatch(setNextRoundBetPlaced({ next_round_bet_placed: false }));
        dispatch(
          setBetActive({
            betActive: false,
          })
        );
        setInsuficientWallet(true);
        setTimeout(() => {
          setInsuficientWallet(false);
        }, 2000);
      }
    }
  }, [game_state]);

  const handleQue = () => {
    if (!user_id) {
      return;
    }
    dispatch(
      setNextRoundBetPlaced({ next_round_bet_placed: !next_round_bet_placed })
    );
    dispatch(setBetActive({ betActive: !betActive }));
    dispatch(
      setLockedBetAmount({
        locked_bet_amount: betAmmount,
      })
    );
    dispatch(
      setLockedCashout({
        locked_auto_cashout: autoCashout,
      })
    );
    dispatch(
      setLockedNetworkId({
        locked_network_id: activatedCurrency.coin,
      })
    );
    dispatch(
      setBetWallet({
        network_id: activatedCurrency.coin,
      })
    );
  };
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED && !next_round_bet_placed) {
      dispatch(
        setLockedBetAmount({
          locked_bet_amount: 0,
        })
      );
      dispatch(
        setLockedCashout({
          locked_auto_cashout: 0,
        })
      );
    }
  }, [game_state]);

  useEffect(() => {
    setUserCalculatedProfit(
      (parseFloat(multiplier) * myBet?.amount).toFixed(9)
    );
  }, [multiplier]);

  useEffect(() => {
    if (
      userCalculatedProfit - myBet?.amount >=
        activatedCurrency.currency_limit.max_profit &&
      bet_placed &&
      !cashed_out
    ) {
      handleCashOut(
        (parseFloat(myBet?.amount) +
          parseFloat(activatedCurrency.currency_limit.max_profit)) /
          parseFloat(myBet?.amount)
      );
    }
  }, [userCalculatedProfit]);

  useEffect(() => {
    setUserCalculatedProfit(0);
  }, [game_state]);
  const { t } = useTranslation();

  return (
    <div className="betit-main-class">
      <div className="betit-inner-class">
        <div className="betit-content-clas">
          <div className="betit-button-container my_row justify">
            {game_state === constants.COUNTDOWN_STARTED && !betLoading ? (
              <button
                accessKey="b"
                style={ButtonStyle}
                className="primary-btn primary-btn-bg bet-button-bubbles"
                onClick={() => {
                  handleCreateBet();
                }}
              >
                {t("gamebox.betit.placebet")}
              </button>
            ) : (
              game_state === constants.COUNTDOWN_STARTED && (
                <button
                  accessKey="b"
                  style={{
                    scale: buttonAimation ? "98%" : "100%",
                    transition: ".3s",
                  }}
                  className="primary-btn primary-btn-bg bet-button-bubbles-cancel"
                >
                  {t("gamebox.betit.loading")}
                </button>
              )
            )}
            {game_state === constants.COUNTDOWN_STOPPED && (
              <button
                accessKey="b"
                style={{
                  scale: buttonAimation ? "98%" : "100%",
                  transition: ".3s",
                }}
                className="primary-btn primary-btn-bg bet-button-bubbles-cancel"
              >
                {t("gamebox.betit.loading")}
              </button>
            )}

            {game_state === constants.MULTIPLIER_STARTED &&
              bet_placed &&
              !cashed_out &&
              (myBet?.status === "placed" ? (
                <button
                  accessKey="b"
                  style={ButtonStyle}
                  className="primary-btn primary-btn-bg bet-button-bubbles"
                  onClick={() => {
                    handleCashOut();
                  }}
                >
                  {/* <span>{activatedCurrency.coin}</span> */}
                  <div
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <span>{userCalculatedProfit}</span>
                    <span
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {myBet?.network_id == "wb"
                        ? "wb"
                        : myBet?.network_id == "ethereum"
                        ? "ETH"
                        : myBet?.network_id == "polygon"
                        ? "matic"
                        : myBet?.network_id == "solana"
                        ? "SOL"
                        : myBet?.network_id == "tron"
                        ? "TRX"
                        : myBet?.network_id == "bitcoin"
                        ? "BTC"
                        : myBet?.network_id == "usdt"
                        ? "usdt"
                        : "BCW"}
                    </span>
                  </div>
                  {t("gamebox.betit.payout")}
                </button>
              ) : (
                <button
                  accessKey="b"
                  style={ButtonStyle}
                  className="primary-btn primary-btn-bg bet-button-bubbles"
                >
                  {/* <span>{activatedCurrency.coin}</span> */}
                  <div
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <span>waiting...</span>
                  </div>
                </button>
              ))}

            {game_state === constants.MULTIPLIER_STARTED &&
              bet_placed &&
              cashed_out &&
              (!next_round_bet_placed ? (
                <button
                  accessKey="b"
                  style={ButtonStyle}
                  className="primary-btn primary-btn-bg bet-button-bubbles"
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.placebet")}
                  <br />
                  {t("gamebox.betit.nextround")}
                </button>
              ) : (
                <button
                  accessKey="b"
                  className="primary-btn primary-btn-bg bet-button-bubbles-cancel"
                  style={{
                    scale: buttonAimation ? "98%" : "100%",
                    transition: ".3s",
                  }}
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.loading")}
                  <br />
                  {t("gamebox.betit.cancel")}
                </button>
              ))}

            {game_state === constants.MULTIPLIER_STARTED &&
              !bet_placed &&
              (!next_round_bet_placed ? (
                <button
                  accessKey="b"
                  style={ButtonStyle}
                  className="primary-btn primary-btn-bg bet-button-bubbles"
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.placebet")}
                  <br />
                  {t("gamebox.betit.nextround")}
                </button>
              ) : (
                <button
                  accessKey="b"
                  className="primary-btn primary-btn-bg bet-button-bubbles-cancel"
                  style={{
                    scale: buttonAimation ? "98%" : "100%",
                    transition: ".3s",
                  }}
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.loading")}
                  <br />
                  {t("gamebox.betit.cancel")}
                </button>
              ))}
            {game_state === constants.MULTIPLIER_STOPPED &&
              (!next_round_bet_placed ? (
                <button
                  accessKey="b"
                  style={ButtonStyle}
                  className="primary-btn primary-btn-bg bet-button-bubbles"
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.placebet")}
                  <br />
                  {t("gamebox.betit.nextround")}
                </button>
              ) : (
                <button
                  accessKey="b"
                  className="primary-btn primary-btn-bg bet-button-bubbles-cancel"
                  style={{
                    scale: buttonAimation ? "98%" : "100%",
                    transition: ".3s",
                  }}
                  onClick={() => {
                    handleQue();
                  }}
                >
                  {t("gamebox.betit.loading")}
                  <br />
                  {t("gamebox.betit.cancel")}
                </button>
              ))}
            {/* {<button className="betOff-button white">Loading...</button>} */}
          </div>
          <div className="betit-price-container">
            <BetPrice />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Betit);
