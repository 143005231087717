import { useState, useContext, useEffect } from "react";
// import { betDataContext } from "../../../../../../Contexts/PlayerBetData";

import "./VaultPro.css";
import BTC from "../Deposit/Crypto/imgs/BTC.webp";
import ETH from "../Deposit/Crypto/imgs/ETH.webp";
import BNB from "../Deposit/Crypto/imgs/BNB.webp";
import TRX from "../Deposit/Crypto/imgs/TRX.webp";
import USDT from "../Deposit/Crypto/imgs/USDT.webp";

const VaultPro = () => {
  const [TransferIn, setTransferIn] = useState(true);
  const [TransferOut, setTransferOut] = useState(false);
  // const { accountBalance } = useContext(betDataContext);
  const [balanceToTransfer, setBalanceToTransfer] = useState(0);

  const TransferInHandler = () => {
    setTransferIn(true);
    setTransferOut(false);
  };
  const TransferOutHandler = () => {
    setTransferOut(true);
    setTransferIn(false);
  };
  const dropdownHandle = (e) => {
    setOptionState(e.target.value);
  };
  const MaxSetter = () => {
    setBalanceToTransfer(200);
  };
  const handleBalanceToTransfer = (e) => {
    setBalanceToTransfer(e.target.value);
  };
  const [optionState, setOptionState] = useState("BTC");

  const [imgURL, setImgURL] = useState(BTC);

  useEffect(() => {
    if (optionState === "BTC") {
      setImgURL(BTC);
    }
    if (optionState === "ETH") {
      setImgURL(ETH);
    }
    if (optionState === "BNB") {
      setImgURL(BNB);
    }
    if (optionState === "TRX") {
      setImgURL(TRX);
    }
    if (optionState === "USDT") {
      setImgURL(USDT);
    }
  }, [optionState]);
  return (
    <div
      style={{
        height: "100%",
      }}
      className="commingSoon wallet-scroll-whole"
    >
      <img
        src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg"
        alt="Coming soon"
      />
    </div>
  );
  // return (
  //   <div className="vault-pro-main-class">
  //     <div className="vault-pro-content-class">
  //       <div className="vault-pro-top-container">
  //         <div className="deposit-top-container my_row justify">
  //           <div className="deposit-top-content my_row justify">
  //             <button
  //               className={`DepositButtonGroup ${
  //                 TransferIn && "activeButtonCrypto"
  //               }`}
  //               onClick={TransferInHandler}
  //             >
  //               Transfer In
  //             </button>
  //             <button
  //               className={`DepositButtonGroup ${
  //                 TransferOut && "activeButtonCrypto"
  //               }`}
  //               onClick={TransferOutHandler}
  //             >
  //               Transfer Out
  //             </button>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="vault-pro-bottom-container">
  //         <div className="vault-bottom-typography my_row my-global">
  //           <div className="annual-percentage">
  //             <span>
  //               Annual Percentage Rate <span className="gc">5%</span>{" "}
  //             </span>
  //           </div>
  //           <div className="transfer-security-container">
  //             <span className="text_color">Security Rules</span>
  //           </div>
  //         </div>

  //         <div className="transfer-balance-container width my_row my-global">
  //           <div className="transfer-wallet-balance-indicator">
  //             <span>Wallet Balance {TransferIn ? 200 : "0"} </span>
  //           </div>
  //         </div>

  //         <div className="transfer-balance-feilds-container my-global my-global">
  //           <div className="wallet-crypto-coins-dropdown">
  //             <div className="wallet-crypto-coins-dropdown-content">
  //               <div className="wallet-crypto-dropdown-top my-global">
  //                 <span className="text_color">Deposit Currency</span>
  //                 <span className="text_color">Record</span>
  //               </div>

  //               <div className="wallet-crypto-dropdown-bottom my_row width relative my-global">
  //                 <div className="wallet-coin-dropdown">
  //                   <form className="relative">
  //                     <div className="dropDown-Coin-Img absolute">
  //                       <img src={imgURL} alt="" />
  //                     </div>
  //                     <select
  //                       className="coinsSelect"
  //                       name="coins"
  //                       id="coins"
  //                       value={optionState}
  //                       onChange={dropdownHandle}
  //                     >
  //                       <option value="BTC">BTC</option>
  //                       <option value="ETH">ETH</option>
  //                       <option value="BNB">BNB</option>
  //                       <option value="TRX">TRX</option>
  //                       <option value="USDT">USDT</option>
  //                     </select>
  //                   </form>
  //                 </div>
  //                 <div className="wallet-coin-balance ">
  //                   <form>
  //                     <input
  //                       type="number"
  //                       className="balanceToTransferINput white bold"
  //                       value={balanceToTransfer}
  //                       onChange={handleBalanceToTransfer}
  //                     />
  //                   </form>
  //                   <div className="max-button-container absolute">
  //                     <button
  //                       className="max-button-styling white"
  //                       onClick={MaxSetter}
  //                     >
  //                       Max
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="transfer-button-container my_row justify">
  //           <button className="betit-button white">
  //             {TransferIn ? "Transfer To Vault Pro" : "Transfer To Wallet"}
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default VaultPro;
