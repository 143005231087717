import { useState, useEffect, useContext } from "react";
import "./Crypto.css";
import BasicModal from "./SlideinDeposit";
import { betPriceContext } from "../../../../../../../Contexts/BetPrice";
import { useTranslation } from "react-i18next";
import CurrencyButtons from "../../CurrencyButtons/CurrencyButtons";
import CurrencyDropdownSelect from "../../../../../../CurrencyDropdownSelect/CurrencyDropdownSelect";
import NetworkOptions from "../../../../../../NetworkOptions/NetworkOptions";
import DepositBanner from "../../../../../../DepositBanner/DepositBanner";
import QrGenerator from "../../../../../../QrGenerator/QrGenerator";
import ShowComponent from "../../../../../../ShowComponent/ShowComponent";

const Crypto = ({ activeWallets, walletType }) => {
  const [wallets, setWallets] = useState(
    activeWallets.filter((wallet) => wallet.type === walletType)
  );

  const { optionState, setOptionState } = useContext(betPriceContext);
  const globalActiveWallet = activeWallets?.find(
    (currency) => currency.network_id == optionState
  );
  const usdt_wallet = activeWallets?.find(
    (currency) => currency.network_id == "usdt"
  );
  const activated =
    globalActiveWallet.type === "fiat" ? usdt_wallet : globalActiveWallet;

  const [choosenNetwork, setChoosenNetwork] = useState(
    activated?.supported_networks[0]?.network_id
  );
  const [choosenNetworkBcAccount, setChoosenNetworkBcAccount] = useState(
    activated?.supported_networks[0]?.bc_account
  );

  const withdrayOptionName = [
    {
      name: "MetaMask",
      img: "metamaskDeposit.webp",
    },
    {
      name: "walletconnect",
      img: "walletConnectDeposit.webp",
    },
  ];

  const { t } = useTranslation();
  const activateCurrency = (network_id) => {
    setOptionState(network_id);
  };

  const handleNetwork = (network) => {
    setChoosenNetwork(network.network_id);
    setChoosenNetworkBcAccount(network.bc_account);
  };
  useEffect(() => {
    setChoosenNetwork(activated?.supported_networks[0]?.network_id);
    setChoosenNetworkBcAccount(activated?.supported_networks[0]?.bc_account);
  }, [activated.network_id]);

  return (
    <div className="wallet-crypto-main">
      <div className="wallet-crypto-content">
        <CurrencyButtons
          wallets={wallets}
          activeCurrency={optionState}
          activateCurrency={activateCurrency}
        />
        <CurrencyDropdownSelect activated={activated} text={true} />
        <DepositBanner />

        <ShowComponent condition={activated?.supported_networks.length}>
          <NetworkOptions
            networks={activated?.supported_networks}
            handleNetwork={handleNetwork}
            choosenNetwork={choosenNetwork}
          />
        </ShowComponent>
        {activated.currency_configuration.deposit ? (
          <QrGenerator
            stringToQr={
              activated.network_support
                ? choosenNetworkBcAccount
                : activated?.bc_account
            }
            QrOnly={false}
            QrTypoHeading={t("header.wallet.depositTab.depositAddress")}
          />
        ) : (
          <div className="not-supported-main width">
            <div className="not-supported-inner">
              <div className="not-supported-content">
                <p className="text_color orbitron-font">
                  {activated.network_id} is not deposit able as it is platform
                  standard currency. Please choose any other network to deposit
                  {activated.network_id === "bcw" &&
                    " but you can swap any other currency to bcw."}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* <div className="wallet-notice-conainer-options-container">
          <div className="wallet-notice-conainer-container">
            <span
              className="wallet-notice text_color"
              style={{ fontSize: "14px" }}
            >
              <strong>{t("header.wallet.depositTab.notice")}: </strong>
              {t("header.wallet.depositTab.note")}
            </span>
          </div>
        </div> */}
        {activated.supported_deposit_methods.map((depositMethod) => {
          return (
            depositMethod.name === "meta_mask" && (
              <div className="walletOptions-container">
                <div className="centered_text text_color">
                  <span>Or </span>
                </div>
                <div
                  syle={{
                    background: "#17181b",
                    padding: "15px 0",
                    marginTop: "15px",
                  }}
                  className="support-deposit-container"
                >
                  <div
                    style={{
                      margin: "10px",
                    }}
                    className="supportDeposit-heading centered_text text_color"
                  >
                    <span>Support Deposit With</span>
                  </div>
                  <div
                    style={{
                      gap: "35px",
                    }}
                    className="support-deposit-option-container justified"
                  >
                    {withdrayOptionName.map((withdraw, index) => {
                      return (
                        withdraw.name !== "walletconnect" && (
                          <button
                            key={index}
                            name={withdraw.name}
                            className="support-deposit-option justified"
                          >
                            <div className="depositOption-image-container">
                              <img
                                style={{
                                  maxWidth: "43px",
                                }}
                                src={`./imgs/${withdraw.img}`}
                                alt=""
                              />
                            </div>
                            <div className="deposit-option-name-container white">
                              <BasicModal
                                Text={withdraw.name}
                                img={withdraw.img}
                                imgURL={`/imgs/AcceptedNetworks/${activated.network_id}.webp`}
                                bcAccount={activated?.bc_account}
                                network_id={activated?.network_id}
                              />
                            </div>
                          </button>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default Crypto;
