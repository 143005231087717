import { useState, useEffect, createContext, useContext } from "react";
import { useSelector } from "react-redux";
import BetAPI from "../../api/bet";
import { constants } from "../../store/constants";

const betsControl = createContext();

var insideBetSetter = null;
var insideBetCapture = null;
var insideAllBetsSetter = null;

const allBetsCapture = (bets) => {
  insideAllBetsSetter(bets);
};
const betsCapture = (bet) => {
  insideBetCapture(bet);
};
const betsCatcher = (bets) => {
  insideBetSetter(bets);
};

const BetsControlProvider = ({ children }) => {
  const game_state = useSelector((state) => state.game.game_state);

  const [bets, setBets] = useState({});
  const [indices, setIndices] = useState([]);
  const [tempIndices, setTempIndices] = useState([]);
  const [betsCount, setBetsCount] = useState(0);
  const [cashoutCount, setCashotCount] = useState(0);

  useEffect(() => {
    if (game_state === constants.COUNTDOWN_STARTED) {
      setBets({});
      setIndices([]);
      setBetsCount(0);
      setCashotCount(0);
    }
  }, [game_state]);

  insideAllBetsSetter = (allBets) => {
    const prevBets = bets;
    let totalCashout = allBets.data.totalCashout;
    let totalBets = allBets.data.totalBets;

    setIndices(allBets.data.indices);
    setTempIndices(allBets.data.indices);

    let dataBets = allBets.data.bets;

    for (let bet in dataBets) {
      if (dataBets[bet].status !== "failed") {
        prevBets[bet] = dataBets[bet];
      }
    }
    setBets(prevBets);

    setCashotCount(totalCashout);
    setBetsCount(totalBets);
  };

  insideBetCapture = (data) => {
    const prevBets = bets;
    let dataBets = data.bets;

    let totalBets = data.totalBets;
    let totalCashout = data.totalCashout;
    setIndices(data.indices);
    setTempIndices(data.indices);
    for (let bet in dataBets) {
      if (dataBets[bet].status === "placed") {
        
        prevBets[bet] = dataBets[bet];
      }
    }
    setCashotCount(totalCashout);
    setBetsCount(totalBets);

    setBets(prevBets);
  };

  insideBetSetter = (data) => {
    const prevBets = bets;
    let dataBets = data.bets;
    let totalBets = data.totalBets;
    let totalCashout = data.totalCashout;
    setIndices(data.indices);
    for (let bet in dataBets) {
      if (dataBets[bet].status === "checkout") {
        prevBets[bet].cash_out = dataBets[bet].cash_out;
        prevBets[bet].profit = dataBets[bet].profit;
      }
    }
    setCashotCount(totalCashout);
    setBetsCount(totalBets);

    setBets(prevBets);
  };

  return (
    <betsControl.Provider
      value={{
        bets,
        setBets,
        cashoutCount,
        betsCount,
        indices,
        setIndices,
        tempIndices,
        setTempIndices,
      }}
    >
      {children}
    </betsControl.Provider>
  );
};

export {
  betsControl,
  BetsControlProvider,
  betsCatcher,
  betsCapture,
  allBetsCapture,
};
