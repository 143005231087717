import React from "react";
const TabNavItem = ({ id, title, activeTab, setActiveTab, disabled }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <button
      className={`tabs-nav my_row justified ${
        activeTab === id ? "tab-is-active" : disabled ? "tab-is-disabled" : ""
      }`}
      onClick={handleClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};
export default TabNavItem;
