import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setConvertedFakeAmount } from "../../../store/user";
import { useNavigate } from "react-router-dom";
import WalletInnerTabs from "../Header/Wallet/Tabs/WalletInnerTabs";
import { modalsControl } from "../../Contexts/ModalsControl";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#43b309",
  boxShadow: 24,
  borderRadius: "20px",
};

export default function BasicModal({ Text, children, UniqueClass, linkTo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setWalletOpen, walletOpen } = useContext(modalsControl);

  // const [open, setOpen] = useState(walletOpen);

  const handleClose = () => {
    setWalletOpen(false);
    dispatch(
      setConvertedFakeAmount({
        converted_fake_amount: 0,
      })
    );
  };

  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={walletOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalsOn-mobile" sx={style}>
          {children}
        </Box>
      </Modal>
    </>
  );
}
