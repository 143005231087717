import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};
const NetworkOptions = ({ networks, handleNetwork, choosenNetwork }) => {
  const { t } = useTranslation();
  return (
    <div className="wallet-crypto-network-container">
      <div className="wallet-crypto-network-content">
        <div className="network-wallet-crypto-top">
          <span className="text_color">
            {t("header.wallet.depositTab.chooseNetwork")}
          </span>
        </div>
        <div
          style={{
            margin: "15px 20px 20px 20px",
          }}
          className="network-wallet-crypto-bottom"
        >
          <div className="crypto-wallet-network-slider">
            <Slider {...settings}>
              {networks.map((network) => (
                <button
                  className={`crypto-wallet-network justified network-btn-css uppercase ${
                    choosenNetwork === network.network_id
                      ? "activeNetworkButton"
                      : ""
                  }`}
                  onClick={() => handleNetwork(network)}
                >
                  <span
                    className={`${
                      choosenNetwork === network.network_id
                        ? "white bold"
                        : "secondary_text"
                    }`}
                  >
                    {network.network_id}
                  </span>
                </button>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkOptions;
