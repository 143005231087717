import React, { useState } from "react";

const Accordion = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      class={`affiliate-accordion-item js-acc-item ${isOpen && "is-open"}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <h2 class="affiliate-accordion-title js-acc-single-trigger">{title}</h2>
      <div class="affiliate-accordion-content">
        <p class="secondary_text">{data}</p>
      </div>
    </div>
  );
};

export default Accordion;
