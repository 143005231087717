import React from "react";

const SpinSvg = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 438 438"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M219 50.2319C125.941 50.2319 50.2324 125.941 50.2324 219C50.2324 312.059 125.941 387.768 219 387.768C312.059 387.768 387.768 312.059 387.768 219C387.768 125.941 312.059 50.2319 219 50.2319ZM219 256.786C198.165 256.786 181.214 239.835 181.214 219C181.214 198.165 198.165 181.214 219 181.214C239.835 181.214 256.786 198.165 256.786 219C256.786 239.835 239.835 256.786 219 256.786Z"
        fill="#B6AD0B"
      />
      <path
        d="M183.077 207.302C185.576 199.649 190.453 193.065 196.836 188.426L119.989 82.417C91.4804 103.141 69.6844 132.557 58.5244 166.742L183.077 207.302Z"
        fill="#09C1D0"
      />
      <path
        d="M254.924 207.301L379.476 166.74C368.316 132.555 346.521 103.139 318.012 82.415L241.165 188.424C247.547 193.065 252.425 199.648 254.924 207.301Z"
        fill="#DED715"
      />
      <path
        d="M379.476 166.741L254.924 207.302C256.127 210.988 256.787 214.918 256.787 219.001C256.787 223.084 256.128 227.013 254.924 230.7L379.476 271.261C384.85 254.798 387.768 237.236 387.768 219.002C387.768 200.768 384.85 183.204 379.476 166.741Z"
        fill="#8620FE"
      />
      <path
        d="M254.924 230.699C252.425 238.352 247.547 244.935 241.164 249.575L318.011 355.584C346.52 334.86 368.316 305.444 379.475 271.259L254.924 230.699Z"
        fill="#09C1D0"
      />
      <path
        d="M241.164 249.575C234.933 254.105 227.276 256.786 219 256.786V387.768C255.962 387.768 290.18 375.815 318.011 355.584L241.164 249.575Z"
        fill="#DED715"
      />
      <path
        d="M219 387.768V256.786C210.724 256.786 203.067 254.105 196.836 249.575L119.989 355.584C147.82 375.815 182.038 387.768 219 387.768Z"
        fill="#EA3031"
      />
      <path
        d="M183.077 230.698L58.5254 271.259C69.6854 305.444 91.4804 334.86 119.99 355.584L196.837 249.575C190.453 244.935 185.576 238.352 183.077 230.698Z"
        fill="#DED715"
      />
      <path
        d="M181.214 219C181.214 214.917 181.873 210.988 183.077 207.302L58.5254 166.741C53.1514 183.204 50.2324 200.766 50.2324 219C50.2324 237.234 53.1504 254.797 58.5254 271.259L183.077 230.698C181.874 227.012 181.214 223.083 181.214 219Z"
        fill="#8620FE"
      />
      <path
        d="M219 50.2319V181.214C227.275 181.214 234.933 183.895 241.164 188.425L318.011 82.4159C290.18 62.1849 255.962 50.2319 219 50.2319Z"
        fill="#EA3031"
      />
      <path
        d="M219.001 257.786C240.422 257.786 257.787 240.421 257.787 219C257.787 197.579 240.422 180.214 219.001 180.214C197.58 180.214 180.215 197.579 180.215 219C180.215 240.421 197.58 257.786 219.001 257.786Z"
        fill="#9F4AF1"
      />
      <path
        d="M205.43 219C205.43 201.994 216.379 187.555 231.609 182.321C227.655 180.962 223.417 180.214 219.002 180.214C197.581 180.214 180.216 197.579 180.216 219C180.216 240.421 197.581 257.786 219.002 257.786C223.417 257.786 227.655 257.038 231.609 255.679C216.379 250.445 205.43 236.006 205.43 219Z"
        fill="#8B3FD5"
      />
      <path
        d="M219 406.768C115.464 406.768 31.2324 322.536 31.2324 219C31.2324 115.464 115.464 31.2319 219 31.2319C322.535 31.2319 406.768 115.464 406.768 219C406.768 322.536 322.535 406.768 219 406.768ZM219 51.2319C126.492 51.2319 51.2324 126.492 51.2324 219C51.2324 311.508 126.492 386.768 219 386.768C311.508 386.768 386.768 311.508 386.768 219C386.768 126.492 311.508 51.2319 219 51.2319Z"
        fill="#EF9765"
      />
      <path
        d="M223.33 88.4999L259.377 26.0649C261.301 22.7319 258.896 18.5649 255.047 18.5649H182.953C179.104 18.5649 176.698 22.7319 178.623 26.0649L214.67 88.4999C216.594 91.8329 221.406 91.8329 223.33 88.4999Z"
        fill="#EFBF0C"
      />
      <path
        d="M203.677 26.064C201.753 22.731 204.158 18.564 208.007 18.564H182.952C179.103 18.564 176.697 22.731 178.622 26.064L214.67 88.5C216.594 91.833 221.406 91.833 223.33 88.5L231.527 74.302L203.677 26.064Z"
        fill="#D9AE0D"
      />
      <path
        d="M221.119 195.178L227.014 207.122C227.358 207.819 228.024 208.303 228.793 208.415L241.974 210.33C243.912 210.612 244.686 212.994 243.284 214.361L233.746 223.658C233.189 224.201 232.935 224.983 233.066 225.75L235.318 238.878C235.649 240.809 233.623 242.281 231.889 241.369L220.1 235.17C219.412 234.808 218.589 234.808 217.901 235.17L206.112 241.368C204.378 242.279 202.352 240.807 202.683 238.877L204.935 225.749C205.066 224.982 204.812 224.2 204.255 223.657L194.717 214.36C193.314 212.993 194.088 210.611 196.027 210.329L209.208 208.414C209.978 208.302 210.643 207.819 210.987 207.121L216.882 195.177C217.748 193.421 220.252 193.421 221.119 195.178Z"
        fill="white"
      />
      <path
        d="M221.119 195.178L227.014 207.122C227.358 207.819 228.024 208.303 228.793 208.415L241.974 210.33C243.912 210.612 244.686 212.994 243.284 214.361L233.746 223.658C233.189 224.201 232.935 224.983 233.066 225.75L235.318 238.878C235.649 240.809 233.623 242.281 231.889 241.369L220.1 235.17C219.412 234.808 218.589 234.808 217.901 235.17L206.112 241.368C204.378 242.279 202.352 240.807 202.683 238.877L204.935 225.749C205.066 224.982 204.812 224.2 204.255 223.657L194.717 214.36C193.314 212.993 194.088 210.611 196.027 210.329L209.208 208.414C209.978 208.302 210.643 207.819 210.987 207.121L216.882 195.177C217.748 193.421 220.252 193.421 221.119 195.178Z"
        fill="white"
      />
      <path
        d="M215.682 235.232L217.934 222.104C218.065 221.337 217.811 220.555 217.254 220.012L207.716 210.715C207.138 210.152 206.947 209.419 207.037 208.729L196.026 210.329C194.088 210.611 193.314 212.993 194.716 214.36L204.254 223.657C204.811 224.2 205.065 224.982 204.934 225.749L202.682 238.877C202.351 240.808 204.377 242.28 206.111 241.368L215.762 236.295C215.664 235.965 215.617 235.612 215.682 235.232Z"
        fill="#F5F8F9"
      />
    </svg>
  );
};

export default SpinSvg;
