import axiosClient from ".";
class BcwDataApi {
  static getBcwData() {
    return axiosClient.get(`/api/v1/user/bcw/coin`);
  }
  static claimBcd() {
    return axiosClient.post(`/api/v1/user/bcw/claim`);
  }
  static getBcdTransactions() {
    return axiosClient.get("/api/v1/user/bcw/transactions");
    // return axiosClient.get("/api/v1/transaction/records/bcw");
  }
}
export default BcwDataApi;
