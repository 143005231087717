import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";
import DepositTransactions from "./caterogiesTransactions/DepositTransactions";
import WithdrawTransactions from "./caterogiesTransactions/WithdrawTransactions";
import AllSwapWrapper from "./caterogiesTransactions/allSwapWrapper/AllSwapWrapper";
import { modalsControl } from "../../../Contexts/ModalsControl";
import { useContext } from "react";
export default function TransectionModals({ Text }) {
  const { width } = useWindowDimensions();
  const { transactionsModal, setTransactionsModal } = useContext(modalsControl);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    width: width <= 767 ? width : 700,
    border: "2px solid #000",
    background: "#1e2024",
    boxShadow: 24,
    padding: "20px",
    borderRadius: "3px",
  };

  const handleOpen = () => setTransactionsModal(true);
  const handleClose = () => setTransactionsModal(false);
  const [Deposit, setDeposit] = useState(true);
  const [Bill, setBill] = useState(false);
  const [Withdraw, setWithdraw] = useState(false);
  const [AllButton, setAllButton] = useState(true);
  const [SwapButton, setSwapButton] = useState(false);
  const selectorHandle = (name) => {
    if (name == "all") {
      setAllButton(true);
      setSwapButton(false);
    }
    if (name == "swap") {
      setSwapButton(true);
      setAllButton(false);
    }
    if (name == "game") {
      setAllButton(false);
      setSwapButton(false);
    }
  };
  const HandleClick = (name) => {
    if (name == "deposit") {
      setDeposit(true);
      setBill(false);
      setWithdraw(false);
    }
    if (name == "bill") {
      setBill(true);
      setDeposit(false);
      setWithdraw(false);
    }
    if (name == "withdraw") {
      setWithdraw(true);
      setDeposit(false);
      setBill(false);
    }
  };

  const { t } = useTranslation();
  return (
    <div>
      <button className="text_color" onClick={handleOpen}>
        {Text}
      </button>

      <Modal
        open={transactionsModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="transaction-popup modals-height-on-mobiles">
          <div className="transection-history-inner-div">
            <div className="transectin-history-content-div">
              <div
                style={{
                  padding: width <= 767 ? "25px" : "0",
                  boxSizing: "border-box",
                  marginBottom: "20px",
                }}
                className="transection-top-div width my_row space-between align_center"
              >
                <div className="transections">
                  <span className="orbitron-font">
                    {t("header.wallet.transactionTab.transaction")}
                  </span>
                </div>
                <div className="close">
                  <button className="white" onClick={handleClose}>
                    <CloseIcon />
                  </button>
                </div>
              </div>

              <div className="transections-tab-switch width">
                <div className="tab-switch-content width justified">
                  <button
                    name="deposit"
                    style={{
                      background: Deposit ? "rgb(52 52 52)" : "rgb(22 22 22)",
                      padding: "15px 25px",
                      transition: ".5s",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`tab-stitch-transection-history ${
                      Deposit ? "white" : "secondary_text"
                    } bold`}
                    onClick={() => HandleClick("deposit")}
                  >
                    {t("header.wallet.transactionTab.deposit")}
                  </button>

                  <button
                    name="withdraw"
                    style={{
                      background: Withdraw ? "rgb(52 52 52)" : "rgb(22 22 22)",
                      padding: "15px 25px",
                      transition: ".5s",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`tab-stitch-transection-history ${
                      Withdraw ? "white" : "secondary_text"
                    } bold`}
                    onClick={() => HandleClick("withdraw")}
                  >
                    {t("header.wallet.transactionTab.withdraw")}
                  </button>

                  <button
                    name="swap"
                    style={{
                      background: Bill ? "rgb(52 52 52)" : "rgb(22 22 22)",
                      padding: "15px 25px",
                      transition: ".5s",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className={`tab-stitch-transection-history ${
                      Bill ? "white" : "secondary_text"
                    } bold`}
                    onClick={() => HandleClick("bill")}
                  >
                    {t("header.wallet.transactionTab.bilswap")}
                  </button>
                </div>
              </div>

              {Bill ? (
                <AllSwapWrapper
                  AllButton={AllButton}
                  selectorHandle={selectorHandle}
                  SwapButton={SwapButton}
                ></AllSwapWrapper>
              ) : (
                <div className="transections-history-sections-container">
                  <div
                    style={{
                      height: "65px",
                    }}
                    className="transections-history-header my_row space-between"
                  >
                    <div className="time-amount-container width-45 my_row space-between">
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="transections-header-items text_color"
                      >
                        {t("header.wallet.transactionTab.time")}
                      </span>

                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="transections-header-items text_color"
                      >
                        {t("header.wallet.transactionTab.amount")}
                      </span>
                    </div>

                    <div className="state-transection-contanier width-45 my_row space-between">
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="transections-header-items text_color"
                      >
                        {t("header.wallet.transactionTab.status")}
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                        className="transections-header-items text_color"
                      >
                        {t("header.wallet.transactionTab.transaction")}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "308px",
                      background: "#161616",
                      padding: "10px 0",
                      overflowY: "auto",
                      borderRadius: "5px",
                      marginBottom: "55px",
                    }}
                    className="transections-history-data-wrapper relative"
                  >
                    {Deposit && <DepositTransactions type="deposit" />}
                    {Withdraw && <WithdrawTransactions type="withdraw" />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
