import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import BetRoundsPlayersStatsAPI from "../../../../api/bet_rounds_players_stats";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../Contexts/windowDimensions";
function RoundPlayers({ RoundId }) {
  const { height, width } = useWindowDimensions();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 767 ? width : "475px",
    height: width <= 767 ? height : "580px",
    bgcolor: "#1e2024",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    color: "#ccc",
    padding: 0,
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" },
  };
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [betsArray, setBetsArray] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const handleOpen = (id) => {
    setLoadingData(true);
    BetRoundsPlayersStatsAPI.getBetRoundsPlayersStats({ id: id })
      .then((res) => {
        setBetsArray(res.data.bet_rounds_players_stats.bets);

        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
      });

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <span>
        <button
          className="secondary_text justified history-userClick"
          onClick={(id) => {
            handleOpen(RoundId);
          }}
        >
          {RoundId}
        </button>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="all-player-popup modals-height-on-mobiles">
          <div className="bet-user-modal-inner">
            <div
              style={{
                padding: width <= 767 ? "30px" : "15px",
              }}
              className="bet-user-modal-top my_row space-between"
            >
              <span className="orbitron-font">
                {t("header.referral.alplyer")}
              </span>
              <span>
                <button className="white" onClick={handleClose}>
                  <CloseIcon />
                </button>
              </span>
            </div>
            <div
              style={{
                height: "20px",
                height: "415px",
                padding: "10px",
                boxSizing: "border-box",
              }}
              className="total-players-table-container width"
            >
              <div
                style={{
                  padding: "10px 20px",
                  boxSizing: "border-box",
                  fontSize: "12px",
                }}
                className="total-players-table-header width my_row space-between"
              >
                <div className="bet-id-player-container width-48 my_row space-between text_color bold">
                  <span>{t("stats.mybets.betid")}</span>
                  <span>{t("allbets.player")}</span>
                </div>
                <div className="pay-out-profit width-48 my_row space-between text_color bold">
                  <span>{t("stats.mybets.payout")}</span>
                  <span>{t("stats.mybets.profit")}</span>
                </div>
              </div>

              <div
                style={{
                  height: "470px",
                  overflow: "auto",
                }}
                className="total-players-parent"
              >
                <div className="total-players-table-data width">
                  {loadingData ? (
                    <div
                      style={{
                        top: "190px",
                        left: "43%",
                      }}
                      className="lds-ellipsis relative"
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : betsArray.length ? (
                    betsArray.map((bet) => {
                      return (
                        <div
                          key={bet.id}
                          style={{}}
                          className="total-players-table-row"
                        >
                          <div
                            style={{
                              padding: "10px",
                              boxSizing: "border-box",
                              fontSize: "12px",
                            }}
                            className="total-players-table-header width my_row space-between"
                          >
                            <div className="bet-id-player-data width-48 my_row space-between bold">
                              <span className="secondary_text">{bet.id}</span>
                              <span className="bold white">
                                {bet.username.substring(0, 10)}
                                {bet.username.length > 10 && "..."}
                              </span>
                            </div>
                            <div className="pay-out-profit width-48 my_row space-between bold">
                              <span className="secondary_text">
                                {bet.win_status ? bet.cash_out + "x" : "Bang"}
                              </span>
                              <span
                                style={{
                                  gap: "5px",
                                  color: bet.win_status ? "#3bc117" : "red",
                                }}
                                className="bold justified"
                              >
                                {bet.win_status
                                  ? " + " + bet.profit
                                  : " - " + bet.amount}
                                <span>
                                  <img
                                    style={{
                                      maxWidth: "20px",
                                    }}
                                    src={`/imgs/AcceptedNetworks/${bet.network_id}.webp`}
                                    alt={bet.network_id}
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        flexDirection: "column",
                        marginTop: "60px",
                      }}
                      className="showEmpty-containern justified width"
                    >
                      <div className="show-empty-img-container">
                        <img
                          className="empty-max-width"
                          src="/imgs/empty.png"
                          alt="empty"
                        />
                      </div>

                      <div className="show-empty-typo">
                        <span
                          style={{ fontSize: "12px" }}
                          className="text_color"
                        >
                          {t("header.referral.oop")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default memo(RoundPlayers);
