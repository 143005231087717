import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useState } from "react";
import SwapCurrencyAPI from "../../../../../../../api/swap_currency";

import { useEffect } from "react";

import UserWalletsAPI from "../../../../../../../api/user_wallets";
import { setUserWallets } from "../../../../../../../store/user";
import { setLoadingWalletInfo } from "../../../../../../../store/user";
import { betPriceContext } from "../../../../../../Contexts/BetPrice";
import Alert from "../../../../../Alert";
import { useTranslation } from "react-i18next";
import { modalsControl } from "../../../../../../Contexts/ModalsControl";

const BCSwap = () => {
  const dispatch = useDispatch();
  const { activatedCurrency } = useContext(betPriceContext);
  const { gettingRate } = useContext(modalsControl);
  const globalWallets = useSelector((state) => state.user.wallets);
  const wallets = globalWallets.filter((wallet) => wallet.type !== "fiat");
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  const [firstField, setFirstField] = useState(
    activatedCurrency.type === "fiat"
      ? "usdt"
      : activatedCurrency.network_id == "wb"
      ? "usdt"
      : activatedCurrency.network_id
  );
  const [secondField, setSecondField] = useState(
    activatedCurrency.type === "fiat"
      ? "ethereum"
      : activatedCurrency.network_id == "wb"
      ? "ethereum"
      : activatedCurrency.network_id == "usdt"
      ? "ethereum"
      : "usdt"
  );
  const [selectedNetwork, setSelectedNetword] = useState("0");
  const [swap, setSwap] = useState(false);
  const [swapFailed, setSwapFailed] = useState(false);
  const [Swaping, setSwaping] = useState(false);
  const [swapFee, setSwapFee] = useState(0);
  const [swappedRate, setSwappedRate] = useState("0");
  const [fieldOne, setFieldOne] = useState({});
  const activatedWallet = wallets?.find((singleWallet) => {
    return singleWallet.network_id === firstField;
  });
  const handleDropDown = (e) => {
    e.target.name == "firstField"
      ? setFirstField(e.target.value)
      : setSecondField(e.target.value);

    setSwap(false);
  };
  useEffect(() => {
    setSelectedNetword(activatedWallet.fake_amount);
  }, [swap, firstField, secondField]);

  const handleSwap = () => {
    if (gettingRate) {
      return;
    }
    selectedNetwork !== "0.0" && selectedNetwork > "0.0" && setSwaping(true);
    SwapCurrencyAPI.swap_currency({
      swap: {
        from_network_id: firstField,
        to_network_id: secondField,
        from_fake_amount: parseFloat(selectedNetwork),
      },
    })
      .then((response) => {
        UserWalletsAPI.getUserWallets()
          .then((response) => {
            dispatch(
              setUserWallets({
                wallets: response.data,
              })
            );
            dispatch(
              setLoadingWalletInfo({
                loadingWalletInfo: false,
              })
            );
            setSwap(true);
            setSwaping(false);
            setTimeout(() => {
              setSwap(false);
            }, 2000);
          })
          .catch((error) => {
            setSelectedNetword(activatedWallet.fake_amount);
            dispatch(
              setLoadingWalletInfo({
                loadingWalletInfo: false,
              })
            );
          });
      })
      .catch((err) => {
        setSwaping(false);
        setSwapFailed(true);
        setTimeout(() => {
          setSwapFailed(false);
        }, 2000);
      });
  };

  useEffect(() => {
    setFieldOne(
      conversion_rates.conversion_rates?.find((formula) => {
        return formula.network_id == firstField;
      })
    );
  }, [firstField]);

  useEffect(() => {
    setSwappedRate(
      conversion_rates.conversion_rates.find(
        (rate) =>
          rate.from_currency === firstField && rate.to_currency === secondField
      ).rate
    );
  }, [firstField, secondField, fieldOne]);

  useEffect(() => {
    setSelectedNetword(activatedWallet.fake_amount);
  }, [firstField]);

  useEffect(() => {
    if (firstField == "bcw" && secondField == "usdt") {
      setSwapFee(0);
    } else if (secondField == "bcw" && firstField == "usdt") {
      setSwapFee(0);
    } else {
      setSwapFee(parseFloat(selectedNetwork) * 0.0025);
    }
  }, [selectedNetwork, firstField, secondField]);

  const swapVariables = () => {
    setFirstField(secondField);
    setSecondField(firstField);
  };

  const { t } = useTranslation();
  return (
    <div
      className="wallet-scroll-whole swap-panel"
      style={{
        color: "#98a7b5",
      }}
    >
      <div
        className="swap-get-approximately"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#98a7b5",
          marginBottom: "10px",
        }}
      >
        <span className="text_color">
          {t("header.wallet.swapTab.getApprox")}
        </span>
        <span className="text_color">{t("header.wallet.swapTab.record")}</span>
      </div>

      <div
        style={{
          padding: "5px 9px 9px 9px",
          border: "4px solid #2d3035",
          backgroundColor: "#17181b",
          borderRadius: "3px",
        }}
      >
        <div
          className="wallet-dropdown-field-div1"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <select
            className="white bold swap-first-field"
            name="firstField"
            id=""
            style={{
              fontWeight: "800",
              padding: "10px 13px",
              width: "36%",
              border: "none",
              backgroundColor: "#1e2023",
              textTransform: "uppercase",
              alignSelf: "flex-end",
              border: "solid 1px #1e2023",
            }}
            value={firstField}
            onChange={(e) => {
              handleDropDown(e);
            }}
          >
            {wallets.map((wallet, index) => {
              return (
                wallet.network_id !== "wb" &&
                wallet.network_id !== secondField && (
                  <option key={index} value={wallet.network_id}>
                    {wallet.network_id == "bcd"
                      ? "bcw"
                      : wallet.network_id == "polygon"
                      ? "Matic"
                      : wallet.network_id}
                  </option>
                )
              );
            })}
          </select>
          <div
            className="wallet-dropdown-field-max"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="swap-max-button"
              style={{
                padding: "0px 5px",
                width: "40px",
                height: "31px",
                border: "none",
                backgroundColor: "#1e2023",
                color: "#98a7b5",
                marginBottom: "4px",
              }}
              onClick={() => {
                setSelectedNetword(activatedWallet.fake_amount);
              }}
            >
              {t("header.wallet.swapTab.max")}
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "50%",
              }}
            >
              <label htmlFor="" className="margin-bottom-10">
                {t("header.wallet.swapTab.send")}
              </label>
              <input
                className="bold white swap-send-field"
                type="number"
                style={{
                  fontWeight: "800",
                  width: "90%",
                  padding: "8px",
                  background: "transparent",
                  textAlign: "right",
                  fontSize: "16px",
                }}
                value={selectedNetwork}
                onChange={(e) => {
                  setSelectedNetword(e.target.value);
                  setSwap(false);
                }}
                onBlur={(e) => {
                  e.target.value > parseFloat(activatedWallet.fake_amount)
                    ? setSelectedNetword(activatedWallet.fake_amount)
                    : setSelectedNetword(e.target.value);
                  e.target.value == 0 &&
                    setSelectedNetword(activatedWallet.fake_amount);
                }}
              />
            </div>
          </div>
        </div>
        <Divider
          className="swap-devider"
          sx={{
            // padding: "10px",
            width: "100%",
            "&::before, &::after": {
              borderTop: "#2d3035 solid 4px",
            },
          }}
        >
          <button className="swapIconClick" onClick={swapVariables}>
            <ImportExportIcon
              style={{
                border: "2px solid #98a7b5",
                // padding: "3px",
                color: "#98a7b5",
              }}
            />
          </button>
        </Divider>
        <div
          className="wallet-dropdown-field-div2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <select
            className="white swap-second-field"
            name="secondField"
            id=""
            style={{
              fontWeight: "800",

              padding: "10px 13px",
              width: "36%",
              border: "none",
              backgroundColor: "#1e2023",
              textTransform: "uppercase",
              alignSelf: "flex-end",
              border: "solid 1px #1e2023",
            }}
            value={secondField}
            onChange={(e) => {
              handleDropDown(e);
            }}
          >
            {wallets.map((wallet, index) => {
              return (
                wallet.network_id !== "wb" &&
                wallet.network_id !== firstField && (
                  <option key={index} value={wallet.network_id}>
                    {wallet.network_id == "bcd"
                      ? "bcw"
                      : wallet.network_id == "polygon"
                      ? "Matic"
                      : wallet.network_id}
                  </option>
                )
              );
            })}
          </select>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "46%",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                marginBottom: "-5px",
                marginRight: "7px",
                marginTop: "-7px",
              }}
            >
              <label htmlFor="" className="margin-bottom-10">
                {t("header.wallet.swapTab.get")}
              </label>
            </div>
            <input
              className="white swap-get-field"
              type="number"
              value={(
                (parseFloat(selectedNetwork) - swapFee) *
                parseFloat(swappedRate)
              ).toFixed(8)}
              style={{
                fontWeight: "800",
                width: "58%",
                padding: "8px",
                background: "transparent",
                textAlign: "right",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      </div>
      <br />
      <div
        className="swap-fee conversion-rates-container relative"
        style={{
          color: "#98a7b5",
          fontSize: "14px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "flex-start",
          padding: "15px",
          backgroundColor: "#17181b",

          width: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            left: swap || swapFailed ? "0" : "-580px",
            transition: ".5s",
          }}
          className="swap-alert-container"
        >
          <Alert
            type={swapFailed ? "warning" : "success"}
            message={
              swapFailed
                ? t("header.wallet.swapTab.topup")
                : t("header.wallet.swapTab.swapSuccess")
            }
            title={
              swapFailed
                ? t("header.wallet.swapTab.fail")
                : t("header.wallet.swapTab.success")
            }
          />
        </div>
        <span
          style={{
            textTransform: "uppercase",
          }}
        >
          1 {firstField === "bcd" ? "bcw" : firstField} ≈ {swappedRate}{" "}
          {secondField === "bcd" ? "bcw" : secondField}
        </span>
        <span>
          {t("header.wallet.swapTab.swapFee")}: &nbsp;
          {/* {firstField == "usdt" && secondField == "bcd"
            ? 0
            : secondField == "usdt" && firstField == "bcd"
            ? 0 */}
          {/* :  */}
          {swapFee}
        </span>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {gettingRate ? (
          <button className="primary-btn primary-btn-disable">
            Getting Rate
          </button>
        ) : !swap ? (
          parseFloat(selectedNetwork) <= 0 ? (
            <button className="primary-btn primary-btn-disable">
              {Swaping ? (
                <div
                  style={{
                    top: "-45px",
                  }}
                  className="lds-ellipsis"
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                t("header.wallet.swapTab.swapNow")
              )}
            </button>
          ) : parseFloat(selectedNetwork) >
            parseFloat(activatedWallet.fake_amount) ? (
            <button className="primary-btn primary-btn-disable">
              {t("header.wallet.swapTab.invalidAmount")}
            </button>
          ) : Swaping ? (
            <button className="primary-btn primary-btn-bg">
              <div
                style={{
                  top: "-45px",
                }}
                className="lds-ellipsis"
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          ) : (
            <button className="primary-btn primary-btn-bg" onClick={handleSwap}>
              {t("header.wallet.swapTab.swapNow")}
            </button>
          )
        ) : (
          <button className="primary-btn primary-btn-success">
            {t("header.wallet.swapTab.swaped")}
          </button>
        )}
      </div>
    </div>
  );
};

export default BCSwap;
