import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import UserWalletsAPI from "../../../../../../../../api/user_wallets";
import { betPriceContext } from "../../../../../../../Contexts/BetPrice";
import { setUserWallets } from "../../../../../../../../store/user";
import { useDispatch } from "react-redux";
import Authenticate2Fa from "../Authenticate";
import Alert from "../../../../../../Alert";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import CurrencyDropdownSelect from "../../../../../../CurrencyDropdownSelect/CurrencyDropdownSelect";
import NetworkOptions from "../../../../../../NetworkOptions/NetworkOptions";
import ShowComponent from "../../../../../../ShowComponent/ShowComponent";
const Crypto = ({ activeWallets, walletType }) => {
  const [wallets, setWallets] = useState(
    activeWallets.filter((wallet) => wallet.type === walletType)
  );
  const { optionState, setOptionState } = useContext(betPriceContext);
  const globalActiveWallet = activeWallets?.find(
    (currency) => currency.network_id == optionState
  );
  const usdt_wallet = activeWallets?.find(
    (currency) => currency.network_id == "usdt"
  );
  const activated =
    globalActiveWallet.type === "fiat" ? usdt_wallet : globalActiveWallet;

  const [choosenNetwork, setChoosenNetwork] = useState(
    activated?.supported_networks[0]?.network_id
  );
  const [walletAddress, setWalletAddress] = useState("");
  const [walletAmount, setWalletAmount] = useState(activated.fake_amount);
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [ammountSent, setAmmountSet] = useState(false);
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const dispatch = useDispatch();

  const [withdrawing, setwithdrawing] = useState(false);

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [amountToReceive, setAmountToReceive] = useState(0);

  useEffect(() => {
    setChoosenNetwork(activated?.supported_networks[0]?.network_id);
  }, [activated.network_id]);
  useEffect(() => {
    setWalletAmount(activated.fake_amount);
  }, [optionState]);
  const two_fa_enabled = useSelector((state) => state.user.two_fa_enabled);
  const [open, setOpen] = useState();
  const [canProcess, setCanProcess] = useState(false);
  const [feeToCharge, setFeeToCharge] = useState(0);
  const [minToWithdraw, setMinToWithdraw] = useState(0);

  useEffect(() => {
    setAmmountSet(false);

    if (optionState === "usdt") {
      const tokenHandle = wallets.find(
        (currency) => currency.network_id == choosenNetwork
      );
      setFeeToCharge(tokenHandle?.currency_limit.withdrawal_fee_token);
      setMinToWithdraw(tokenHandle?.currency_limit.withdrawal_limit_min_token);
    } else {
      setFeeToCharge(activated.currency_limit.withdrawal_fee);
      setMinToWithdraw(activated.currency_limit.withdrawal_limit_min);
    }
  }, [optionState, choosenNetwork]);
  const { t } = useTranslation();

  useEffect(() => {
    const withdrawAmount = new BigNumber(parseFloat(walletAmount));
    !walletAmount ||
    parseFloat(walletAmount) === 0 ||
    parseFloat(walletAmount) < 0
      ? setAmountToReceive("0")
      : setAmountToReceive(withdrawAmount.minus(feeToCharge).toPrecision(8));
  }, [walletAmount]);

  const handleNetwork = (network) => {
    setChoosenNetwork(network.network_id);
  };
  useEffect(() => {
    setChoosenNetwork(activated?.supported_networks[0]?.network_id);
  }, [activated?.network_id]);

  const handleWithDrawal = () => {
    if (two_fa_enabled && !canProcess) {
      setOpen(two_fa_enabled ? true : false);
    } else {
      if (walletAddress && !open && !withdrawing) {
        let networkIdToPass =
          activated.network_id === "usdt"
            ? choosenNetwork
            : activated.network_id;
        let tokenToPass = activated.network_id === "usdt" ? true : false;
        setwithdrawing(true);

        const data = {
          withdraw_amount: {
            network_id: networkIdToPass,
            public_key: walletAddress,
            amount: walletAmount,
            token: tokenToPass,
          },
        };

        UserWalletsAPI.withdrawalToWallet(data)
          .then((response) => {
            setwithdrawing(false);
            setAmmountSet(true);
            setMessage(response.data.message);
            setStatus(response.data.status);

            UserWalletsAPI.getUserWallets().then((res) => {
              dispatch(
                setUserWallets({
                  wallets: res.data,
                })
              );
            });

            setTimeout(() => {
              setAmmountSet(false);
            }, 3000);
          })
          .catch((error) => {
            setFailed(true);
            setFailedMessage(error.response.data.message);
            setwithdrawing(false);

            setTimeout(() => {
              setFailed(false);
            }, 3000);
          });
      }
    }
  };
  return (
    <>
      <Authenticate2Fa
        open={open}
        setOpen={setOpen}
        setCanProcess={setCanProcess}
      />
      <div className="withdraw-main-container">
        <div className="withdraw-content-container">
          <div className="crypto-set-content">
            <CurrencyDropdownSelect activated={activated} />
            <ShowComponent condition={activated?.supported_networks?.length}>
              <NetworkOptions
                networks={activated?.supported_networks}
                handleNetwork={handleNetwork}
                choosenNetwork={choosenNetwork}
              />
            </ShowComponent>

            {activated.network_id === "wb" || activated.network_id === "bcw" ? (
              <div className="not-supported-main width">
                <div className="not-supported-inner">
                  <div className="not-supported-content">
                    <p className="text_color orbitron-font">
                      {activated.network_id} is not withdraw able because this
                      is the platform standard currency. Please choose any other
                      network to withdraw{" "}
                      {activated.network_id === "bcw" &&
                        "or swap this currency to another currency for withdraw."}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="withdraw-performe">
                <div className="withdraw-performer-content">
                  <div className="withdraw-adress-container">
                    <div
                      style={{
                        margin: "0 0 10px 0",
                      }}
                      className="withdraw-adress text_color"
                    >
                      <span
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {t("header.wallet.withdrawTab.withdrawAddress")}
                      </span>
                    </div>

                    <div
                      className="withdraw-adress-field"
                      style={{ margin: "0 1px" }}
                    >
                      <form>
                        <input
                          style={{
                            background: "#17181b",
                            border: "none",
                            boxSizing: "border-box",
                            padding: "20px 2px 20px 2px",
                            textAlign: "left",
                            padding: "20px",
                          }}
                          id={
                            activated.currency_configuration.withdraw === 2
                              ? `inputID`
                              : "button"
                          }
                          className="width white bold"
                          type="text"
                          placeholder={
                            activated.currency_configuration.withdraw === 2
                              ? t("header.wallet.withdrawTab.notificationalret")
                              : t(
                                  "header.wallet.withdrawTab.addressPlaceholder"
                                )
                          }
                          disabled={
                            activated.currency_configuration.withdraw === 2
                              ? true
                              : false
                          }
                          value={walletAddress}
                          onChange={(e) => {
                            setWalletAddress(e.target.value);
                          }}
                        />
                      </form>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      overflow: "hidden",
                    }}
                    className="withdraw-ammount-container relative"
                  >
                    <div
                      style={{
                        zIndex: "2",
                        top: failed ? "0px" : "-76px",
                        transition: ".5s",
                      }}
                      className="withdraw-alert-container absolute width"
                    >
                      <Alert
                        type="warning"
                        message={failedMessage}
                        title="Failed to withdraw"
                      />
                    </div>
                    <div
                      style={{
                        zIndex: "2",
                        top: ammountSent ? "0px" : "-76px",
                        transition: ".5s",
                      }}
                      className="withdraw-alert-container absolute width"
                    >
                      <Alert
                        type="success"
                        message={message}
                        title="Withdrawal Request Submitted"
                      />
                    </div>
                    <div
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "!2px",
                      }}
                      className="withdraw-amount text_color my_row space-between"
                    >
                      <span
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {t("header.wallet.withdrawTab.withdrawAmount")}
                      </span>

                      <span
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        {t("header.wallet.withdrawTab.min")}: {minToWithdraw}
                      </span>
                    </div>
                    <div
                      className="withdraw-amount-field relative"
                      style={{ margin: "0 1px" }}
                    >
                      <form>
                        <input
                          style={{
                            background: "#17181b",
                            border: "none",
                            boxSizing: "border-box",
                            padding: "20px",
                            marginBottom: "2px",
                          }}
                          className="width bold white"
                          type="number"
                          placeholder={t(
                            "header.wallet.withdrawTab.amountPlaceholder"
                          )}
                          value={walletAmount}
                          onChange={(e) => {
                            setWalletAmount(e.target.value);
                          }}
                          onBlur={(e) => {
                            e.target.value > parseFloat(activated.fake_amount)
                              ? setWalletAmount(activated.fake_amount)
                              : setWalletAmount(e.target.value);
                          }}
                        />
                      </form>
                    </div>
                  </div>

                  <div className="invalid-adress-container centered_text">
                    <p
                      className="text_color"
                      style={{
                        fontSize: "14px",
                        margin: "10px 0",
                      }}
                    >
                      {t("header.referral.fee")} &nbsp;
                      <span
                        className="bold uppercase"
                        style={{
                          color: "#2fa5cd",
                        }}
                      >
                        {feeToCharge}
                        &nbsp;
                        {activated.symbol}
                      </span>
                      &nbsp;
                      <span>
                        ({t("header.wallet.withdrawTab.receive")}{" "}
                        {amountToReceive}
                        <span className="gc bold uppercase">
                          {" " + activated.symbol}
                        </span>
                        )
                      </span>
                    </p>
                  </div>

                  <div
                    style={{
                      margin: "15px 0",
                    }}
                    className="withdraw-action-container justified"
                  >
                    {activated.currency_configuration.withdraw === 2 ? (
                      <button className="primary-btn primary-btn-disable">
                        {t("header.wallet.withdrawTab.undermatainance")}
                      </button>
                    ) : failed ? (
                      <button className="primary-btn primary-btn-danger">
                        Failed
                      </button>
                    ) : ammountSent ? (
                      <button className="primary-btn primary-btn-success">
                        In Process
                      </button>
                    ) : parseFloat(walletAmount) >
                        parseFloat(activated.fake_amount) ||
                      parseFloat(activated.fake_amount) == 0 ||
                      parseFloat(walletAmount) <= 0 ||
                      parseFloat(walletAmount) < parseFloat(minToWithdraw) ? (
                      <button className="primary-btn primary-btn-disable">
                        {t("header.referral.invalidAmount")}
                      </button>
                    ) : (
                      <button
                        className="primary-btn primary-btn-bg"
                        onClick={() => {
                          handleWithDrawal();
                        }}
                      >
                        {withdrawing ? (
                          <div
                            style={{
                              top: "-45px",
                            }}
                            className="lds-ellipsis"
                          >
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          t("header.wallet.confirm")
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {activated.network_id !== "wb" &&
              activated.network_id !== "bcw" && (
                <div className="security-purpose">
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                    className="text_color"
                  >
                    {t("header.wallet.withdrawTab.securityPurpose")}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Crypto;
