import { useState, useEffect, createContext } from "react";
const betPriceContext = createContext();
const BetPriceProvider = ({ children }) => {
  const [autoCashout, setAutoCashout] = useState((100.0).toFixed(2));
  const [autoProfit, setAutoProfit] = useState(0);
  const betCOIN = {
    id: null,
    network_id: "wb",
    symbol: "wb",
    amount: null,
    bc_account: null,
    default_wallet: null,
    fake_token_balance: null,
    is_usdt: false,
    currency_limit: {
      min_bet: "1",
      max_bet: "2000.00000",
      precision: null,
      withdrawal_limit_min: null,
      withdrawal_limit_min_token: null,
      withdrawal_fee: null,
      withdrawal_fee_token: null,
      max_profit: null,
    },
    type: "crypto",
  };
  const [activatedCurrency, setActivatedCurrency] = useState(betCOIN);

  const [optionState, setOptionState] = useState(
    activatedCurrency?.network_id === "wb"
      ? "usdt"
      : activatedCurrency?.network_id === "bcd"
      ? "usdt"
      : activatedCurrency?.network_id
  );
  const [betAmmount, setBetPrice] = useState("1");
  const accountStatus = true;
  const [accountSetup] = useState(accountStatus ? true : false);
  const [fixed, setFixed] = useState(0);
  const [countDown, setCountDown] = useState(0);
  const [globaleGameState, setGlobalGameState] = useState(true);
  const [insuficientWallet, setInsuficientWallet] = useState(false);
  useEffect(() => {
    setOptionState(
      activatedCurrency?.network_id === "wb"
        ? "usdt"
        : activatedCurrency?.network_id === "bcd"
        ? "usdt"
        : activatedCurrency?.network_id
    );
  }, [activatedCurrency?.network_id]);
  useEffect(() => {
    setFixed(activatedCurrency?.currency_limit.precision);
  }, [activatedCurrency?.network_id]);
  useEffect(() => {
    setBetPrice(parseFloat(activatedCurrency?.currency_limit.min_bet));
  }, [activatedCurrency?.network_id]);

  
  return (
    <betPriceContext.Provider
      value={{
        betAmmount,
        setBetPrice,
        autoCashout,
        setAutoCashout,
        accountSetup,
        activatedCurrency,
        setActivatedCurrency,
        autoProfit,
        setAutoProfit,
        fixed,
        countDown,
        setCountDown,
        globaleGameState,
        setGlobalGameState,
        insuficientWallet,
        setInsuficientWallet,
        optionState,
        setOptionState,
      }}
    >
      {children}
    </betPriceContext.Provider>
  );
};

export { betPriceContext, BetPriceProvider };
