import Common from "../components/Common/Common";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { constants } from "../store/constants";
import { useContext } from "react";
import { multiplierContext } from "../components/Contexts/Multiplier";

const Router = () => {
  const [startingDate, setStartingTime] = useState(Date.now());

  const game_state = useSelector((state) => state.game.game_state);

  const { clientSocket, setClientSocket, reConnect } =
    useContext(multiplierContext);

  useEffect(() => {
    if (
      game_state === constants.MULTIPLIER_STOPPED &&
      Date.now() - startingDate >= 300000
    ) {
      clientSocket.disconnect();
      setClientSocket(null);
      reConnect();
      setStartingTime(Date.now());
    }
  }, [game_state]);

  return (
    <BrowserRouter>
      <Common />
    </BrowserRouter>
  );
};

export default Router;
