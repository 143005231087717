import {
  allBetsCapture,
  betsCapture,
  betsCatcher,
} from "../components/Contexts/Bets";
import { clientSocketOutside } from "../components/Contexts/Multiplier";
import {
  betCreaterChannel,
  betUpdaterChannel,
  clientSocketContext,
} from "../components/Contexts/SocketBets";

let betCreater = async () => {
  for await (let data of betCreaterChannel) {
    betsCapture(data);
  }
};
let betUpdater = async () => {
  for await (let data of betUpdaterChannel) {
    betsCatcher(data);
  }
};
let allBetCatcher = async () => {
  await clientSocketOutside
    .invoke("allBets", { get: true })
    .then((res) => {
      allBetsCapture(res);
    })
    .catch((err) => {});
};
const betStatusSubscribe = () => {
  allBetCatcher();
  betCreater();
  betUpdater();
};
export { betStatusSubscribe, betCreater, betUpdater, allBetCatcher };
