import axiosClient from ".";
class UserProfilesAPI {
  static get_user_profile(id) {
    return axiosClient.get(`/user_profiles/${id.id}.json`);
  }
  static get_signin_user_profile() {
    return axiosClient.get(`/user_profiles.json`);
  }
  static set_user_username(data) {
    return axiosClient.put("/user_profiles/update_username", data);
  }
  static set_user_profile_image(data) {
    return axiosClient.put("/user_profiles/update_custom_avatar", data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }
  static update_name(data) {
    return axiosClient.put("/user_profiles/update_name", data);
  }
  static update_user_email(data) {
    return axiosClient.put("/user_profiles/update_email", data);
  }
  static send_verification_code() {
    return axiosClient.get("/user_profiles/resend_verification_email");
  }
  static set_user_feedback(data) {
    return axiosClient.post("/user_queries", data);
  }
  static referal_stats() {
    return axiosClient.get("api/v1/user/referral/stats");
  }
  static referal_claim() {
    return axiosClient.post("api/v1/user/referral/claim");
  }
}
export default UserProfilesAPI;
