import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import GameHelpModal from "./GameHelpModal";
import HotKeysModa from "./HotKeysModa";

const AutoCashOut = ({ autoCashout, setAutoCashout }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const locked_auto_cashout = useSelector(
    (state) => state.bets.locked_auto_cashout
  );
  const betActive = useSelector((state) => state.bets.betActive);
  const nextRoundBetPlaced = useSelector(
    (state) => state.game.next_round_bet_placed
  );
  const cashed_out = useSelector((state) => state.user.cashedOut);

  const handleAutoCash = (e) => {
    let gotValue = e.target.value;
    setAutoCashout(gotValue);
  };

  useEffect(() => {
    if (betActive || nextRoundBetPlaced) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [betActive, nextRoundBetPlaced]);

  const { t } = useTranslation();
  return (
    <div className="autocashout-content-container">
      <div className="auto-cashout-chance-container">
        <p className="text_color orbitron-font">
          {t("gamebox.betit.autocashout")}
          {/* <span style={{ color: "#43b309", marginLeft: "5px" }}> 0.99%</span> */}
        </p>
      </div>
      <div
        style={{
          marginTop: "10px",
        }}
        className="autocashout-field-container"
      >
        <input
          value={betActive ? locked_auto_cashout : autoCashout}
          className={`autocashoutField-number-input ModalInps ${
            isDisabled ? "disabled" : "white"
          }`}
          type="number"
          id="autocashoutField"
          name="autoCashOut"
          onChange={handleAutoCash}
          onBlur={(e) => {
            if (!e.target.value || e.target.value < 1.01) {
              setAutoCashout(1.01);
            } else {
              if (parseFloat(e.target.value) > 1000000) {
                setAutoCashout("1000000.0");
              } else {
                setAutoCashout(parseFloat(e.target.value).toFixed(2));
              }
            }
          }}
          disabled={isDisabled}
        />
      </div>

      <div
        style={{
          width: "100%",
          gap: "10px",
        }}
        className="about-game-hotKeys-modal-conatiner my_row end"
      >
        <div className="hotKeys-help hotKeys-help-hide-sm">
          <HotKeysModa />
        </div>

        <div className="GameHelp-help">
          <GameHelpModal />
        </div>
      </div>
    </div>
  );
};

export default AutoCashOut;
