import axiosClient from ".";
class SignInAPI {
  static verify(data) {
    return axiosClient.post("/users/sign_in", data);
  }
  static emailVerify(token) {
    const response = axiosClient.get(
      `/users/confirmation?confirmation_token=${token}`
    );
    return response;
  }
  static logout() {
    return axiosClient.delete("/users/sign_out.json");
  }
  static resetOTP(data) {
    return axiosClient.post("/users/password.json", data);
  }
  static passwordReset(data) {
    return axiosClient.put("/users/password.json", data);
  }
  static updatePassword(data) {
    return axiosClient.patch("/sessions/update_password", data);
  }
}
export default SignInAPI;
