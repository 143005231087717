import { useContext, useState, memo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import "./assets/Css/Modal.css";
// import Modal from "../Modal";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation, Trans } from "react-i18next";
import SignInModal from "../Signin/SignInModal";
import SignUpModal from "../Signup/SignUpModal";
import { modalsControl } from "../../../Contexts/ModalsControl";
import useWindowDimensions from "../../../Contexts/windowDimensions";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../Alert";

const Wrapper = ({ Text, linkTo }) => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const [error, setErr] = useState(false);
  const [errorMessage, setErrMessage] = useState(false);
  const [signupErr, setSignupErr] = useState(false);
  const [open, setOpen] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 767 ? width : 400,
    background: "linear-gradient(to right, #6f33ee 50%, #6124d4 50%)",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
  };

  const { signInOpen, signUpOpen, setSignInOpen, setSignUpOpen } =
    useContext(modalsControl);

  const navigate = useNavigate();

  const handleClose = () => {
    setSignInOpen(false);
    setSignUpOpen(false);
  };
  const handleOpener = (linkTo) => {
    if (linkTo === "signin") {
      setSignInOpen(true);
    }
    if (linkTo === "signup") {
      setSignUpOpen(true);
    }
  };
  return (
    <>
      <button
        onClick={() => handleOpener(linkTo)}
        className={` ${
          Text == "Sign in" || Text == "Masuk"
            ? "headerAction-buttons text_color orbitron-font"
            : "white"
        } ${
          Text == t("header.signup.signup") ? "primary-btn primary-btn-bg" : ""
        }`}
      >
        {Text}
      </button>

      {linkTo == "signin" ? (
        <Modal
          open={signInOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="modalsOn-mobile signin-signup modals-height-on-mobiles"
            sx={style}
          >
            <div className="modal-inner-main">
              <div className="modal-inner-inner">
                <div className="modal-inner-content">
                  <div className="modal-inner-img-container relative">
                    <div
                      style={{
                        opacity: error ? "100%" : "0%",
                        transition: ".5s",
                      }}
                      className="alert-container absolute width"
                    >
                      <Alert
                        type="error"
                        title="Invalid Credentials"
                        message={errorMessage}
                      />
                    </div>

                    <img
                      onClick={handleClose}
                      src="/imgs/logo/logo.png"
                      alt="BCW.CLUB"
                    />

                    <span
                      className="absolute"
                      style={{
                        right: "9px",
                        bottom: "30px",
                      }}
                    >
                      <button className="white" onClick={handleClose}>
                        <CloseIcon />
                      </button>
                    </span>
                  </div>
                  <SignInModal setErr={setErr} setErrMessage={setErrMessage} />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={signUpOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="modalsOn-mobile signin-signup modals-height-on-mobiles"
            sx={style}
          >
            <div className="modal-inner-main">
              <div className="modal-inner-inner">
                <div className="modal-inner-content">
                  <div className="modal-inner-img-container relative">
                    <div
                      style={{
                        opacity: error ? "100%" : "0%",
                        transition: ".5s",
                      }}
                      className="alert-container absolute width"
                    >
                      <Alert
                        type="error"
                        title="Invalid Credentials"
                        message={errorMessage}
                      />
                    </div>
                    <img
                      onClick={handleClose}
                      src="/imgs/logo/logo.png"
                      alt="BCW.CLUB"
                    />
                    <span
                      className="absolute"
                      style={{
                        right: "9px",
                        bottom: "30px",
                      }}
                    >
                      <button className="white" onClick={handleClose}>
                        <CloseIcon />
                      </button>
                    </span>
                  </div>
                  <SignUpModal
                    setSignUpOpen={setSignUpOpen}
                    setErr={setErr}
                    setErrMessage={setErrMessage}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default memo(Wrapper);
