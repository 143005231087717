import React, { memo, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  width: "250px",
  background: "#151617",
};
function BasicModal({ prizePop, prize }) {
  const { setSpinWheel } = useContext(modalsControl);
  const handleClose = () => {
    setSpinWheel(false);
  };

  return (
    <div>
      <Modal
        open={prizePop}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="spin-prize-main-container">
            <div className="spin-prize-inner-container">
              <div className="spin-prize-content-container">
                <div className="spin-prize-img-container">
                  <div
                    style={{
                      background:
                        "linear-gradient(161deg, #b8711e -20%, #151617 80%)",
                    }}
                    className="img-container width justified"
                  >
                    <img
                      className="lotery"
                      style={{
                        maxWidth: "180px",

                        transition: ".5s",
                      }}
                      src="/imgs/lottery.png"
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      padding: "25px 0",
                    }}
                    className="prize-price-container width justified"
                  >
                    <img
                      style={{
                        maxWidth: "20px",
                        marginRight: "5px",
                      }}
                      src={`/imgs/AcceptedNetworks/${prize.prize?.network_id}.webp`}
                      alt={prize.prize?.network_id}
                    />
                    <span
                      style={{
                        color: "#5ddb1c",
                        marginRight: "5px",
                      }}
                      className="bold"
                    >
                      + {prize.prize?.amount}
                    </span>

                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="bold white"
                    >
                      {" "}
                      {prize.prize?.locked && " Locked "}
                      {prize.prize?.network_id == "bcd"
                        ? "bcw"
                        : prize.prize?.network_id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default memo(BasicModal);
