import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { modalsControl } from "../Contexts/ModalsControl";
import Accordion from "./Additionals/Accordion";
import { useTranslation } from "react-i18next";
const ReferalBeforeLogin = () => {
  const { t } = useTranslation();
  const { setSignUpOpen, setSignInOpen } = useContext(modalsControl);

  const openSignin = () => {
    setSignInOpen(true);
  };

  const openSignup = () => {
    setSignUpOpen(true);
  };
  const accordionData = [
    {
      title: t("header.question.qone"),
      data: t("header.question.ansone"),
    },
    {
      title: t("header.question.qtwo"),
      data: t("header.question.anstwo"),
    },
    {
      title: t("header.question.qthre"),
      data: t("header.question.ansthre"),
    },
    {
      title: t("header.question.qfour"),
      data: t("header.question.ansfour"),
    },
    {
      title: t("header.question.qfive"),
      data: t("header.question.ansfive"),
    },
    {
      title: t("header.question.qsix"),
      data: t("header.question.anssix"),
    },
    {
      title: t("header.question.qseven"),
      data: t("header.question.ansseven"),
    },
  ];
  return (
    <div class="main-wrapper-container">
      <div class="box-1 margin-bottom-30">
        <div class="row">
          <div class="col-md-7">
            <div class="box-1-content">
              <h1>{t("header.banner.refruend")} </h1>
              <h2>
                <b>{t("header.banner.comision")}</b>
              </h2>
              <a
                href="#"
                class="primary-btn primary-btn-bg inline-block"
                onClick={openSignup}
              >
                {" "}
                {t("header.banner.earn")}
              </a>
              <p>{t("header.banner.term")}</p>
            </div>
          </div>
          <div class="col-md-5 centered_text">
            <img
              src="/imgs/affiliate-firstBanner.png"
              alt=""
              class="width-auto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="box-2 margin-bottom-30">
            <div class="row">
              <div class="col-md-7">
                <div class="box-2-content">
                  <h1>{t("header.banner.reward")}</h1>
                  <p className="orbitron-font">{t("header.banner.detail")}</p>
                </div>
              </div>
              <div class="col-md-5 centered_text">
                <img
                  src="/imgs/affiliate-secondBanner.png"
                  alt=""
                  class="width-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box-2 margin-bottom-30">
            <div class="row">
              <div class="col-md-7">
                <div class="box-2-content">
                  <h1>{t("header.banner.rewardinpersent")} </h1>
                  <p className="orbitron-font">
                    {t("header.banner.resivedreward")}
                  </p>
                </div>
              </div>
              <div class="col-md-5 centered_text">
                <img
                  src="/imgs/affiliate-thirdBanner.png"
                  alt=""
                  class="width-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="margin-bottom-30 text-center">
        <a
          href="javascript:;"
          class="primary-btn primary-btn-bg inline-block"
          onClick={openSignin}
        >
          <img
            src="images/dashboard.png"
            alt=""
            class="width-auto margin-right-10"
          />
          {t("header.referral.dashbord")}
        </a>
      </div>
      <br />
      <div class="affiliate-accordion-contents">
        <h1> {t("header.referral.question")}</h1>
        <div class="affiliate-accordion js-acc-single">
          {accordionData.map((accordItem) => (
            <Accordion title={accordItem.title} data={accordItem.data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferalBeforeLogin;
