import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import SwapCurrencyAPI from "../../../../../../api/swap_currency";
import { loadingContext } from "../../../../../Contexts/Loading";

const SwapDataWrapper = ({
  handlePage,
  setTotalPages,
  setlastPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [swap_currencies_data, setswap_currencies_data] = useState([]);
  const { loadingData, setLoadingData } = useContext(loadingContext);

  useEffect(() => {
    setLoadingData(true);
    setlastPage(true);

    SwapCurrencyAPI.get_swap_currencies_data(currentPage)
      .then((response) => {
        setswap_currencies_data(response.data.swap_histories_data);
        handlePage(response.data.current_page);
        setTotalPages(response.data.total_pages);
        setlastPage(response.data.last_page);
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  }, [currentPage]);
  return loadingData ? (
    <div
      style={{
        top: "40%",
        left: "50%",
      }}
      className="lds-ellipsis relative"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : swap_currencies_data.length ? (
    swap_currencies_data.map((data, index) => {
      return (
        <div
          key={index}
          style={{
            margin: "10px 0",
            padding: "0px 0px 0px 25px",
            transition: ".5s",
          }}
          className="swap-send-get-data relative"
        >
          <div
            style={{
              top: "31px",
              left: "8px",
              bottom: "29px",
            }}
            className="chain-link absolute"
          >
            <img
              style={{
                maxWidth: "25px",
              }}
              src="/imgs/chainIcon.png"
              alt="chain link"
            />
          </div>

          <div
            style={{
              fontSize: "10px",
              padding: "10px",
            }}
            className="swap-send_row my_row my_row_item"
          >
            <div
              className="tarnsections-bill-swap-type-time my_row my_row_item_3 center_align font-14-on-big-screen"
              style={{
                flexDirection: "column",
                alignItems: "self-start",
              }}
            >
              <span className="secondary_text" style={{ marginBottom: "5px" }}>
                {t("header.wallet.transactionTab.swapSend")}
              </span>
              <div>
                <span className="text_color">
                  {new Date(data.created_at).toLocaleDateString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </div>
            </div>
            <div
              style={{
                boxSizing: "border-box",
                paddingRight: "15",
                justifyContent: "center",
              }}
              className="tarnsections-bill-swap-amount my_row my_row_item_3 center_align font-14-on-big-screen"
            >
              <span
                style={{
                  color: "#ed6300",
                }}
                className="bold"
              >
                {data.from_fake_amount}
              </span>
              <img
                className="amount-img"
                style={{
                  height: "1.4em",
                  width: "1.4em",
                  marginLeft: "3px",
                }}
                src={`/imgs/AcceptedNetworks/${data.from_network_id}.webp`}
                alt={data.from_network_id}
              />
            </div>
            <div className="tarnsections-bill-swap-balance my_row_item_3 my_row end center_align font-14-on-big-screen">
              <span className="bold">{data.remaining_balance}</span>
              <img
                className="hide-on-370px"
                style={{
                  height: "1.4em",
                  width: "1.4em",
                  marginLeft: "3px",
                }}
                src={`/imgs/AcceptedNetworks/${data.from_network_id}.webp`}
                alt={data.from_network_id}
              />
            </div>
          </div>

          <div
            style={{
              fontSize: "10px",
              padding: "10px",
            }}
            className="swap-send_row my_row my_row_item"
          >
            <div
              className="tarnsections-bill-swap-type-time my_row my_row_item_3 center_align font-14-on-big-screen"
              style={{ textAlign: "left" }}
            >
              <span className="secondary_text" style={{ marginBottom: "5px" }}>
                {t("header.wallet.transactionTab.swapGet")}
              </span>
              <div>
                <span className="text_color">
                  {new Date(data.created_at).toLocaleDateString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </div>
            </div>
            <div
              style={{
                boxSizing: "border-box",
                paddingRight: "15",
                justifyContent: "center",
              }}
              className="tarnsections-bill-swap-amount my_row my_row_item_3 center_align font-14-on-big-screen"
            >
              <span
                style={{
                  color: "#3bc117",
                }}
                className="bold"
              >
                {data.to_fake_amount}
              </span>
              <img
                className="amount-img"
                style={{
                  height: "1.4em",
                  width: "1.4em",
                  marginLeft: "3px",
                }}
                src={`/imgs/AcceptedNetworks/${data.to_network_id}.webp`}
                alt={data.to_network_id}
              />
            </div>
            <div className="tarnsections-bill-swap-balance my_row_item_3 my_row end center_align font-14-on-big-screen">
              <span className="bold">{data.updated_balance}</span>
              <img
                className="hide-on-370px"
                style={{
                  height: "1.4em",
                  width: "1.4em",
                  marginLeft: "3px",
                }}
                src={`/imgs/AcceptedNetworks/${data.to_network_id}.webp`}
                alt={data.to_network_id}
              />
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div
      style={{
        flexDirection: "column",
        marginTop: "60px",
      }}
      className="showEmpty-containern justified width"
    >
      <div className="show-empty-img-container">
        <img className="empty-max-width" src="/imgs/empty.png" alt="empty" />
      </div>

      <div className="show-empty-typo">
        <span style={{ fontSize: "14px" }} className="text_color">
          {t("header.referral.oop")}
        </span>
      </div>
    </div>
  );
};

export default SwapDataWrapper;
