import { useState, useEffect, createContext } from "react";

const userBetContext = createContext();

const UserBetProvider = ({ children }) => {
  const [userBetAmount, setUserBetAmount] = useState(1);
  
  return (
    <userBetContext.Provider value={{}}>{children}</userBetContext.Provider>
  );
};

export { userBetContext, UserBetProvider };
