import axiosClient from ".";
class BetRoundsAPI {
  static getCurrentGameState() {
    return axiosClient.get("/bet_rounds.json");
  }
  static getLastTenMultipliersAndIds() {
    return axiosClient.get("/bet_rounds_stats.json");
  }
}
export default BetRoundsAPI;
