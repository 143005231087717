import React from "react";
import "./CurrencyButtons.css";
const CurrencyButtons = ({ wallets, activeCurrency, activateCurrency }) => {
  const currencyActivator = (network_id) => {
    activateCurrency(network_id);
  };
  return (
    <div className="coin-buttons-container my_row space-between">
      {wallets.map(
        (coin) =>
          coin.currency_configuration.display_priority <= 4 && (
            <button
              className={`currency-button ${
                activeCurrency === coin.network_id
                  ? "active-button"
                  : "inactive-button"
              }`}
              onClick={() => currencyActivator(coin.network_id)}
            >
              <div className="currency-button-image-symbol justified">
                <img
                  className="currency-button-image"
                  src={`/imgs/AcceptedNetworks/${coin.network_id}.webp`}
                  alt={coin.network_id}
                />
                <span className="currency-button-symbol">{coin.symbol}</span>
              </div>
            </button>
          )
      )}
    </div>
  );
};

export default CurrencyButtons;
