import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMetaMask from "../../../../hooks/metamask";
import "./SignInModal.css";
// import Input from "../Inputs/input";
import { GoogleLogin } from "@react-oauth/google";

import SignInAPI from "../../../../api/signin";
import { setAuthData } from "../../../../store/user";
// import UserWalletWB from "../../../../api/user_selectedWalletAmount";
// import { setWalletWB } from "../../../../store/user";
import {
  signIn,
  signInByGoogle,
  signInByMetaMask,
} from "../../../../store/user/actions";
import GoogleSignInAPI from "../../../../api/google_signin";
import { ethers } from "ethers";
import MetaMaskSignInAPI from "../../../../api/metamask_signin";
import Alert from "../../Alert";
import { useTranslation, Trans } from "react-i18next";
const SignInModal = ({ setErr, setErrMessage }) => {
  const initialSignin = { email: "", password: "" };
  const [signInVals, setSignInVals] = useState(initialSignin);
  const [formErrors, setFormErrors] = useState({});
  const [passwordReset, setPasswordReset] = useState(false);
  const [registeredMail, setRegisteredMail] = useState("");
  const dispatch = useDispatch();
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [resetPassAlert, setResetPassAlert] = useState(false);
  const { connect, disconnect, isActive, account, shouldDisable } =
    useMetaMask();
  const signInOnChange = (e) => {
    const { name, value } = e.target;
    setSignInVals({ ...signInVals, [name]: value });
  };
  const directLogins = [
    {
      name: "google",
      img: "/imgs/google.webp",
    },

    {
      name: "metamask",
      img: "/imgs/metamask.webp",
    },
  ];

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(signInVals));
    if (
      Object.keys(formErrors).length == 0 &&
      signInVals.email &&
      signInVals.password
    ) {
      signIn(signInVals.email, signInVals.password, setErr, setErrMessage);
    }
  };

  const validate = (value) => {
    const errors = {};
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value.email) {
      errors.email = "Email is required";
    } else if (!regex.test(value.email)) {
      errors.email = "This is not a valid email format";
    }
    if (!value.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const handleClick = async (name) => {
    if (name == "metamask") {
      try {
        if (!window.ethereum) {
          alert("No crypto wallet found. Please install it.");
        } else {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = await provider.getSigner();
          const user_address = await signer.getAddress();
          // await connect()

          MetaMaskSignInAPI.metamask_signin({
            metamask: {
              eth_address: user_address,
            },
          }).then((response) => {
            signInByMetaMask(response);
          });
        }
      } catch (err) {
        setTimeout(false);
        console.log(err);
      }
    }
  };
  const responseGoogleSuccess = (response) => {
    GoogleSignInAPI.google_signin({
      google_signin: {
        token: response.credential,
      },
    })
      .then((response) => {
        signInByGoogle(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const responseGoogleError = (response) => {
    console.log(response);
  };
  const handleSection = () => {
    passwordReset ? setPasswordReset(false) : setPasswordReset(true);
  };

  const handleResetOtp = () => {
    if (!resetPassAlert) {
      SignInAPI.resetOTP({
        user: {
          email: registeredMail,
        },
      })
        .then((res) => {
          setResetPassAlert(true);
          setTimeout(() => {
            setResetPassAlert(false);
          }, 3000);
          setAlertMessage("Check your email");
          setAlertType("success");
        })
        .catch((err) => {
          setAlertType("warning");
          setResetPassAlert(true);
          setTimeout(() => {
            setResetPassAlert(false);
          }, 3000);
          setAlertMessage(err.response.data.errors.email[0]);
        });
    }
  };
  const { t } = useTranslation();
  return (
    <div
      style={{
        overflow: "hidden",
        paddingBottom: "15px",
      }}
      className="modal-form-container my_row justify relative"
    >
      <div
        style={{
          height: "100%",
          background: "#1e2024",
          zIndex: "2",
          right: passwordReset ? "0px" : "-750px",
          transition: "all 0.5s ease 0s",
          padding: "50px 35px",
          marginBottom: "0",
        }}
        className="pass-reset-fields absolute width"
      >
        <div
          style={{
            bottom: resetPassAlert ? "0px" : "-60px",
            transition: ".3s",
          }}
          className="reset-password-alert-container absolute width-80"
          onClick={() => {
            setResetPassAlert(false);
          }}
        >
          <Alert
            type={alertType}
            message={
              alertMessage === "not found"
                ? "Email incorrect."
                : alertMessage === "can't be blank"
                ? "E-mail address field cannot be empty."
                : "Check your E-mail"
            }
          />
        </div>

        <div className="reset-pass-heading-container">
          <p className="input-color">{t("header.signin.resetPass")}</p>
        </div>
        <div
          style={{
            margin: "15px 0",
          }}
          className="registered-mail-container modal-input"
        >
          <input
            className="width input-color"
            style={{
              padding: "15px",
              border: "solid 1px #3d4046",
              borderRadius: "3px",
            }}
            type="text"
            value={registeredMail}
            onChange={(e) => {
              setRegisteredMail(e.target.value);
            }}
            placeholder={t("header.signin.register")}
          />
        </div>
        <div
          className="reset-password-button-container"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          <button
            className="primary-btn primary-btn-bg"
            onClick={handleResetOtp}
          >
            {t("header.signin.resetPass")}
          </button>
        </div>
        <div
          style={{
            marginTop: "15px",
          }}
          className="have-an-account-container centered_text"
        >
          <p
            style={{
              fontSize: "12px",
            }}
            className="text_color have-account"
          >
            {t("header.signin.haveAccount")}
            <button
              style={{
                marginLeft: "5px",
              }}
              onClick={handleSection}
            >
              {t("header.signin.signin")}
            </button>
          </p>
        </div>
      </div>

      <div className="modal-form-inputs-container">
        <div className="modal-input login-email">
          <div className="input-label-container input-color">
            <label htmlFor="emailInp">{t("header.signin.email")}</label>
          </div>
          <input
            name="email"
            label="Email Adress"
            id="emailInp"
            type="text"
            value={signInVals.email}
            onChange={signInOnChange}
            placeholder={t("header.signin.enteremail")}
            className="emailInp-text-input ModalInps input-color"
          />
          <p className="signin-email-err inputErr">{formErrors.email}</p>
        </div>
        <div className="modal-input login-password">
          <div className="input-label-container input-color">
            <label htmlFor="emailInp">{t("header.signin.loginPassword")}</label>
          </div>
          <input
            name="password"
            className="passwordInp-password-input ModalInps input-color"
            label="Login Password"
            id="passwordInp"
            type="password"
            value={signInVals.password}
            onChange={signInOnChange}
            placeholder={t("header.signin.enterpassword")}
          />
          <p className="signin-password-err inputErr">{formErrors.password}</p>
          <div className="forgot-pass-container">
            <button onClick={handleSection}>
              {t("header.signin.forgotPass")}
            </button>
          </div>
        </div>
      </div>
      <div className="form-actions-container my_row justify">
        <div className="form-action-button-container">
          <button
            className="primary-btn primary-btn-bg"
            onClick={handleOnSubmit}
          >
            {t("header.signin.signin")}
          </button>
        </div>
      </div>

      <div className="direct-logins">
        <div className="direct-login-content">
          <div
            style={{
              margin: "10px 0",
            }}
            className="div or-login text_color centered_text"
          >
            <span
              style={{
                fontSize: "12px",
              }}
            >
              {t("header.signin.directSignin")}
            </span>
          </div>

          <div
            style={{
              gap: "7px",
            }}
            className="direct-directLoginItemlogins-option-container justified"
          >
            {directLogins.map((directLoginItem, index) => {
              return directLoginItem.name == "google" ? (
                <GoogleLogin
                  key={index}
                  type="icon"
                  width="20px"
                  shape="circle"
                  onSuccess={responseGoogleSuccess}
                  onError={responseGoogleError}
                />
              ) : (
                <div>
                  <button
                    key={index}
                    className="direct-login-option"
                    onClick={() => {
                      handleClick(directLoginItem.name);
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                      }}
                      src={directLoginItem.img}
                      alt="google"
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
