import React, { useEffect, useState, memo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Paper,
  DialogTitle,
  IconButton,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Draggable from "react-draggable";
import "./Trends.css";
import Asset1 from "./asset1.svg";
import Asset1Clr from "./Asset1Clr.svg";
import UTC from "./UTC";

import { modalsControl } from "../../../../../Contexts/ModalsControl";
import { useContext } from "react";
import GetTrendsAPI from "../../../../../../api/trends_api";
import { useSelector } from "react-redux";
import { createRef } from "react";
import { constants } from "../../../../../../store/constants";
import { useTranslation } from "react-i18next";
import { trendsContext } from "../../../../../Contexts/TrendsDistributor";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "448px",
  // height: "580px",
  bgcolor: "#1e2024",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  color: "#ccc",
  padding: 0,
  overflowY: "auto",
  "&::-webkit-scrollbar": { display: "none" },
};

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      className="checking-class"
    >
      <Paper {...props} />
    </Draggable>
  );
};

const Trends = () => {
  const game_state = useSelector((state) => state.game.game_state);
  const { trendModal, setTrendsModal, mblTrends } = useContext(modalsControl);
  const { utc, setUtc } = useContext(modalsControl);
  const handleClickStart = () => {
    setUtc(true);
  };

  const handleEnd = () => {
    setUtc(false);
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    // navigate("/");
    setTrendsModal(false);
  };
  const {
    first,
    setfirst,
    Second,
    setSecond,
    Third,
    setThird,
    Fourth,
    setFourth,
    Fifth,
    setFifth,
    Last,
    setLast,
  } = useContext(trendsContext);

  const ToScroll = createRef();
  const [toTriger, setToTriger] = useState(false);
  const [fetcheOnce, setFetchOnce] = useState(true);
  useEffect(() => {
    toTriger ? setToTriger(false) : setToTriger(true);
  }, [trendModal, mblTrends]);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED || fetcheOnce) {
      setFetchOnce(false);
      setTimeout(() => {
        GetTrendsAPI.get_trends().then((res) => {
          let one = res.data.data.first_row;
          let two = res.data.data.second_row;
          let three = res.data.data.third_row;
          let four = res.data.data.fourth_row;
          let five = res.data.data.fifth_row;
          let six = res.data.data.sixth_row;

          const last_emtpy = Array(32).fill(false);
          if (six && six.length > 0) {
            for (let i = 0; i < six.length; i++) {
              last_emtpy[six[i].x_val - 1] = six[i].multipler_val;
            }
          }
          setLast(last_emtpy);

          const fifth_emtpy = Array(32).fill(false);
          if (five && five.length > 0) {
            for (let i = 0; i < five.length; i++) {
              fifth_emtpy[five[i].x_val - 1] = five[i].multipler_val;
            }
          }
          setFifth(fifth_emtpy);

          const fourth_emtpy = Array(32).fill(false);
          if (four && four.length > 0) {
            for (let i = 0; i < four.length; i++) {
              fourth_emtpy[four[i].x_val - 1] = four[i].multipler_val;
            }
          }
          setFourth(fourth_emtpy);

          const third_emtpy = Array(32).fill(false);
          if (three && three.length > 0) {
            for (let i = 0; i < three.length; i++) {
              third_emtpy[three[i].x_val - 1] = three[i].multipler_val;
            }
          }
          setThird(third_emtpy);

          const second_emtpy = Array(32).fill(false);
          if (two && two.length > 0) {
            for (let i = 0; i < two.length; i++) {
              second_emtpy[two[i].x_val - 1] = two[i].multipler_val;
            }
          }
          setSecond(second_emtpy);

          const first_emtpy = Array(32).fill(false);
          if (one && one.length > 0) {
            for (let i = 0; i < one.length; i++) {
              first_emtpy[one[i].x_val - 1] = one[i].multipler_val;
            }
          }
          setfirst(first_emtpy);
        });
      }, 1000);
    }
  }, [game_state]);

  const scrollIt = () => {
    ToScroll.current.scrollLeft += 800;
  };

  useEffect(() => {
    if (ToScroll.current) {
      scrollIt();
    }
  }, [trendModal, toTriger]);

  const { t } = useTranslation();

  return (
    <div className="trends-main-container">
      <Dialog
        hideBackdrop
        disableEnforceFocus
        style={{
          position: "sticky",
          transform: "translate(398px, -221px)",
        }}
        disableScrollLock
        open={trendModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="trends-popup-design"
      >
        <DialogTitle
          style={{
            cursor: "move",
          }}
          id="draggable-dialog-title"
          sx={{
            backgroundColor: "#17181b",
            color: "#ccc",
            textAlign: "center",
            fontWeight: "bold",
            padding: 0,
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {t("gamebox.graphbar.trends")}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#17181b",
          }}
        >
          <IconButton style={{ position: "absolute", top: "0", right: "0" }}>
            <CloseIcon
              sx={{
                color: "#ccc",
                fontSize: "18px",
                paddingTop: "16px",
                "&:hover": {
                  color: "#43b309",
                },
              }}
              onClick={handleNavigate}
            />
          </IconButton>

          <DialogContentText
            sx={{
              color: "#ccc",
            }}
          >
            <div className="dots-wrap" ref={ToScroll}>
              <div
                style={{
                  width: "800px",
                  display: "grid",
                  marginBottom: "1rem",
                  gap: "1px",
                  gridTemplateColumns: " repeat(32,1fr)",
                }}
                className="dots"
              >
                {first?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
                {Second?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
                {Third?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
                {Fourth?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
                {Fifth?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
                {Last?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot-dot-type relative ${index} ${
                        row > 0 && row < 2
                          ? "type-1"
                          : row >= 10
                          ? "type-3"
                          : row == 0
                          ? "type-0"
                          : "type-2"
                      }`}
                    >
                      {/* <span className="here">{row}</span> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              style={{
                border: "solid 1px #1f2024",
              }}
              onClick={handleClickStart}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "flex-start",
                textDecoration: "none",
                color: "#ccc",
              }}
            >
              <HelpOutlineIcon
                sx={{
                  color: "#43b309",
                }}
              />
              &nbsp;
              <span
                style={{
                  textTransform: "capitalize",
                  marginTop: "1px",
                  fontSize: "12px",
                }}
              >
                {t("gamebox.graphbar.understandingchart")}
              </span>
            </Button>
            {/* <button onClick={scrollIt}>scroll</button> */}
            <Modal
              open={utc}
              onClose={handleEnd}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="modals-height-on-mobiles">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    width: "auto",
                    backgroundColor: "#17181b",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    padding: "20px 20px 20px 20px",
                    position: "sticky",
                    top: 0,
                    zIndex: 9999,
                  }}
                >
                  <div className="understanding-chart-container my_row space-between">
                    <span
                      className="orbitron-font"
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {t("gamebox.graphbar.understandingchart")}
                    </span>
                    <span>
                      <button
                        style={{
                          color: "#fff",
                        }}
                        onClick={() => {
                          setUtc(false);
                        }}
                      >
                        <CloseIcon />
                      </button>
                    </span>
                  </div>
                </Typography>
                <div
                  style={{
                    padding: "20px",
                  }}
                >
                  <UTC />
                </div>
              </Box>
            </Modal>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(Trends);
