import axiosClient from ".";
class UserWalletsAPI {
  static getUserWallets() {
    return axiosClient.get("/user_wallets.json");
  }
  static setDefaultUserWallets(id, data) {
    return axiosClient.put(`/user_wallets/${id}.json`, data);
  }
  static withdrawalToWallet(data) {
    return axiosClient.post("/api/v1/transaction/withdrawals", data);
  }
}
export default UserWalletsAPI;
