import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../constants";
const initialState = {
  game_state: null,
  is_loading: true,
  total_players: 0,
  total_cashed_out_players: 0,
  multiplier_history: [],
  game_ids_history: [],
  final_multiplier: 0,
  final_time: 0,
  error: null,
  pool_amounts: [],
  small_history_loading: false,
  multiplier_history_complete: [],
  game_ids_history_complete: [],
  hashes_history_complete: [],
  server_game_state: null,
  server_state_time: null,
  server_game_state_loading: false,
  next_round_bet_placed: false,
  selected_user_profile: null,
  conversion_rates: [],
  game_histories_data: [],
  hide_Small: false,
  view_in_fiat: false,
  //Other technical states
};
const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setGameStateLoading: (state, action) => {
      state.is_loading = true;
    },
    setGameState: (state, action) => {
      state.game_state = action.payload.game_state;
    },

    setTotalCashedOutPlayers: (state, action) => {
      state.total_cashed_out_players = action.payload.total_cashed_out_players;
    },

    setMultiplierHistory: (state, action) => {
      state.multiplier_history = action.payload.multiplier_history;
    },
    setGameIdsHistory: (state, action) => {
      state.game_ids_history = action.payload.game_ids_history;
    },
    smallHistoryLoading: (state, action) => {
      state.small_history_loading = action.payload.small_history_loading;
    },
    setMultiplierHistoryComplete: (state, action) => {
      const temp = action.payload.multiplier_history_complete;
      state.multiplier_history_complete = temp.reverse();
    },
    setGameIdsHistoryComplete: (state, action) => {
      const temp = action.payload.game_ids_history_complete;
      state.game_ids_history_complete = temp.reverse();
    },
    // hashes_history_complete : [],

    setHashesHistoryComplete: (state, action) => {
      const temp = action.payload.hashes_history_complete;
      state.hashes_history_complete = temp.reverse();
    },
    setPoolAmounts: (state, action) => {
      state.pool_amounts = action.payload.pool_amounts;
    },
    setFinalMultiplier: (state, action) => {
      state.final_multiplier = action.payload.final_multiplier;
    },
    setFinalTime: (state, action) => {
      state.final_time = action.payload.final_time;
    },
    setServerGameState: (state, action) => {
      state.server_game_state = action.payload.server_game_state;
    },
    setServerStateTime: (state, action) => {
      state.server_state_time = action.payload.server_state_time;
    },
    setNextRoundBetPlaced: (state, action) => {
      state.next_round_bet_placed = action.payload.next_round_bet_placed;
    },
    setSelectedUserProfile: (state, action) => {
      state.selected_user_profile = action.payload.selected_user_profile;
    },

    setConversionRates: (state, action) => {
      state.conversion_rates = action.payload.conversion_rates;
    },

    setGameHistoriesData: (state, action) => {
      state.game_histories_data = action.payload.game_histories_data;
    },
    setServerGameStateLoading: (state, action) => {
      state.server_game_state_loading =
        action.payload.server_game_state_loading;
    },
    setHideSmall: (state, action) => {
      state.hide_Small = action.payload.hide_Small;
    },
    setViewInFiat: (state, action) => {
      state.view_in_fiat = action.payload.view_in_fiat;
    },
  },
});
export const {
  setGameState,
  setGameStateLoading,
  setCountDownStart,
  setCountDownStop,
  setMultiplierStart,
  setMultiplierStop,
  setTotalPlayers,
  setTotalCashedOutPlayers,
  setMultiplierHistory,
  setFinalMultiplier,
  setFinalTime,
  setGameIdsHistory,
  setPoolAmounts,
  setGameIdsHistoryComplete,
  setMultiplierHistoryComplete,
  setServerGameState,
  setServerStateTime,
  setNextRoundBetPlaced,
  smallHistoryLoading,
  setHashesHistoryComplete,
  setSelectedUserProfile,
  setConversionRates,
  setGameHistoriesData,
  setServerGameStateLoading,
  setHideSmall,
  setViewInFiat,
} = gameSlice.actions;
export default gameSlice.reducer;
