import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { modalsControl } from "../../Contexts/ModalsControl";

const MobileTrendsOpener = () => {
  const { setMblTrends, mblTrends } = useContext(modalsControl);
  const navigate = useNavigate();
  const location = useLocation();
  const openTrends = () => {
    setMblTrends(!mblTrends);
  };
  return (
    <button onClick={openTrends}>
      <svg
        style={{
          maxWidth: "20px",
        }}
        id="e59U203AIH61"
        xmlns="http://www.w3.org/2000/svg"
        link="http://www.w3.org/1999/xlink"
        viewBox="0 0 300 300"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <g transform="matrix(5.488765 0 0 5.404616-452.436422-405.000067)">
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(90.115286 82.621691)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(129.400907 82.621691)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(109.758097 102.690007)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(109.758097 82.621691)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(90.115286 122.758327)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="7.685739"
            ry="7.685739"
            transform="translate(129.400907 122.758327)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="17.417773"
            ry="19.998074"
            transform="matrix(.441216 0 0 0.384287 90.117503 102.689268)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
          <ellipse
            rx="17.417773"
            ry="19.998074"
            transform="matrix(.441216 0 0 0.384287 129.400168 102.689268)"
            fill={
              location.pathname === "/crash/mobileTrends" ? "#fff" : "#60646e"
            }
            strokeWidth="0"
          />
        </g>
      </svg>
      &nbsp;&nbsp;
      <span
        style={{
          color:
            location.pathname === "/crash/mobileTrends" ? "white" : "#5f636d",
        }}
      >
        Trends
      </span>
    </button>
  );
};

export default MobileTrendsOpener;
