import { useState, useEffect } from "react";

import "./Buy.css";
// Images
import BTC from "../Deposit/Crypto/imgs/BTC.webp";
import ETH from "../Deposit/Crypto/imgs/ETH.webp";
import BNB from "../Deposit/Crypto/imgs/BNB.webp";
import TRX from "../Deposit/Crypto/imgs/TRX.webp";
import USDT from "../Deposit/Crypto/imgs/USDT.webp";

import MoonPayimg from "./imgs/MoonPay.webp";
import Banxaimg from "./imgs/Banxa.webp";
import MasterCardimg from "./imgs/visaCard.png";
import ApplePayimg from "./imgs/applePay.png";
import WireTransferimg from "./imgs/wiretransfer.png";

const Buy = () => {
  const [MoonPay, setMoonPay] = useState(true);
  const [Banxa, setBanxa] = useState(false);

  const [optionState, setOptionState] = useState("BTC");
  const [imgURL, setImgURL] = useState(BTC);
  const [MasterCard, setMasterCard] = useState(true);
  const [ApplePay, setApplePay] = useState(false);
  const [WireTransfer, setWireTransfer] = useState(false);

  const masterSetter = () => {
    setMasterCard(true);
    setApplePay(false);
    setWireTransfer(false);
  };
  const appleSetter = () => {
    setMasterCard(false);
    setApplePay(true);
    setWireTransfer(false);
  };
  const wireSetter = () => {
    setMasterCard(false);
    setApplePay(false);
    setWireTransfer(true);
  };
  useEffect(() => {
    if (optionState === "BTC") {
      setImgURL(BTC);
    }
    if (optionState === "ETH") {
      setImgURL(ETH);
    }
    if (optionState === "BNB") {
      setImgURL(BNB);
    }
    if (optionState === "TRX") {
      setImgURL(TRX);
    }
    if (optionState === "USDT") {
      setImgURL(USDT);
    }
  }, [optionState]);

  const dropdownHandle = (e) => {
    setOptionState(e.target.value);
  };
  const MoonPaySetter = (e) => {
    setMoonPay(true);
    setBanxa(false);
  };
  const BanxaSetter = () => {
    setBanxa(true);
    setMoonPay(false);
  };

  const payMethod = [
    {
      img: MoonPay,
      name: "MoonPay",
    },
    {
      img: Banxa,
      name: "Banxa",
    },
  ];
  const BuyCroptoButtonHandle = () => {
    alert("You are going to buy crypto")
  }

  return(
    <div style={{
      height: "100%"
    }}className="commingSoon wallet-scroll-whole">
    <img src="https://img.freepik.com/free-vector/abstract-coming-soon-halftone-style-background-design_1017-27282.jpg" alt="Coming soon" />
    </div>
  )
  // return (
  //   <div className="buy-crypto-main">
  //     <div className="buy-crypto-content">
  //       <div className="buy-crypto-top-container my_row justify">
  //         <div
  //           id="MoonPay"
  //           className={`moonPay-container payMethods-Styling my_row justify pointer ${
  //             MoonPay && "forBorderOnActive"
  //           }`}
  //           onClick={MoonPaySetter}
  //         >
  //           <div className={`pay-method-img-container`}>
  //             <img src={MoonPayimg} alt="PayMethod" />
  //           </div>
  //           <div className="pay-method-name-container">
  //             <span className="bold">MoonPay</span>
  //           </div>
  //         </div>

  //         <div
  //           id="Banxa"
  //           className={`moonPay-container payMethods-Styling my_row justify pointer ${
  //             Banxa && "forBorderOnActive"
  //           }`}
  //           onClick={BanxaSetter}
  //         >
  //           <div className={`pay-method-img-container`}>
  //             <img src={Banxaimg} alt="PayMethod" />
  //           </div>
  //           <div className="pay-method-name-container">
  //             <span className="bold">Banxa</span>
  //           </div>
  //         </div>

  //       </div>

  //       <div className="buy-crypto-middle-container">
  //         <div className="wallet-crypto-coins-dropdown">
  //           <div className="wallet-crypto-coins-dropdown-content">
  //             <div className="wallet-crypto-dropdown-top">
  //               <span className="text_color">You Pay With</span>
  //             </div>

  //             <div className="wallet-crypto-dropdown-bottom my_row width">
  //               <div className="wallet-coin-dropdown">
  //                 <form className="relative">
  //                   <div className="dropDown-Coin-Img absolute">
  //                     <img src={imgURL} alt="" />
  //                   </div>
  //                   <select
  //                     className="coinsSelect"
  //                     name="coins"
  //                     id="coins"
  //                     value={optionState}
  //                     onChange={dropdownHandle}
  //                   >
  //                     <option value="BTC">BTC</option>
  //                     <option value="ETH">ETH</option>
  //                     <option value="BNB">BNB</option>
  //                     <option value="TRX">TRX</option>
  //                     <option value="USDT">USDT</option>
  //                   </select>
  //                 </form>
  //               </div>
  //               <div className="wallet-coin-balance">
  //                 <span className="bold white">100384.78 </span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="wallet-crypto-coins-dropdown">
  //           <div className="wallet-crypto-coins-dropdown-content">
  //             <div className="wallet-crypto-dropdown-top">
  //               <span className="text_color">You Get</span>
  //             </div>

  //             <div className="wallet-crypto-dropdown-bottom my_row width">
  //               <div className="wallet-coin-dropdown">
  //                 <form className="relative">
  //                   <div className="dropDown-Coin-Img absolute">
  //                     <img src={imgURL} alt="" />
  //                   </div>
  //                   <select
  //                     className="coinsSelect"
  //                     name="coins"
  //                     id="coins"
  //                     value={optionState}
  //                     onChange={dropdownHandle}
  //                   >
  //                     <option value="BTC">BTC</option>
  //                     <option value="ETH">ETH</option>
  //                     <option value="BNB">BNB</option>
  //                     <option value="TRX">TRX</option>
  //                     <option value="USDT">USDT</option>
  //                   </select>
  //                 </form>
  //               </div>
  //               <div className="wallet-coin-balance">
  //                 <span className="bold white">100384.78 </span>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="buy-crypto-bottom-container">
  //         <div className="buy-crypto-banks-container">
  //           <div className="paymentMethod-selection">
  //             <span className="text_color">Payment Method Selection</span>
  //           </div>

  //           <div
  //             className={`buy-crypto-bank ${MasterCard && "forBorderOnActive"}`}
  //             onClick={masterSetter}
  //           >
  //             <div className="buy-crypto-bank-name">
  //               <span className="bold">Visa/MasterCard</span>
  //             </div>
  //             <div className="buy-crypto-img-container">
  //               <img
  //                 id="maxter-card-img"
  //                 src={MasterCardimg}
  //                 alt="Master Card"
  //               />
  //             </div>
  //           </div>
  //           {Banxa && (
  //             <div className="parent-div">
  //               <div
  //                 className={`buy-crypto-bank ${
  //                   ApplePay && "forBorderOnActive"
  //                 }`}
  //                 onClick={appleSetter}
  //               >
  //                 <div className="buy-crypto-bank-name">
  //                   <span className="bold">ApplePay</span>
  //                 </div>
  //                 <div className="buy-crypto-img-container">
  //                   <img src={ApplePayimg} alt="Master Card" />
  //                 </div>
  //               </div>

  //               <div
  //                 className={`buy-crypto-bank ${
  //                   WireTransfer && "forBorderOnActive"
  //                 }`}
  //                 onClick={wireSetter}
  //               >
  //                 <div className="buy-crypto-bank-name">
  //                   <span className="bold">WireTransfer</span>
  //                 </div>
  //                 <div className="buy-crypto-img-container">
  //                   <img src={WireTransferimg} alt="Master Card" />
  //                 </div>
  //               </div>
  //             </div>
  //           )}

  //         </div>
  //         <div className="buy-balance-button my_row justify">
  //           <button className="betit-button white" onClick={BuyCroptoButtonHandle}>Buy Now</button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Buy;
