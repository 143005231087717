import { useState, useEffect, createContext } from "react";

const loadingContext = createContext();

const LoadingContextProvider = ({ children }) => {
  const [loadingData, setLoadingData] = useState(false);

  return (
    <loadingContext.Provider
      value={{
        loadingData,
        setLoadingData,
      }}
    >
      {children}
    </loadingContext.Provider>
  );
};

export { loadingContext, LoadingContextProvider };
