import React, { useState, useEffect, useContext } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { loadingContext } from "../../Contexts/Loading";
import { useTranslation, Trans } from "react-i18next";
const Paginator = ({ currentPage, totalPages, lastPage, handlePage }) => {
  const { t } = useTranslation();
  const [customPage, setCustomPage] = useState(currentPage);
  const { loadingData } = useContext(loadingContext);
  const ascendPage = () => {
    if (!lastPage) {
      handlePage(currentPage + 1);
    }
  };
  const descendPage = () => {
    if (loadingData) {
      return;
    }

    if (currentPage - 1 >= 1) {
      if (loadingData) {
        return;
      }
      handlePage(currentPage - 1);
    }
  };

  const handleCustomPage = (e) => {
    const { value } = e.target;
    setCustomPage(value);
  };

  const toToPage = (e) => {
    if (loadingData) {
      return;
    }
    if (e.key === "Enter") {
      handlePage(customPage);
    }
  };

  useEffect(() => {
    setCustomPage(currentPage);
  }, [currentPage]);

  return (
    <div className="pagination-container">
      <div
        style={{
          gap: "20px",
          background: "#161616",
          padding: "10px",
          borderRadius: "3px",
          marginTop: "10px",
        }}
        className="pagination-buttons-container my_row"
      >
        <div className="pagination-total-pages-number-count justified">
          <p className="text_color">{t("header.referral.tot")} {totalPages}</p>
        </div>

        <div className="pagination-current-page-input width-10">
          <input
            style={{
              backgroundColor: "black",
              fontWeight: "normal",
            }}
            className="width height centered_text"
            type="number"
            value={customPage}
            onChange={(e) => handleCustomPage(e)}
            onKeyPress={(e) => toToPage(e)}
          />
        </div>

        <div className="ascend-descend-buttons-container my_row">
          <div
            className="prev-button page-action-button text_color"
            onClick={descendPage}
          >
            <NavigateBeforeIcon />
          </div>
          <div
            className="next-button page-action-button text_color"
            onClick={ascendPage}
          >
            <NavigateNextIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
