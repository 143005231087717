import axiosClient from ".";
class SwapCurrencyAPI {
  static swap_currency(data) {
    return axiosClient.post("/api/v1/transaction/swaps", data);
  }
  static get_swap_currencies_data(currentPage) {
    return axiosClient.get(`/api/v1/transaction/swaps?page=${currentPage}`);
  }
}
export default SwapCurrencyAPI;
