import { useState, useContext, useEffect, memo } from "react";
import "./ManualBet.css";
import { useSelector } from "react-redux";
import { betPriceContext } from "../../../../../../Contexts/BetPrice";
import { useTranslation } from "react-i18next";

const ManualBet = () => {
  const conversion_rates = useSelector((state) => state.game.conversion_rates);
  const [sliderToggle, setSliderToggle] = useState(false);
  const [usdAmount, setUsdAmount] = useState(0);
  const [usdRates, setUsdRates] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const locked_bet_amount = useSelector(
    (state) => state.bets.locked_bet_amount
  );
  const betActive = useSelector((state) => state.bets.betActive);
  const nextRoundBetPlaced = useSelector(
    (state) => state.game.next_round_bet_placed
  );
  useEffect(() => {
    if (betActive || nextRoundBetPlaced) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [betActive, nextRoundBetPlaced]);
  const [maxBet, setMaxBet] = useState(0);
  const { activatedCurrency, betAmmount, setBetPrice, fixed } =
    useContext(betPriceContext);
  useEffect(() => {
    if (!currentUser) {
      setMaxBet(2000);
    } else {
      if (
        parseFloat(activatedCurrency?.fake_amount) >
        parseFloat(activatedCurrency?.currency_limit.max_bet)
      ) {
        setMaxBet(activatedCurrency?.currency_limit.max_bet);
      } else {
        setMaxBet(activatedCurrency?.fake_amount);
      }
    }
  }, [activatedCurrency]);
  useEffect(() => {
    if (activatedCurrency?.network_id == "wb") {
      setUsdAmount(0);
    } else {
      console.log(
        "activatedCurrency?.network_id ::: ",
        activatedCurrency?.network_id
      );
      setUsdRates(
        conversion_rates.conversion_rates?.find((formula) => {
          return formula.network_id == activatedCurrency?.network_id;
        })
      );
      console.log(
        "conversion_rates.conversion_rates",
        conversion_rates.conversion_rates
      );
      console.log(
        "finded ::: ",
        conversion_rates.conversion_rates.find(
          (rate) =>
            rate.from_currency === activatedCurrency?.network_id &&
            rate.to_currency === "united_states_dollar"
        )
      );
      setUsdAmount(
        conversion_rates.conversion_rates.find(
          (rate) =>
            rate.from_currency === activatedCurrency?.network_id &&
            rate.to_currency === "united_states_dollar"
        ).rate
      );
    }
  }, [betAmmount, activatedCurrency?.network_id]);
  const setSlider = () => {
    if (!betActive && !nextRoundBetPlaced) {
      sliderToggle ? setSliderToggle(false) : setSliderToggle(true);
    }
  };
  const handleChange = (e) => {
    setBetPrice(e.target.value);

    if (e.target.name === "sliderField") {
      setBetPrice(parseFloat(e.target.value));
    }
  };
  const onBlur = (e) => {
    if (!e.target.value || parseFloat(e.target.value) == 0) {
      setBetPrice(parseFloat(activatedCurrency?.currency_limit.min_bet));
    } else {
      if (parseFloat(e.target.value) > parseFloat(maxBet)) {
        setBetPrice(maxBet);
      } else {
        setBetPrice(e.target.value);
      }
    }
  };
  const multiplyHandle = () => {
    if (!betActive && !nextRoundBetPlaced) {
      if (parseFloat(betAmmount) * 2 > parseFloat(maxBet)) {
        setBetPrice(parseFloat(maxBet));
      } else {
        setBetPrice(parseFloat(betAmmount * 2));
      }
    }
  };
  const divideHandle = () => {
    if (!betActive && !nextRoundBetPlaced) {
      if (
        parseFloat(betAmmount) / 2 <
        parseFloat(activatedCurrency?.currency_limit.min_bet)
      ) {
        setBetPrice(parseFloat(activatedCurrency?.currency_limit.min_bet));
      } else {
        setBetPrice(parseFloat(betAmmount / 2));
      }
    }
  };
  const handleMin = () => {
    if (currentUser) {
      setBetPrice(parseFloat(activatedCurrency?.currency_limit.min_bet));
    } else {
      setBetPrice(1);
    }
  };
  const handleMax = () => {
    setBetPrice(maxBet);
  };
  useEffect(() => {
    if (!currentUser) {
      setBetPrice(1);
    }
  }, []);
  const { t } = useTranslation();

  return (
    <div className="manual-price-content text_color">
      <div className="manual-price-top-div my_row orbitron-font">
        <div className="help-tool-tip">
          <p className="orbitron-font">{t("gamebox.betit.amount")}</p>
        </div>
        <div className="manual-price-top-price">
          <span style={{ color: "#2fa5cd" }}>
            {!currentUser || activatedCurrency?.network_id == "wb"
              ? " 0 "
              : (parseFloat(usdAmount) * parseFloat(betAmmount).toFixed(8))
                  .toString()
                  .substring(0, 10) + " "}
          </span>
          USD
        </div>
      </div>
      <div className="manual-price-bottom-div my_row relative justify">
        <div className="bet-ammount-picker">
          <input
            type="number"
            className={`betAmmountField ${isDisable ? "disabled" : "white"}`}
            value={betActive ? locked_bet_amount : betAmmount}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={isDisable}
          />
          <div className="coin-svg-container absolute">
            <img
              src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
              alt={activatedCurrency?.network_id}
            />
          </div>
        </div>
        <div className="bet-ammount-ammount-arithmetic-container my_row justify absolute">
          <div className="bet-ammount-mathem my_row justify">
            <div className="bet-ammount-multiply">
              <button
                accessKey="s"
                className="bet-ammount-multiplier common-for-arethms"
                onClick={multiplyHandle}
              >
                x2
              </button>
            </div>
            <div className="bet-ammount-divider-container">
              <button
                accessKey="a"
                className="bet-ammount-divider 
                common-for-arethms"
                onClick={divideHandle}
              >
                /2
              </button>
            </div>
          </div>
          <div className="bet-ammount-range-selecter white">
            <button
              className="bet-ammount-rangeSelecter common-for-arethms"
              onClick={setSlider}
              name="rangeActivator"
            >
              <div className="button-up-arrow-container white">
                <i className="fa-solid fa-angle-up"></i>
              </div>
              <div className="button-down-arrow-container white">
                <i className="fa-solid fa-angle-down"></i>
              </div>
            </button>
          </div>
          {sliderToggle && (
            <div className="bet-ammount-range-container absolute my_row justify relative">
              <input
                name="sliderField"
                className="slider"
                type="range"
                onChange={handleChange}
                value={betAmmount}
                step="0.000001"
                max={maxBet}
                min={
                  currentUser ? activatedCurrency?.currency_limit.min_bet : 1
                }
              />
              <div className="slider-min-container absolute">
                <button
                  className="common-for-min-max slider-min"
                  onClick={handleMin}
                >
                  {t("gamebox.betit.min")}
                </button>
              </div>
              <div className="slider-max-container absolute">
                <button
                  className="common-for-min-max slider-max"
                  onClick={handleMax}
                >
                  {t("gamebox.betit.max")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ManualBet);
