import React from "react";

const ChatPreloader = () => {
  const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  return count.map((count) => (
    <div
      style={{
        margin: "20px 0",
      }}
      className="global-chat-message my_row"
    >
      <div
        className="message-user-img-container"
        style={{ width: "40px", marginRight: "15px" }}
      >
        <img
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
          }}
          src="/imgs/userProfile/userProfile.png"
          alt=""
        />
      </div>

      <div
        style={{ fontSize: "15px", width: "calc(100% + -60px)" }}
        className="message-typo-container"
      >
        <div className="message-username-container text_color">
          <span
            className="orbitron-font"
            style={{
              color: "#ADC862",
              fontSize: "11px",
              marginRight: "20px",
            }}
          ></span>
          <span style={{ fontSize: "10px" }}></span>
        </div>
        <div
          className="message-userMessage-container secondary_text preLoader-animation"
          style={{
            background: "#2e323b",
            borderRadius: "0px 20px 0px 20px",
            padding: "20px",
            marginTop: "10px",
            fontSize: "13px",
            wordBreak: "break-all",
          }}
        ></div>
      </div>
    </div>
  ));
};

export default ChatPreloader;
