import React, { useContext } from "react";
import "./CurrencyDropdownSelect.css";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { modalsControl } from "../../Contexts/ModalsControl";

const CurrencyDropdownSelect = ({ activated, text }) => {
  const { setAssetsPortfolio } = useContext(modalsControl);
  const { t } = useTranslation();
  const handleAssetsPortfolio = () => {
    setAssetsPortfolio(true);
  };
  return (
    <div className="wallet-crypto-coins-dropdown">
      <div className="wallet-crypto-coins-dropdown-content">
        {text && (
          <div className="wallet-crypto-dropdown-top my_row space-between">
            <span className="text_color">
              {t("header.wallet.depositTab.depositCurrency")}
            </span>
            <span className="text_color">
              {t("header.wallet.depositTab.record")}
            </span>
          </div>
        )}

        <div className="wallet-crypto-dropdown-bottom justified width">
          <button
            className="wallet-coin-dropdown my_row space-between white uppercase"
            onClick={handleAssetsPortfolio}
          >
            <div className="active-currency-img-symbol-container justified">
              <div className="dropDown-Coin-Img">
                <img
                  src={`/imgs/AcceptedNetworks/${activated.network_id}.webp`}
                  alt=""
                />
              </div>
              <div className="activated-symbol-container">
                <span className="bold">{activated.symbol}</span>
              </div>
            </div>
            <div className="dropdown-icon-container secondary_text">
              <KeyboardArrowDownIcon />
            </div>
          </button>
          <div className="wallet-coin-balance width-45 my_row end">
            <div
              style={{
                marginRight: "5px",
              }}
            >
              <span className="text_color">
                {t("header.wallet.depositTab.balance")}
              </span>
            </div>
            <span className="bold white">{activated?.fake_amount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyDropdownSelect;
