import axiosClient from ".";
class TwoFactorAuth {
  static two_factor_auth() {
    return axiosClient.get("/users/activate_2fa");
  }
  static activate_2fa_update(data) {
    return axiosClient.post("/users/activate_2fa_update", data);
  }
  static verify_otp(data) {
    return axiosClient.post("/users/verify_otp", data);
  }
}
export default TwoFactorAuth;
