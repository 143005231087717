import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BcwModal from "../../../BcwModal";

const Currencies = ({
  activeWallets,
  walletType,
  activeCurrency,
  hide_Small,
  filterId,
  setFilterId,
  activeTab,
}) => {
  const [wallets, setWallets] = useState(
    activeWallets.filter((item) => item.type === walletType)
  );
  useEffect(() => {
    if (!filterId) {
      setWallets(activeWallets.filter((item) => item.type === walletType));
    } else {
      setWallets(
        activeWallets.filter(
          (item) =>
            item.network_id.includes(filterId) || item.symbol.includes(filterId)
        )
      );
    }
  }, [filterId, activeTab]);
  useEffect(() => {
    setFilterId("");
    if (hide_Small) {
      setWallets(
        wallets.filter(
          (item) => parseFloat(item.fake_amount) > 0 && item.type === walletType
        )
      );
    } else {
      setWallets(activeWallets.filter((item) => item.type === walletType));
    }
  }, [hide_Small, activeTab]);

  const bcwData = useSelector((state) => state.user.bcwData);

  return (
    <div className="tab-view-content">
      {wallets?.map((currency, index) => {
        return (
          currency.currency_configuration.useable && (
            <div
              key={index}
              className={`userCurrency-item my_row space-between white width relative ${
                currency.default_wallet ? "selected-currency" : "rest-currency"
              }`}
            >
              <button
                style={{
                  background: "transparent",
                  width: "100%",
                  height: "100%",
                  left: "0",
                  border: "none",
                }}
                name={currency.id}
                onClick={() =>
                  activeCurrency(currency.id, currency.is_usdt, currency)
                }
                className="absolute"
              ></button>

              <div
                name={currency.network_id}
                className="userCurrency-image-container justified"
              >
                <img
                  style={{
                    width: "1.875rem",
                    height: "1.875rem",
                    marginRight: "5px",
                  }}
                  src={`/imgs/AcceptedNetworks/${currency.network_id}.webp`}
                  alt={currency.network_id}
                />
                <span
                  className="coinName"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {currency.symbol}
                </span>
              </div>
              <div
                name={currency.network_id}
                className="userCurrency-ammount-container"
              >
                <span>{currency.fake_amount}</span>
                {currency.network_id == "bcw" && (
                  <BcwModal
                    claimed={bcwData?.claimed}
                    locked={bcwData?.locked}
                    unlocked={bcwData?.unlocked}
                  />
                )}
                <div></div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default Currencies;
