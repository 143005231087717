import SignInAPI from "../../api/signin";
import UserWalletsAPI from "../../api/user_wallets";
import UserProfilesAPI from "../../api/user_profiles";
import { store } from "..";
import {
  setAuthData,
  setLoadingWalletInfo,
  setUserWallets,
  setbcwData,
  setTwoFAEnabled,
  setVerified,
} from ".";
import { setUserProfile } from ".";
import BcwDataApi from "../../api/bcw_data";
export const signIn = (email, password, serErr, setErrMessage) => {
  store.dispatch(
    setLoadingWalletInfo({
      loadingWalletInfo: true,
    })
  );
  SignInAPI.verify({
    session: {
      email: email,
      password: password,
    },
    setUserWallets,
  })
    .then((response) => {
      store.dispatch(
        setVerified({
          verified: response.data.confirmed,
        })
      );
      UserProfilesAPI.get_signin_user_profile()
        .then((response) => {
          store.dispatch(
            setUserProfile({
              user_profile: response.data.user_profile,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
      //////////////////////////////////
      //Getting user's wallets
      UserWalletsAPI.getUserWallets()
        .then((response) => {
          BcwDataApi.getBcwData()
            .then((response) => {
              store.dispatch(
                setbcwData({
                  bcwData: response.data.bcw,
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
          store.dispatch(
            setUserWallets({
              wallets: response.data,
            })
          );
          store.dispatch(
            setLoadingWalletInfo({
              loadingWalletInfo: false,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(
            setLoadingWalletInfo({
              loadingWalletInfo: false,
            })
          );
        });
      store.dispatch(
        setAuthData({
          currentUser: {
            user_id: response.data.user_id,
            email: response.data.email,
            username: response.data.username,
          },
          token: response.headers.authorization,
        })
      );
      store.dispatch(
        setTwoFAEnabled({
          two_fa_enabled: response.data.two_fa_enabled,
        })
      );
      localStorage.setItem("token", response.headers.authorization);
    })
    .catch((error) => {
      setErrMessage(error.response.data.message);
      console.log(error.response.data.message);

      serErr(true);
      setTimeout(() => {
        serErr(false);
      }, 2000);

      store.dispatch(
        setLoadingWalletInfo({
          loadingWalletInfo: false,
        })
      );
    });
};

export const signInByGoogle = (response) => {
  store.dispatch(
    setLoadingWalletInfo({
      loadingWalletInfo: true,
    })
  );

  UserProfilesAPI.get_signin_user_profile()
    .then((response) => {
      store.dispatch(
        setUserProfile({
          user_profile: response.data.user_profile,
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });
  //////////////////////////////////
  //Getting user's wallets
  UserWalletsAPI.getUserWallets()
    .then((response) => {
      store.dispatch(
        setUserWallets({
          wallets: response.data,
        })
      );
      store.dispatch(
        setLoadingWalletInfo({
          loadingWalletInfo: false,
        })
      );
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(
        setLoadingWalletInfo({
          loadingWalletInfo: false,
        })
      );
    });
  store.dispatch(
    setAuthData({
      currentUser: {
        user_id: response.data.user_id,
        email: response.data.email,
        username: response.data.username,
      },
      token: response.headers.authorization,
    })
  );
  store.dispatch(
    setTwoFAEnabled({
      two_fa_enabled: response.data.two_fa_enabled,
    })
  );
  localStorage.setItem("token", response.headers.authorization);
  // })
  //     .catch((error) => {
  //       store.dispatch(
  //         setLoadingWalletInfo({
  //           loadingWalletInfo: false,
  //         })
  //       );
  //     });
};

export const signInByMetaMask = (response) => {
  store.dispatch(
    setLoadingWalletInfo({
      loadingWalletInfo: true,
    })
  );

  //Getting users profile data

  UserProfilesAPI.get_signin_user_profile()
    .then((response) => {
      store.dispatch(
        setUserProfile({
          user_profile: response.data.user_profile,
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });
  //////////////////////////////////
  //Getting user's wallets
  UserWalletsAPI.getUserWallets()
    .then((response) => {
      store.dispatch(
        setUserWallets({
          wallets: response.data,
        })
      );
      store.dispatch(
        setLoadingWalletInfo({
          loadingWalletInfo: false,
        })
      );
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(
        setLoadingWalletInfo({
          loadingWalletInfo: false,
        })
      );
    });
  store.dispatch(
    setAuthData({
      currentUser: {
        user_id: response.data.user_id,
        email: response.data.email,
        username: response.data.username,
      },
      token: response.headers.authorization,
    })
  );
  store.dispatch(
    setTwoFAEnabled({
      two_fa_enabled: response.data.two_fa_enabled,
    })
  );
  localStorage.setItem("token", response.headers.authorization);
  // })
  //     .catch((error) => {
  //       store.dispatch(
  //         setLoadingWalletInfo({
  //           loadingWalletInfo: false,
  //         })
  //       );
  //     });
};
