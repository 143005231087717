import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";

function ChatRules() {
  const { height, width } = useWindowDimensions();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 767 ? width : 400,
    background: "#17181b",
    // height: width <= 767 ? height : "50%",
    padding: "30px",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const chatRules = [
    {
      id: "1",
      rule: t("header.chat.rule1"),
    },
    {
      id: "2",
      rule: t("header.chat.rule2"),
    },
    {
      id: "3",
      rule: t("header.chat.rule3"),
    },
    {
      id: "4",
      rule: t("header.chat.rule4"),
    },
    {
      id: "5",
      rule: t("header.chat.rule5"),
    },
    {
      id: "6",
      rule: t("header.chat.rule6"),
    },
  ];

  return (
    <div>
      <button className="text_color" onClick={handleOpen}>
        <HelpOutlineIcon />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals-height-on-mobiles">
          <div className="chat-rules-main-container">
            <div className="chat-rules-inner-container">
              <div className="chat-rules-content-container text-justify">
                <div className="chat-rules-head-container my_row space-between">
                  <span className="orbitron-font">
                    {t("header.chat.chatRules")}
                  </span>

                  <button className="secondary_text" onClick={handleClose}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="chat-rules-content-container">
                  {chatRules.map((rule, index) => (
                    <p
                      key={index}
                      style={{
                        marginTop: "0.75rem",
                        fontSize: "14px",
                      }}
                      className="rule-item"
                    >
                      <span className="secondary_text">{rule.id}. </span>
                      <span className="secondary_text">{rule.rule}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ChatRules;
