import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import TransectionHistory from "../../../../../../api/transections_history";
import { loadingContext } from "../../../../../Contexts/Loading";

const AllDataWrapper = ({
  handlePage,
  setTotalPages,
  setlastPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [arrayOfAll, setArrayOfAll] = useState([]);
  const { loadingData, setLoadingData } = useContext(loadingContext);

  useEffect(() => {
    setLoadingData(true);
    setlastPage(true);

    TransectionHistory.transections_history(currentPage).then((response) => {
      setArrayOfAll(response.data.transaction_histories_data);
      handlePage(response.data.current_page);
      setTotalPages(response.data.total_pages);
      setlastPage(response.data.last_page);
      setLoadingData(false);
    });
  }, [currentPage]);

  return loadingData ? (
    <div
      style={{
        top: "40%",
        left: "50%",
      }}
      className="lds-ellipsis relative"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : arrayOfAll.length ? (
    arrayOfAll.map((data, index) => {
      return (
        <div
          key={index}
          style={{
            fontSize: "10px",
            padding: "10px",
          }}
          className="swap-send_row my_row my_row_item"
        >
          <div
            className="tarnsections-bill-swap-type-time my_row my_row_item_3 center_align font-14-on-big-screen"
            style={{
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <span className="secondary_text" style={{ marginBottom: "5px" }}>
              {data.transaction_type}
            </span>
            <div>
              <span className="text_color">
                {new Date(data.created_at).toLocaleDateString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
          <div
            style={{
              boxSizing: "border-box",
              paddingRight: "15",
              justifyContent: "center",
            }}
            className="tarnsections-bill-swap-amount my_row my_row_item_3 center_align font-14-on-big-screen"
          >
            <span
              style={{
                color: "#3bc117",
              }}
              className="bold "
            >
              {data.amount}
            </span>
            <img
              className="amount-img"
              style={{
                height: "1.4em",
                width: "1.4em",
                marginLeft: "3px",
              }}
              src={`/imgs/AcceptedNetworks/${data.network_id}.webp`}
              alt="CoinImg"
            />
          </div>
          <div className="tarnsections-bill-swap-balance my_row_item_3 my_row end center_align font-14-on-big-screen">
            <span className="bold">{data.balance}</span>
            <img
              className="hide-on-370px"
              style={{
                height: "1.4em",
                width: "1.4em",
                marginLeft: "3px",
              }}
              src={`/imgs/AcceptedNetworks/${data.network_id}.webp`}
              alt="CoinImg"
            />
          </div>
        </div>
      );
    })
  ) : (
    <div
      style={{
        flexDirection: "column",
        marginTop: "60px",
      }}
      className="showEmpty-containern justified width"
    >
      <div className="show-empty-img-container">
        <img className="empty-max-width" src="/imgs/empty.png" alt="empty" />
      </div>

      <div className="show-empty-typo">
        <span style={{ fontSize: "14px" }} className="text_color">
          {t("header.referral.oop")}
        </span>
      </div>
    </div>
  );
};

export default AllDataWrapper;
