import axiosClient from ".";

class ReferalManagmentAPI {
  static viewUserReferalData(
    data,
    page,
    filter,
    filterUsername,
    usernamePredicate,
    startDate,
    endDate,
    claimedPredicate,
    filterClaimed
  ) {
    return axiosClient.get(
      `api/v1/user/referral/stats/${data.id}?page=${page}/${
        filter &&
        `&q[user_username_${usernamePredicate}]=${filterUsername}&q[claimed_${claimedPredicate}]=${filterClaimed}&q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}`
      }`
    );
  }
}
export default ReferalManagmentAPI;
