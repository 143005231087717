import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { ethers } from "ethers";

import TxList from "./TxList";
import ErrorMessage from "./ErrorMessage";
import useWindowDimensions from "../../../../../../../Contexts/windowDimensions";
import CloseIcon from "@mui/icons-material/Close";
export default function BasicModal({
  Text,
  img,
  imgURL,
  bcAccount,
  network_id,
}) {
  const { height, width } = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    // transform: "translate(-35%, -50%)",
    transform: "translate(-50%, -50%)",
    // width: 400,
    width: width <= 767 ? width : 400,
    height: width <= 767 ? height : 400,

    boxShadow: 24,
    borderRadius: "20px",
    background: "#17181b",
    padding: "45px 35px",
    boxSizing: "border-box",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => Text === "MetaMask" && setOpen(true);

  const handleClose = () => setOpen(false);

  const startPayment = async ({ setError, setTxs, ether, bcAccount }) => {
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");

      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      ethers.utils.getAddress(bcAccount);
      let accounts = [];
      const tx = await signer.sendTransaction({
        from: accounts[0],
        to: bcAccount,
        value: ethers.utils.parseEther(ether),
      });
      setTxs([tx]);
    } catch (err) {
      setError(err.message);
    }
  };

  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    await startPayment({
      setError,
      setTxs,
      ether: data.get("ether"),
      bcAccount: data.get("bcAccount"),
    });
  };

  return (
    <>
      <button
        style={{
          color: "white",
        }}
        onClick={handleOpen}
        className={`headerAction-buttons ${Text == "wallet" && "justified"}`}
      >
        {Text}
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalsOn-mobile modals-height-on-mobiles" sx={style}>
          <div
            style={{
              // height: "50px",
              position: "sticky",
              top: "0px",
              background: "#17181b",
              marginBottom:"25px",
              // padding: width <= 767 ? "20px" : "0 20px",
            }}
            className="hotKeys-head-container my_row space-between"
          >
            <span
              style={{
                textTransform: "uppercase",
              }}
              className="orbitron-font white"
            >
              Deposit Option
            </span>

            <button className="secondary_text" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="deposit-modal-main">
            <div className="depositModal-content">
              <div
                style={{
                  gap: "15px",
                }}
                className="deposit-modal-meta my_row start"
              >
                <div className="deposit-option-img">
                  <img
                    style={{
                      maxWidth: "40px",
                    }}
                    src={`./imgs/${img}`}
                    alt="Deposit Option"
                  />
                </div>

                <div className="deposit-option-name">
                  <div>
                    <span className="text_color">Deposit With</span>
                    <div>
                      <span className="white bold">{Text}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "2rem",
                }}
                className="deposit-formContainer"
              >
                <div className="depositForm-top my_row start text_color">
                  <div className="depositCurrency-img-container">
                    <img
                      style={{ maxWidth: "25px" }}
                      src={imgURL}
                      alt="Deposit Currency"
                    />
                  </div>
                  <span
                    style={{
                      marginLeft: "5px",
                      verticalAlign: "middle",
                    }}
                    className="justified"
                  >
                    {network_id} Deposit Amount
                  </span>
                </div>

                <div className="depositFieldsContainer">
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        margin: "10px 0px 30px 0px",
                      }}
                      className="justified"
                    >
                      <input
                        className="width"
                        style={{
                          background: "#24262b",
                          padding: "20px",
                          boxSizing: "border-box",
                          fontFamily: "Montserrat, sans-serif",
                          borderRadius: "3px",
                          marginTop: "4px",
                        }}
                        name="ether"
                        type="text"
                        placeholder="Amount in ETH"
                      />
                      <input name="bcAccount" type="hidden" value={bcAccount} />
                    </div>
                    <div className="submit-buttonContainer justified">
                      <button
                        style={{
                          // width: "60%",
                          // height: "45px",
                        }}
                        className="primary-btn primary-btn-bg"
                        type="submit"
                      >
                        Pay now
                      </button>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      <span>{error}</span>
                    </div>
                    {/* <TxList txs={txs} /> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
