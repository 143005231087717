import React, { useState } from "react";
import "./Switch.css";
const Switch = ({ switchText, onClickHandle, activeStatus }) => {
  const handleSwitch = () => {
    onClickHandle();
  };
  return (
    <div className="switch-structure justified pointer" onClick={handleSwitch}>
      <div
        className={`switch-capsule relative ${
          activeStatus ? "open-switch" : "closed-switch"
        }`}
      >
        <div className="switch-dot absolute"></div>
      </div>
      <span className="stitch-text secondary_text">{switchText}</span>
    </div>
  );
};

export default Switch;
