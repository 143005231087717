import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bets: [],
  is_loading: false,
  error: null,
  locked_auto_cashout: 0,
  locked_bet_amount: 0,
  locked_network_id: "",
  bet_id: null,
  betActive: false,
};
const betSlice = createSlice({
  name: "bets",
  initialState,
  reducers: {
    setBetId: (state, action) => {
      state.bet_id = action.payload.bet_id;
    },
    setBetActive: (state, action) => {
      state.betActive = action.payload.betActive;
    },
    setLockedCashout: (state, action) => {
      state.locked_auto_cashout = action.payload.locked_auto_cashout;
    },
    setLockedBetAmount: (state, action) => {
      state.locked_bet_amount = action.payload.locked_bet_amount;
    },
    setLockedNetworkId: (state, action) => {
      state.locked_network_id = action.payload.locked_network_id;
    },
    setAllBetsData: (state, action) => {
      state.bets = action.payload.bets;
      state.is_loading = true;
      state.error = null;
    },
    setSingleBet: (state, action) => {
      state.bets = [...state.bets, ...action.payload.data];
    },
    updateSingleBet: (state, action) => {
      const data = action.payload.data;
      let index = state.bets.findIndex((bet) => bet.user_id === data.user_id);
      state.bets[index] = data;
    },
    updateBetsForDisplay: (state, action) => {
      state.bets.map((bet) => {
        if (bet.cash_out == 0) {
          bet.cash_out = "bang";
          bet.profit = bet.amount;
        }
        return bet;
      });
    },
    clearBetData: (state, action) => {
      state.bets = [];
      state.is_loading = false;
      state.error = null;
    },
  },
});
export const {
  fetchBetData,
  setAllBetsData,
  setSingleBet,
  clearBetData,
  updateSingleBet,
  updateBetsForDisplay,
  setLockedCashout,
  setLockedNetworkId,
  setLockedBetAmount,
  setBetId,
  setBetActive,
} = betSlice.actions;
export default betSlice.reducer;