import { useContext, memo } from "react";
import "./BetPrice.css";
import ManualBet from "./ManualBet/ManualBet";
import AutoCashout from "./AutoCashOut/AutoCashOut";
import { betPriceContext } from "../../../../../Contexts/BetPrice";

const BetPrice = () => {
  const { autoCashout, setAutoCashout } = useContext(betPriceContext);

  return (
    <div className="betPrice-main-class">
      <div className="betPrice-inner-class">
        <div
          style={{
            justifyContent: "center",
            alignItems: "start",
            gap: "30px",
          }}
          className="betPrice-content-class my_row"
        >
          <div className="betPrice-ManulBet-container">
            <ManualBet />
          </div>
          <div className="betPrice-AutoCashOut-container">
            <AutoCashout
              autoCashout={autoCashout}
              setAutoCashout={setAutoCashout}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BetPrice);
