import { useState, createContext } from "react";

const multiplierDistributor = createContext();

const DistributorProvider = ({ children }) => {
  const [multiplier, setMultiplier] = useState(1.01);
  const [interval, setIntervalTime] = useState(100);
  const [countDown, setCountDown] = useState(5.9);

  return (
    <multiplierDistributor.Provider
      value={{
        multiplier,
        setMultiplier,
        setCountDown,
        countDown,
        interval,
        setIntervalTime,
      }}
    >
      {children}
    </multiplierDistributor.Provider>
  );
};

export { multiplierDistributor, DistributorProvider };
