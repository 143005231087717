import { useState, useEffect, createContext } from "react";

const trendsContext = createContext();

const TrendsProvider = ({ children }) => {
  const [first, setfirst] = useState([]);
  const [Second, setSecond] = useState([]);
  const [Third, setThird] = useState([]);
  const [Fourth, setFourth] = useState([]);
  const [Fifth, setFifth] = useState([]);
  const [Last, setLast] = useState([]);

  return (
    <trendsContext.Provider
      value={{
        first,
        setfirst,
        Second,
        setSecond,
        Third,
        setThird,
        Fourth,
        setFourth,
        Fifth,
        setFifth,
        Last,
        setLast,
      }}
    >
      {children}
    </trendsContext.Provider>
  );
};

export { trendsContext, TrendsProvider };
