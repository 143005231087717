import { useState, useEffect } from "react";
import SignUpAPI from "../../../../api/signup";
import { ethers } from "ethers";

import {
  signIn,
  signInByGoogle,
  signInByMetaMask,
} from "../../../../store/user/actions";

import { useDispatch } from "react-redux";
import useMetaMask from "../../../../hooks/metamask";

import { GoogleLogin } from "@react-oauth/google";
import GoogleSignInAPI from "../../../../api/google_signin";
import MetaMaskSignInAPI from "../../../../api/metamask_signin";

import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
const SignUpModal = ({ setSignUpOpen, setErr, setErrMessage }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const signupInitial = {
    email: "",
    password: "",
    check: "checked",
    fname: "",
    lname: "",
    date: "1",
    month: "2",
    year: "2000",
    referal: params.get("referalLink"),
  };

  const directLogins = [
    {
      name: "google",
      img: "/imgs/google.webp",
    },

    {
      name: "metamask",
      img: "/imgs/metamask.webp",
    },
  ];

  const [termsCheck, setTerm] = useState(false);
  const [signupValues, setSignupValues] = useState(signupInitial);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const { connect, disconnect, isActive, account, shouldDisable } =
    useMetaMask();

  const signUpOnChange = (e) => {
    const { name, value } = e.target;
    setSignupValues({ ...signupValues, [name]: value });
  };

  const buttonToggle = (e) => {
    if (e.target.checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    setFormErrors(validate(signupValues));

    if (
      Object.keys(formErrors).length == 0 &&
      signupValues.email &&
      signupValues.password
    ) {
      SignUpAPI.userRegistration({
        user_registration: {
          email: signupValues.email,
          password: signupValues.password,
          password_confirmation: signupValues.password,
          used_referal_code: signupValues.referal,
        },
      })
        .then((response) => {
          signIn(signupValues.email, signupValues.password);
          setSignUpOpen(false);
        })
        .catch((error) => {
          setLoading(false);
          setErr(true);
          setTimeout(() => {
            setLoading(false);
            setErr(false);
          }, 2000);
          setErrMessage(error.response.data.error);
          console.log(error.response);
        });
    }
  };

  const validate = (value) => {
    const errors = {};
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!value.email) {
      errors.email = "Email is required";
      setLoading(false);
    } else if (!regex.test(value.email)) {
      errors.email = "This is not a valid email format";
      setLoading(false);
    }
    if (!value.password) {
      errors.password = "Password is required";
      setLoading(false);
    }
    return errors;
  };

  const handleClick = async (name) => {
    if (name == "metamask") {
      try {
        if (!window.ethereum) {
          alert("No crypto wallet found. Please install it.");
        } else {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = await provider.getSigner();
          const user_address = await signer.getAddress();
          // await connect()

          MetaMaskSignInAPI.metamask_signin({
            metamask: {
              eth_address: user_address,
            },
          }).then((response) => {
            signInByMetaMask(response);
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const responseGoogleSuccess = (response) => {
    GoogleSignInAPI.google_signin({
      google_signin: {
        token: response.credential,
      },
    })
      .then((response) => {
        signInByGoogle(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const responseGoogleError = (response) => {
    console.log(response);
  };
  useEffect(() => {
    console.log(formErrors);
  }, [formErrors]);
  const { t } = useTranslation();
  return (
    <div className="modal-form-container my_row justify">
      <form onSubmit={handleSubmit}>
        <div className="modal-form-inputs-container">
          <div className="modal-input login-email input-color">
            <div className="input-label-container input-color">
              <label htmlFor="dob-date">{t("header.signup.email")}</label>
            </div>
            <input
              name="email"
              className={`emailInp-text-input ModalInps input-color`}
              label={t("header.signin.emailaddress")}
              id="emailInp"
              type="text"
              value={signupValues.email}
              placeholder={t("header.signin.enteremail")}
              onChange={signUpOnChange}
            />
            <p className="signin-email-err inputErr">{formErrors.email}</p>
          </div>

          <div className="modal-input login-password input-color">
            <div className="input-label-container input-color">
              <label htmlFor="dob-date">{t("header.signup.password")}</label>
            </div>
            <input
              name="password"
              className={`passwordInp-password-input ModalInps input-color`}
              label="Login Password"
              id="passwordInp"
              type="password"
              value={signupValues.password}
              placeholder={t("header.signup.enterpassword")}
              onChange={signUpOnChange}
            />
            <p className="signin-password-err inputErr">
              {formErrors.password}
            </p>
          </div>
        </div>
        <div className="modal-input login-password input-color">
          <div className="input-label-container input-color">
            <label htmlFor="dob-date">{t("header.signup.referral")}</label>
          </div>
          <input
            name="referal"
            className={`passwordInp-password-input ModalInps input-color`}
            label="referal code"
            id="referrralInp"
            type="text"
            value={signupValues.referal}
            placeholder={t("header.signup.enterrefcode")}
            onChange={signUpOnChange}
          />
        </div>
        <div
          className="signup-terms-container input-color"
          style={{ marginBottom: "20px" }}
        >
          <input
            className="terms-check-class"
            id="termsCheck"
            type="checkbox"
            onClick={buttonToggle}
          />
          <label htmlFor="termsCheck" style={{ fontSize: "13px" }}>
            {t("header.signup.agreement")}
          </label>
        </div>

        <div className="form-actions-container my_row justify">
          <div className="form-action-button-container">
            {loading ? (
              <button
                className={`primary-btn primary-btn-disable`}
                disabled={true}
              >
                Loading
              </button>
            ) : (
              <button
                className={`primary-btn primary-btn-bg ${termsCheck ? "" : ""}`}
                disabled={!termsCheck ? true : false}
              >
                {t("header.signup.signup")}
              </button>
            )}
          </div>
        </div>
      </form>

      <div className="direct-logins">
        <div className="direct-login-content">
          <div
            style={{
              margin: "10px 0",
            }}
            className="div or-login text_color centered_text"
          >
            <span
              style={{
                fontSize: "12px",
              }}
            >
              {t("header.signup.directSignup")}
            </span>
          </div>

          <div
            style={{
              gap: "7px",
            }}
            className="direct-directLoginItemlogins-option-container justified"
          >
            {directLogins.map((directLoginItem, index) => {
              return directLoginItem.name == "google" ? (
                <GoogleLogin
                  key={index}
                  type="icon"
                  width="20px"
                  shape="circle"
                  onSuccess={responseGoogleSuccess}
                  onError={responseGoogleError}
                />
              ) : (
                <div key={index}>
                  <button
                    key={index}
                    className="direct-login-option"
                    onClick={() => {
                      handleClick(directLoginItem.name);
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                      }}
                      src={directLoginItem.img}
                      alt="google"
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // </Wrapper>
  );
};

export default SignUpModal;
