import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const communityArray = [
    {
      communityImg: "/imgs/community/facebook.webp",
      communitylink: "#",
    },
    {
      communityImg: "/imgs/AcceptedNetworks/btcClr.webp",
      communitylink: "#",
    },
    {
      communityImg: "/imgs/community/github.webp",
      communitylink: "#",
    },
    {
      communityImg: "/imgs/community/discord.webp",
      communitylink: "#",
    },
    {
      communityImg: "/imgs/community/telegram.webp",
      communitylink: "#",
    },
    {
      communityImg: "/imgs/community/twitter.webp",
      communitylink: "#",
    },
  ];
  const AcceptedNetworks = [
    {
      mainImg: "/imgs/AcceptedNetworks/bitcoin.webp",
      hoverImg: "/imgs/AcceptedNetworks/bitcoin.webp",
    },
    {
      mainImg: "/imgs/AcceptedNetworks/ethereum.webp",
      hoverImg: "/imgs/AcceptedNetworks/ethereum.webp",
    },

    {
      mainImg: "/imgs/AcceptedNetworks/tron.webp",
      hoverImg: "/imgs/AcceptedNetworks/tron.webp",
    },
    {
      mainImg: "/imgs/AcceptedNetworks/polygon.webp",
      hoverImg: "/imgs/AcceptedNetworks/polygon.webp",
    },
    {
      mainImg: "/imgs/AcceptedNetworks/solana.webp",
      hoverImg: "/imgs/AcceptedNetworks/solana.webp",
    },
  ];
  return (
    <div className="footer-inner-container">
      <div className="footer-content-container my_row space-between">
        <div className="accepted-networks-main-container">
          <div
            style={{
              marginBottom: "35px",
            }}
            className="accepted_networks bold centered_text"
          >
            <span
              style={{
                fontSize: "16px",
                fontFamily: "Orbitron, sans-serif",
                letterSpacing: "1px",
                textAlign: "left",
                display: "block",
              }}
            >
              {t("footer.acceptednetwork")}
            </span>
          </div>
          <div
            style={{
              gap: "25px",
            }}
            className="accepted-networks-inner-contanier justified"
          >
            {AcceptedNetworks.map((network, index) => {
              return (
                <div
                  key={index}
                  className="accepted-networks-content-contaienr relative"
                >
                  <div className="accepted-network-main-img">
                    <img
                      style={{
                        maxWidth: "40px",
                        background: "#24262b",
                      }}
                      src={network.mainImg}
                      alt="Network"
                    />
                  </div>
                  <div
                    style={{
                      top: "0",
                      zIndex: "-1",
                    }}
                    className="accepted-network-hover-img absolute"
                  >
                    <img
                      style={{
                        maxWidth: "40px",
                      }}
                      src={network.hoverImg}
                      alt="Network"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="platform-info-section width-65">
          <div className="footer-logo-container">
            <img
              style={{
                width: "auto",
                maxWidth: "210px",
              }}
              src="/imgs/logo/logo.png"
              alt="BCW.CLUB"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              fontWeight: "500",
            }}
            className="footer-typo-container text-justify seconday_text"
          >
            <span
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
                color: "#98a7b5",
              }}
            >
              {t("footer.para")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
