import { useEffect, useState, useContext, createRef } from "react";
import "./Classic.css";
import { useSelector, useDispatch } from "react-redux";
import Graph from "./Graph/Graph";
import Betit from "./Betit/Betit";

import { constants } from "../../../../store/constants";

import BankRoll from "./TrendBar/BankRoll/BankRoll";
import Players from "./TrendBar/Players/Players";
import Trends from "./TrendBar/Trends/Trends";
import { betPriceContext } from "../../../../components/Contexts/BetPrice";
import { modalsControl } from "../../../../components/Contexts/ModalsControl";
import { multiplierDistributor } from "../../../../components/Contexts/multiplierDistributor";
import { multiplierContext } from "../../../../components/Contexts/Multiplier";
import game, {
  setCountDownStart,
  setCountDownStop,
  setMultiplierStart,
  setMultiplierStop,
  setServerGameStateLoading,
  setFinalMultiplier,
  setGameState,
} from "../../../../store/game";

import { clearBetData, updateBetsForDisplay } from "../../../../store/bet";
import {
 
  setBetAmount,
  setCashedOut,
} from "../../../../store/user";
import GetTrendsAPI from "../../../../api/trends_api";
// import { modalsControl } from "../../../Contexts/ModalsControl";
import { Box, Modal, Typography } from "@mui/material";
import UTC from "./TrendBar/Trends/UTC";
import { trendsContext } from "../../../Contexts/TrendsDistributor";
import { Link, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TrendsButton from "./TrendBar/Additionals/TrendsButton";
import PlayersOpenerButton from "./TrendBar/Players/PlayersOpenerButton";
import MobTrends from "./TrendBar/Trends/MobTrends";
import useWindowDimensions from "../../../Contexts/windowDimensions";

const Classic = () => {
  const dispatch = useDispatch();
  const game_state = useSelector((state) => state.game.game_state);
  const current_user = useSelector((state) => state.user.currentUser);
  const userBetPlaced = useSelector((state) => state.user.betPlaced);
  const [betStatus, setBetStatus] = useState(false);
  const [showProfit, setShowProfit] = useState(false);
  const { height, width } = useWindowDimensions();

  const { myChannel } = useContext(multiplierContext);

  const { globaleGameState, setGlobalGameState } = useContext(betPriceContext);

  const { setMultiplier } = useContext(multiplierDistributor);

  const { mblTrends, trendModal, playersModal } = useContext(modalsControl);
  const [roundCount, setRoundCount] = useState(0);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  let localCountdown = false;
  let localCountdownStop = false;
  let localCheckout = false;
  let localCheckoutStop = false;
  const runOnCountDown = () => {
    if (!localCountdown) {
      localCountdown = true;
      dispatch(
        setGameState({
          game_state: constants.COUNTDOWN_STARTED,
        })
      );
      if (current_user && userBetPlaced) {
        dispatch(
          setCashedOut({
            cashedOut: false,
          })
        );
      }
      globaleGameState && setGlobalGameState(false);
    }
  };
  const runOnCountDownStop = () => {
    localCountdownStop = true;
    dispatch(
      setGameState({
        game_state: constants.COUNTDOWN_STOPPED,
      })
    );
  };
  const runOnCheckout = () => {
    if (!localCheckout) {
      localCheckout = true;
      dispatch(
        setGameState({
          game_state: constants.MULTIPLIER_STARTED,
        })
      );
      globaleGameState && setGlobalGameState(false);
    }
  };
  const runOnCheckoutStop = (data) => {
    if (!localCheckoutStop) {
      localCheckoutStop = true;
      localCountdown = false;
      localCheckout = false;

      setMultiplier(data.value);

      dispatch(
        setGameState({
          game_state: constants.MULTIPLIER_STOPPED,
        })
      );

       
      dispatch(updateBetsForDisplay());
    }
  };
  let countdownLocalRun = false;
  let countdownStopLocalRun = false;
  let checkoutLocalRun = false;
  let checkoutStopLocalRun = false;
  const run = async () => {
    for await (let data of myChannel) {
      if (data.status === "countdown") {
        if (!countdownLocalRun) {
          countdownLocalRun = true;
          runOnCountDown();
        }
      }
      if (data.status === "countdown_stop") {
        if (!countdownStopLocalRun) {
          countdownStopLocalRun = true;
          runOnCountDownStop();
        }
      }
      if (data.status === "checkout") {
        if (!checkoutLocalRun) {
          checkoutLocalRun = true;
          runOnCheckout();
        }
      }
      if (data.status === "checkout_stop") {
        if (!checkoutStopLocalRun) {
          checkoutStopLocalRun = false;
          checkoutLocalRun = false;
          countdownStopLocalRun = false;
          countdownLocalRun = false;

          runOnCheckoutStop(data);
        }
      }
    }
  };

  useEffect(() => {
    run();
  }, [game_state, myChannel]);

  const ToScroll = createRef();
  const [toTriger, setToTriger] = useState(false);

  const scrollIt = () => {
    ToScroll.current.scrollLeft += 800;
  };
  const { t } = useTranslation();

  useEffect(() => {
    toTriger ? setToTriger(false) : setToTriger(true);
  }, [trendModal]);

  useEffect(() => {
    if (ToScroll.current) {
      scrollIt();
    }
  }, [mblTrends, toTriger]);
  return (
    <>
      <div
        style={{
          background: "#17181b",
          borderRadius: "5px 5px 0 0",
          padding: "14px",
          gap: "3px",
        }}
        className="graph-bar-container justified"
      >
        <BankRoll />
        <PlayersOpenerButton />
        <TrendsButton />
        <Routes>
          {/* <Route path="/crash/trends" element={<Trends />} /> */}
        </Routes>
        {playersModal && <Players />}
        {trendModal ? <Trends /> : null}
      </div>
      {mblTrends && <MobTrends />}

      <Graph
        setBetStatus={setBetStatus}
        showProfit={showProfit}
        setShowProfit={setShowProfit}
      />

      <Betit
        setBetStatus={setBetStatus}
        showProfit={showProfit}
        setShowProfit={setShowProfit}
      />
    </>
  );
};

export default Classic;
