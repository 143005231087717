import { useState, useContext } from "react";
import "./WalletInnerTabs.css";
import InnerTabs from "./InnerTabs";
import TransectionModals from "../TransectionModals";
import TwoFactor from "./TwoFactorModal";
import useWindowDimensions from "../../../../Contexts/windowDimensions";
import CloseIcon from "@mui/icons-material/Close";
import { modalsControl } from "../../../../Contexts/ModalsControl";
import { useTranslation } from "react-i18next";
const WalletInnerTabs = () => {
  const { walletOpen, setWalletOpen } = useContext(modalsControl);
  const { height, width } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          background: "#1e2024",
          borderRadius: "20px",
          color: "white",
          width: width <= 767 ? width : 500,
          height: width <= 767 ? height : "500px",
          overflowX: "hidden",
        }}
        className="wallet-inner-tabs-main relative"
      >
        <div className="wallet-inner-tabs-inner">
          <div className="wallet-inner-tabs-content">
            <div
              style={{
                padding: "15px",
              }}
              className="wallet-inner-top my_row space-between center_align"
            >
              <div className="wallet-top-wallet orbitron-font">
                {t("header.wallet.wallet")}
              </div>
              <div className="wallet-top-transcriptions justified">
                <TransectionModals Text={t("header.wallet.transaction")} />
                <button
                  style={{
                    margin: "1px 0px 0px 10px",
                  }}
                  className="justified"
                  onClick={() => {
                    setWalletOpen(false);
                  }}
                >
                  <CloseIcon className="text_color" />
                </button>
              </div>
            </div>
            <div className="wallet-inner-middle">
              <InnerTabs />
            </div>
            <div className="wallet-inner-bottom">
              <div
                style={{
                  border: "1px solid #363636",
                  padding: "15px",
                  textAlign: "center",
                }}
                className="wallet-security"
              >
                <span className="text_color">
                  {t("header.wallet.secureAsset")}
                </span>
                <span style={{ display: "inline-block" }}>
                  <TwoFactor />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletInnerTabs;
