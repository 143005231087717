import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { modalsControl } from "../../Contexts/ModalsControl";

const DepositBanner = () => {
  const { setReferalBonusSlide } = useContext(modalsControl);
  const slideDepositBonus = () => {
    setReferalBonusSlide(true);
  };
  const { t } = useTranslation();
  return (
    <div class="wallet-popup-steps">
      <div class="bonus-tit">
        <button
          keep-scroll-position="true"
          class="text_color more"
          onClick={slideDepositBonus}
        >
          {t("header.referral.mores")}
        </button>
      </div>
      <div class="bonus-bannerImg margin-bottom-10">
        <img
          style={{ cursor: "pointer" }}
          src="/imgs/Banner-300.webp"
          alt="depositBanner"
          onClick={slideDepositBonus}
        />
      </div>
    </div>
  );
};

export default DepositBanner;
