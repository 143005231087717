import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";
import "./QrGenerator.css";
const QrGenerator = ({ stringToQr, QrTypoHeading, QrOnly }) => {
  const [copy, setCopy] = useState(false);
  const handleCopy = (account) => {
    navigator.clipboard.writeText(account);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  const { t } = useTranslation();
  if (QrOnly) {
    return (
      <div style={{}} className="qr-code-container justified">
        <QRCodeCanvas
          style={{
            verticalAlign: "middle",
          }}
          id="qrCode"
          value={stringToQr}
          size={135}
          bgColor={"#fff"}
          level={"H"}
        />
      </div>
    );
  } else {
    return (
      <div className="qr-main-container">
        <div className="qr-container-inner my_row space-between">
          <div className="qr-typo-container width-50">
            <div className="qr-qr-heading">
              <span className="text_color">{QrTypoHeading}</span>
            </div>
            <div className="string-to-convert-container">
              <span>{stringToQr}</span>
            </div>
            <div className="qr-copy-button-conainer">
              <button
                className="orbitron-font primary-btn primary-btn-bg"
                onClick={() => {
                  handleCopy(stringToQr);
                }}
              >
                {copy
                  ? t("header.wallet.depositTab.copied")
                  : t("header.wallet.depositTab.copy")}
              </button>
            </div>
          </div>
          <div className="qr-code-container justified">
            <QRCodeCanvas
              style={{
                verticalAlign: "middle",
              }}
              id="qrCode"
              value={stringToQr}
              size={135}
              bgColor={"#fff"}
              level={"H"}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default QrGenerator;
