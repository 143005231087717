import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { constants } from "../../../../../../store/constants";
import PoolAPI from "../../../../../../api/pool";
import { betPriceContext } from "../../../../../Contexts/BetPrice";
import { useTranslation } from "react-i18next";
const BankRoll = () => {
  const game_state = useSelector((state) => state.game.game_state);
  const { activatedCurrency } = useContext(betPriceContext);
  const [pool_amounts, setPoolAmounts] = useState([]);
  const [selectedPool, setSelectedPool] = useState([]);
  const [fetcheOnce, setFetchOnce] = useState(true);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED || fetcheOnce) {
      setFetchOnce(false);
      setTimeout(() => {
        PoolAPI.getPoolAmounts("crash").then((response) => {
          setPoolAmounts(response.data.pool_values);
        });
      }, 1000);
    }
  }, [game_state]);

  useEffect(() => {
    setSelectedPool(
      pool_amounts?.find(
        (element) => element.network_id === activatedCurrency?.network_id
      )
    );
  }, [activatedCurrency?.network_id, pool_amounts]);

  const { t } = useTranslation();
  return (
    <div className="BankRollMainClass">
      <div
        className="bankroll-container"
        style={{
          width: "130px",
          height: "44px",
          whiteSpace: "normal",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#31373d",
          borderRadius: "3px",
          ":hover": {
            backgroundColor: "#31373d",
          },
        }}
      >
        <p
          className="orbitron-font bold"
          style={{
            fontSize: "12px",
            marginBottom: "1px",
          }}
        >
          <span
            style={{
              color: "#43b309",
            }}
          >
            {t("header.referral.bankroll")}
          </span>
          &nbsp;
          <span
            style={{
              color: "#818a95",
              textTransform: "uppercase",
            }}
          >
            {activatedCurrency?.symbol}
          </span>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            fontSize: "14px",
          }}
        >
          {selectedPool && (
            <span
              className="bold"
              style={{
                fontSize: "12px",
              }}
            >
              {selectedPool.amount}
            </span>
          )}
          &nbsp;
          <span>
            <img
              style={{
                maxWidth: "17px",
                maxHeight: "17px",
                verticalAlign: "top",
                overFlow: "hidden",
              }}
              src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
              alt="BetCoin"
            />
          </span>
        </p>
      </div>
    </div>
  );
};

export default BankRoll;
