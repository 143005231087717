import axiosClient from ".";
class WheelData {
  static GetWheelData() {
    return axiosClient.get("/get_wheel_data");
  }
  static GetWheelStatus() {
    return axiosClient.get("/wheel_status");
  }
  static GetWheelPrize(data) {
    return axiosClient.post("/get_prize", data);
  }
}
export default WheelData;
