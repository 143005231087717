import "./Crash.css";
import Classic from "./Classic/Classic";
import Data from "./AllBets/Data";
import BetStats from "../../Common/Stats/Stats";
import { betPriceContext } from "../../Contexts/BetPrice";
import { useContext } from "react";

const Crash = () => {
  const { insuficientWallet } = useContext(betPriceContext);
  return (
    <div
      style={{
        transition: ".5s",
      }}
      className="crash-game-main-container"
    >
      <div className="crash-game-inner-container">
        <div
          style={{
            justifyContent: "space-between",
            overflow: "hidden",
          }}
          className="crash-game-content-container my_row relative"
        >
          <div className="Crash-graph-container">
            <Classic />
          </div>

          <div
            style={{
              transition: ".5s",
            }}
            className={`crash-data-container relative`}
          >
            <Data />
          </div>
        </div>
        <div className="crashGame-history-container">
          <BetStats />
        </div>
      </div>
    </div>
  );
};

export default Crash;
