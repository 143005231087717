import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { modalsControl } from "../../Contexts/ModalsControl";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import SignInAPI from "../../../api/signin";
import Alert from "../Alert";
import { betPriceContext } from "../../Contexts/BetPrice";
import { useContext } from "react";
export default function ResetPassword() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    background: "linear-gradient(to right, #6f33ee 50%, #6124d4 50%)",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const { resetPassModal, setResetPassModal, setSignInOpen } =
    React.useContext(modalsControl);
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const password_reset_token = searchParams.get("reset_password_token");
  const [alert, setAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const navigate = useNavigate();
  const handleResetPass = () => {
    if (!newPass || !confirmPass) {
      setAlert(true);
      setAlertType("error");
      setAlertMessage("Fields cannot be epmty.");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else if (newPass !== confirmPass) {
      setAlert(true);
      setAlertType("error");
      setAlertMessage("Password do no match.");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else if (newPass === confirmPass && confirmPass.length < 6) {
      setAlert(true);
      setAlertType("error");
      setAlertMessage("Length should be 6 or greater.");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    } else {
      SignInAPI.passwordReset({
        user: {
          password: newPass,
          password_confirmation: confirmPass,
          reset_password_token: password_reset_token,
        },
      })
        .then((res) => {
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
            setSignInOpen(true);
            setResetPassModal(false);
          }, 3000);
          setAlertMessage("Password Updated Successfully");
          setAlertType("success");
        })
        .catch((err) => {
          console.log(err);
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 3000);
          setAlertMessage(err.response.data.errors.reset_password_token[0]);
          setAlertType("error");
        });
    }
  };
  const handleClose = () => setResetPassModal(false);

  return (
    <>
      <Modal
        open={resetPassModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modalsOn-mobile signin-signup modals-height-on-mobiles"
          sx={style}
        >
          <div className="modal-inner-main">
            <div className="modal-inner-inner">
              <div className="modal-inner-content">
                <div className="modal-inner-img-container relative">
                  <img
                    onClick={handleClose}
                    src="/imgs/logo/logo.png"
                    alt="BCW.CLUB"
                  />

                  <span
                    className="absolute"
                    style={{
                      right: "20px",
                      bottom: "10px",
                    }}
                  >
                    <button className="white" onClick={handleClose}>
                      <CloseIcon />
                    </button>
                  </span>
                </div>

                <div
                  style={{
                    overflow: "hidden",
                  }}
                  className="modal-form-container my_row justify relative"
                >
                  <div className="modal-form-inputs-container">
                    <div className="modal-input new-password">
                      <div className="input-label-container input-color">
                        <label htmlFor="emailInp">New Password</label>
                      </div>
                      <input
                        type="password"
                        name="password"
                        id="emailInp"
                        value={newPass}
                        onChange={(e) => {
                          setNewPass(e.target.value);
                        }}
                        placeholder="New Password"
                        className="emailInp-text-input ModalInps input-color"
                      />
                    </div>
                    <div
                      style={{ overflow: "hidden" }}
                      className="modal-input confirm-new-password relative"
                    >
                      <div
                        style={{
                          top: alert ? "0px" : "-52px",
                          transition: ".3s",
                        }}
                        className="alert-container width absolute"
                      >
                        <Alert
                          type={alertType}
                          message={
                            alertMessage === "is invalid"
                              ? "Link expired please try again."
                              : alertMessage
                          }
                        />
                      </div>
                      <div className="input-label-container input-color">
                        <label htmlFor="emailInp">Confirm Password</label>
                      </div>
                      <input
                        name="password"
                        className="passwordInp-password-input ModalInps input-color"
                        id="passwordInp"
                        type="password"
                        value={confirmPass}
                        onChange={(e) => {
                          setConfirmPass(e.target.value);
                        }}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                  <div className="form-actions-container my_row justify">
                    <div className="form-action-button-container">
                      <button
                        className="primary-btn primary-btn-bg"
                        onClick={handleResetPass}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
