import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import ChatRules from "../ChatRulesModal";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { loadingContext } from "../../Contexts/Loading";
import ChatPreloader from "./ChatPreloader";

const Chat = ({ openChatBox }) => {
  const { t } = useTranslation();
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const user_profile = useSelector((state) => state.user.user_profile);
  const [socket, setSocket] = useState("");

  const [allMessages, setAllMessages] = useState([]);
  const { loadingData, setLoadingData } = useContext(loadingContext);
  const [toTriger, setToTriger] = useState(false);
  const [emojiContainer, setEmojiContainer] = useState(false);
  const [messageFocus, setMessageFocus] = useState(true);
  const Focus = () => {
    setMessageFocus(true);
  };

  const ToScroll = useRef();
  useEffect(() => {
    setSocket(io(process.env.REACT_APP_MESSAGE));
  }, []);
  useEffect(() => {
    setLoadingData(true);
    axios.get(process.env.REACT_APP_ALL_MESSAGES).then((res) => {
      setLoadingData(false);
      setAllMessages(res.data.msgs.reverse());
      setToTriger(true);
    });
  }, []);

  const [messageToSend, setMessageToSend] = useState("");
  useEffect(() => {
    if (user_id) {
      setMessageFocus(true);
    }
    if (messageToSend) {
      setMessageFocus(true);
    } else {
      setMessageFocus(false);
    }
  }, [user_id, messageToSend]);

  const scrollFunction = () => {
    ToScroll.current.scrollTop = ToScroll.current.scrollHeight;
  };
  const sendMessage = () => {
    if (!user_id) {
      return;
    }
    if (messageToSend) {
      socket.emit("chat msg", user_profile.user, messageToSend);
      setAllMessages((allMessages) => [
        ...allMessages,
        {
          createdAt: Date.now(),
          user: {
            profileImage: user_profile.user_img.url
              ? user_profile.user_img.url
              : user_profile.user_img,
            username: user_profile?.username,
          },
          userId: user_id,
          message: messageToSend,
        },
      ]);
      setMessageToSend("");
      scrollFunction();
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("chat msg", (data) => {
        setAllMessages((allMessages) => [...allMessages, data]);
      });
    }
  }, [socket]);

  const [scrollCheck, setScrollCheck] = useState(true);
  useEffect(() => {
    if (scrollCheck) {
      scrollFunction();
    }
  }, [toTriger, allMessages]);

  const onScroll = () => {
    if (
      ToScroll.current.scrollHeight - ToScroll.current.scrollTop ===
      ToScroll.current.clientHeight
    ) {
      setScrollCheck(true);
    } else {
      setScrollCheck(false);
    }
  };

  const handleEmojiContainer = () => {
    setEmojiContainer(!emojiContainer);
  };

  const inputEmoji = (emoji) => {
    setMessageToSend((prevMessage) => prevMessage + emoji.emoji);
  };

  return (
    <>
      <div
        style={{
          padding: "8px 10px",
          boxSizing: "border-box",
          background: "#31373d",
          borderBottom: "solid 1px #17181b",
        }}
        className="chat-module-top-container width my_row space-between"
      >
        <div className="global-chat bold white">
          <span className="orbitron-font margin-left-10">{t("header.chat.global")} </span>
        </div>

        <div
          className="chat-modules-actions my_row"
          style={{ position: "relative", bottom: "-2px" }}
        >
          <div className="chat-rules">
            <ChatRules />
          </div>
          &nbsp; &nbsp;
          <div className="chatClose">
            <button className="text_color" onClick={openChatBox}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          boxSizing: "border-box",
          padding: "0 0 0 6px",
          height: "calc(100vh - 172px)",
          overflowY: "auto",
        }}
        ref={ToScroll}
        className="global-chat-messages-container width relative"
        onScroll={onScroll}
      >
        <div
          className="messages-inner-container absolute"
          style={{
            width: "97%",
            padding: "0",
          }}
        >
          {loadingData ? (
            <ChatPreloader />
          ) : (
            allMessages?.map((message, index) => {
              return (
                <div
                  id={`section-${index}`}
                  key={message?._id}
                  style={{
                    margin: "20px 0",
                  }}
                  className={`global-chat-message my_row ${
                    user_id === message?.userId && "my_message"
                  }`}
                >
                  <div
                    className="message-user-img-container"
                    style={{ width: "40px", marginRight: "15px" }}
                  >
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={
                        message.user?.profileImage ||
                        "/imgs/userProfile/userProfile.png"
                      }
                      alt=""
                    />
                  </div>

                  <div
                    style={{ fontSize: "15px", width: "calc(100% + -60px)" }}
                    className="message-typo-container"
                  >
                    <div className="message-username-container text_color">
                      <span
                        className="orbitron-font"
                        style={{
                          color: "#ADC862",
                          fontSize: "11px",
                          marginRight: "20px",
                        }}
                      >
                        {message.user?.username || "anonymous" + " "}
                      </span>
                      <span style={{ fontSize: "10px" }}>
                        {new Date(message?.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </span>
                    </div>
                    <div
                      className="message-userMessage-container secondary_text"
                      style={{
                        background: "#2e323b",
                        borderRadius: "0px 20px 0px 20px",
                        padding: "12px",
                        marginTop: "10px",
                        fontSize: "13px",
                        wordBreak: "break-all",
                      }}
                    >
                      {message?.message}
                    </div>
                  </div>
                </div>
              );
            })
          )}

          {emojiContainer && (
            <div
              style={{
                position: "fixed",
                bottom: "0px",
              }}
            >
              <EmojiPicker
                theme="dark"
                onEmojiClick={(emoji) => inputEmoji(emoji)}
                autoFocusSearch={false}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          background: "#1e2024",
          padding: "10px",
          boxSizing: "border-box",
          position: "absolute",
          bottom: "68px",
          width: "100%",
          flexDirection: "row-reverse",
        }}
        className="message-sender-action my_row end"
      >
        <div
          style={{
            transition: ".5s",
            height: "40px",
          }}
          className={`message-sender-field-container my_row relative width-85 center_align end`}
        >
          <div className="emojis-handle-button width-15 height justified">
            <button
              className={`${emojiContainer ? "white" : "text_color"}`}
              onClick={handleEmojiContainer}
            >
              <EmojiEmotionsIcon />
            </button>
          </div>
          <input
            className="height width-85"
            style={{
              padding: "0 10px",
              background: "#17181b",
              fontWeight: "400",
            }}
            type="text"
            value={messageToSend}
            onChange={(e) => {
              setMessageToSend(e.target.value);
            }}
            placeholder={t("header.chat.message")}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
          />
        </div>
        <div
          style={{
            width: "42px",
            background: "rgb(23 24 27)",
          }}
          className="message-send-action-button justified signupButton_color"
        >
          <button
            style={{
              height: "100%",
            }}
            className="white width"
            onClick={sendMessage}
          >
            <SendIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default Chat;
