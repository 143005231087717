import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BetRoundsAPI from "../../../../../../api/bet_rounds";
import { constants } from "../../../../../../store/constants";
import { modalsControl } from "../../../../../Contexts/ModalsControl";

const PlayersOpenerButton = () => {
  const navigate = useNavigate();
  const [BarHistory, setBarHistory] = useState([]);
  const game_state = useSelector((state) => state.game.game_state);
  const [fetcheOnce, setFetchOnce] = useState(true);
  const { playersModal, setPlayersModal, setRoundPlayerId } =
    useContext(modalsControl);

  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED || fetcheOnce) {
      setTimeout(() => {

      BetRoundsAPI.getLastTenMultipliersAndIds().then((res) => {
        setFetchOnce(false);
        setBarHistory(res.data.last_five_multiplier_history);
      });
    }, 1000)

    }
  }, [game_state]);
  const handleOpen = (id) => {
    // navigate(`/:round-players=${id}`);
    setPlayersModal(true);
    setRoundPlayerId(id);
  };
  return (
    <div
      style={{
        background: "#31373d",
        borderRadius: "3px",
        margin: "0 10px",
        overflowX: "auto",
      }}
      className="round-users-data-main"
    >
      <div
        style={{
          padding: "5px 15px",
          display: "flex",
          gap: "14px",
          justifyContent: "flex-start",
        }}
        className="round-user-data-inner center_align"
      >
        {BarHistory?.map((item) => {
          return (
            <button
              key={item.ids}
              onClick={() => {
                handleOpen(item.ids);
              }}
            >
              <div className="round-user-data-container my_row">
                <div
                  style={{
                    gap: "7px",
                  }}
                  className="justified"
                >
                  <div
                    className="click-players-dot"
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor:
                        item.multipliers < 2
                          ? "#ed6300"
                          : item.multipliers >= 10
                          ? "#f6c722"
                          : "#3bc117",
                      borderRadius: "50%",
                    }}
                  />

                  <div className="">
                    <span
                      style={{ fontSize: "12px" }}
                      className="text_color players-click-round-id"
                    >
                      {item.ids}
                    </span>
                    <div>
                      <span
                        className="players-click-multiplier"
                        style={{
                          color:
                            item.multipliers < 2
                              ? "#ed6300"
                              : item.multipliers >= 10
                              ? "#f6c722"
                              : "#3bc117",
                          fontSize: "15px",
                        }}
                      >
                        {item.multipliers?.toFixed(2)}x
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PlayersOpenerButton;
