import axios from "axios";
import { store } from "../store";
import { setAuthData } from "../store/user";

const API_URL = process.env.REACT_APP_BACKEND_LINK;
 
let options = {
  baseURL: API_URL,
};

const axiosClient = axios.create(options);

// Add a response interceptor
axiosClient.interceptors.request.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (localStorage.getItem("token")) {
      response.headers.Authorization = localStorage.getItem("token");
    }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(
        setAuthData({
          currentUser: null,
          token: null,
        })
      );
    } else if (error.response.status === 422) {
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
