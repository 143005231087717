import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CurrencyLimitsAPI from "../../../../../../../api/currency_limits";
import useWindowDimensions from "../../../../../../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function BasicModal() {
  const { height, width } = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 767 ? width : 400,
    background: "#1e2024",
    overflow: "hidden",
    // height: width <= 767 ? height : "80%",
    outline: "none",
    padding: "25px",
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState("game");
  const [currencyLimits, setCurrencyLimits] = useState([]);
  const { t } = useTranslation();
  const helpItems = [
    {
      title: t("gamebox.modals.helpmodal.whatGame"),
      name: t("gamebox.modals.helpmodal.whatGame"),
    },
    {
      title: t("gamebox.modals.helpmodal.fairness"),
      name: t("gamebox.modals.helpmodal.fairness"),
    },
    {
      title: t("gamebox.modals.helpmodal.bankroll"),
      name: t("gamebox.modals.helpmodal.bankroll"),
    },
    {
      title: t("gamebox.modals.helpmodal.autoCashout"),
      name: t("gamebox.modals.helpmodal.autoCashout"),
    },
  ];

  const slideDetails = (name) => {
    showDetails ? setShowDetails(false) : setShowDetails(true);
    setDetailsToShow(name);
  };
  useEffect(() => {
    CurrencyLimitsAPI.getCurrencyLimits().then((res) => {
      setCurrencyLimits(res.data.currency_limits);
    });
  }, []);
  return (
    <div>
      <button className="text_color" onClick={handleOpen}>
        <HelpOutlineIcon />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals-height-on-mobiles">
          <>
            <div
              style={{
                // height: width <= 767 ? height : "80%",
                height: "80%",

                top: "0",
                left: showDetails ? "0" : "464px",
                height: "100%",
                background: "#1e2024",
                transition: ".3s",
                overflow: "auto",
                boxSizing: "border-box",
                paddingBottom: "30px",
              }}
              className="help-details-slider absolute width"
            >
              <div
                style={{
                  height: "50px",
                  position: "sticky",
                  top: "0px",
                  padding: width <= 767 ? "20px" : "0 20px",
                  background: "#1e2024",
                }}
                className="hotKeys-head-container my_row space-between"
              >
                <span className="orbitron-font">{detailsToShow}</span>

                <button
                  className="secondary_text"
                  onClick={() => {
                    setShowDetails(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div
                style={{
                  padding: "0 20px",
                }}
                className="game-details-main-container"
              >
                <div className="game-details-inner-containe text-justify">
                  {detailsToShow === t("gamebox.modals.helpmodal.whatGame") && (
                    <>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.whatGameSection.whatCrash"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.whatGameSection.whatCrashPara"
                          )}
                        </p>
                      </div>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.whatGameSection.howToPlay"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.whatGameSection.howToPlayPara"
                          )}
                        </p>
                      </div>
                    </>
                  )}

                  {detailsToShow === t("gamebox.modals.helpmodal.fairness") && (
                    <>
                      <div
                        style={{
                          margin: "20px 0",
                        }}
                        className="details-section-container"
                      >
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t("gamebox.modals.helpmodal.fairnessSection.isFair")}
                        </h5>
                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.isFairPara"
                          )}
                        </p>
                      </div>

                      <div
                        style={{
                          margin: "40px 0",
                        }}
                        className="details-section-container"
                      >
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulation"
                          )}
                          <span>
                            <a
                              style={{
                                color: "#2fa5cd",
                              }}
                              href="https://hash-verification.web.app/"
                              target="_blank"
                            >
                              {t(
                                "gamebox.modals.helpmodal.fairnessSection.hashVerify"
                              )}
                            </a>
                          </span>
                        </h5>
                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulationPara1"
                          )}
                        </p>
                        <div>
                          <span className="text_color">
                            {t(
                              "gamebox.modals.helpmodal.fairnessSection.example"
                            )}
                          </span>
                        </div>
                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulationPara2"
                          )}
                        </p>
                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulationPara3"
                          )}
                        </p>
                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulationPara4"
                          )}
                        </p>

                        <p
                          style={{
                            margin: "10px 0",
                          }}
                          className="text_color"
                        >
                          {t(
                            "gamebox.modals.helpmodal.fairnessSection.manipulationPar5"
                          )}
                        </p>
                      </div>
                    </>
                  )}

                  {detailsToShow == t("gamebox.modals.helpmodal.bankroll") && (
                    <>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.whatBankroll"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.bankrollPara"
                          )}
                        </p>
                      </div>

                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.poolFunds"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.poolFundsPara"
                          )}
                        </p>
                        <p className="text_color">
                          <span
                            style={{
                              color: "#2fa5cd",
                            }}
                          >
                            {t(
                              "gamebox.modals.helpmodal.bankrollSection.houseEdge"
                            )}
                          </span>
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.poolFundsParaLeft"
                          )}
                        </p>
                      </div>

                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.maxProfit"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.maxProfit"
                          )}
                        </p>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.bankrollSection.maxProfitPara"
                          )}
                        </p>
                        <table
                          class="centered_text margin-top-30 text_color width table-style"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th width="50%">Currency</th>
                            <th width="50%">Max Profit</th>
                          </tr>
                          {currencyLimits.map((obj) => (
                            <tr>
                              <td>{obj.network_id}</td>
                              <td>{obj.max_profit}</td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </>
                  )}

                  {detailsToShow ==
                    t("gamebox.modals.helpmodal.autoCashout") && (
                    <>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.internetLag"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.internetLapPara"
                          )}
                        </p>
                      </div>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.autoCashout"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.autoCashuotPara"
                          )}
                        </p>
                      </div>
                      <div className="details-section-container">
                        <h5
                          style={{
                            margin: "20px 0",
                          }}
                          className="secondary_text"
                        >
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.acciedentlyDisconnected"
                          )}
                        </h5>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.acciedentlyDisconnected"
                          )}
                        </p>
                        <p className="text_color">
                          {t(
                            "gamebox.modals.helpmodal.autoCashoutSection.acciedentlyDisconnectedPara"
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="help-helpModal-main">
              <div className="help-helpModal-inner">
                <div className="help-helpModal-content">
                  <div
                    style={{
                      padding: width <= 767 ? "10px" : "0 10px",
                      marginBottom: "20px",
                    }}
                    className="help-head-container my_row space-between"
                  >
                    <span className="orbitron-font">
                      {t("gamebox.modals.helpmodal.help")}
                    </span>

                    <button className="secondary_text" onClick={handleClose}>
                      <CloseIcon />
                    </button>
                  </div>

                  <div className="helpModal-content-contianer">
                    {helpItems.map((help, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            background: "#17181b",
                            margin: "10px",
                            padding: "20px 15px",
                            fontSize: "14px",
                          }}
                          id={help.name}
                          className="help-item my_row space-between bold"
                          onClick={() => {
                            slideDetails(help.name);
                          }}
                        >
                          <span>{help.title}</span>
                          <span className="justified">
                            <ChevronRightIcon />
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
}
