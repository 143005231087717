import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GameHistoryAPI from "../../../../api/game_history";
import { constants } from "../../../../store/constants";
import { setGameHistoriesData } from "../../../../store/game";
import RoundUsers from "./RoundUsers";

const History = () => {
  const dispatch = useDispatch();
  const game_state = useSelector((state) => state.game.game_state);
  const [fetchOnce, setFetchOnce] = useState(true);
  useEffect(() => {
    if (game_state == constants.MULTIPLIER_STOPPED || fetchOnce) {
      setFetchOnce(false);
      GameHistoryAPI.getGameHistory()
        .then((response) => {
          dispatch(
            setGameHistoriesData({
              game_histories_data: response.data.game_histories_data,
            })
          );
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [game_state]);
  const game_histories_data = useSelector(
    (state) => state.game.game_histories_data
  );

  const { t } = useTranslation();

  return (
    <div style={{ color: "#98a7b5" }} className="bets-history-main">
      <div className="bets-history-inner">
        <div
          style={{
            padding: "10px 0px",
            fontSize: "14px",
          }}
          className="bets-history-header-container my_row space-between"
        >
          <div className="bets-history-gameID width-20">
            <span>{t("stats.history.gameid")}</span>
          </div>
          <div className="bets-history-gameResult width-20 justified">
            <span>{t("stats.history.result")}</span>
          </div>
          <div className="bets-history-gameHash width-60 my_row end">
            <span>{t("stats.history.hash")}</span>
          </div>
        </div>
        <div
          style={{
            height: "405px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
          className="gameHistoryContainer my_row align-start"
        >
          {game_histories_data.length ? (
            game_histories_data?.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    height: "40px",
                  }}
                  className="bets-history-content-contnainer my_row width"
                >
                  <div className="gameId-container my_row start width-20 center_align">
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        background:
                          value.multiplier >= 10
                            ? "#f6c722"
                            : value.multiplier > 2
                            ? "#3bc117"
                            : "#ed6300",
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                      className="multiplierIndicator"
                    ></div>

                    <RoundUsers RoundId={value.game_id} />
                    {/* <span>{value}</span> */}
                  </div>

                  <div className="gameResult-container justified width-20">
                    <span>
                      {value.multiplier.toFixed(2)}
                      {value.multiplier.toFixed(2) && "x"}
                    </span>
                  </div>

                  <div className="gameHash-container width-60 my_row end center_align clear">
                    <span
                      style={{
                        float: "left",
                        width: "calc(100% - 60px)",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        textAlign: "right",
                      }}
                      // className="width-80"
                    >
                      {value.game_hashes}
                    </span>
                    <a
                      className="secondary_text"
                      style={{
                        textDecoration: "none",
                      }}
                      href={`https://hash-verification.web.app/?hash=${value.game_hashes}`}
                      target="_blank"
                    >
                      {t("stats.history.verify")}
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                flexDirection: "column",
                marginTop: "60px",
              }}
              className="showEmpty-containern justified width"
            >
              <div className="show-empty-img-container">
                <img
                  className="empty-max-width"
                  src="/imgs/empty.png"
                  alt="empty"
                />
              </div>

              <div className="show-empty-typo">
                <span style={{ fontSize: "12px" }} className="text_color">
                  {t("header.referral.oop")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
