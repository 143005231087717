import React from "react";

const UTC = () => {
  return (
    <img
      src="/imgs/understanding-chart.png"
      alt="UTC"
      style={{
        pointerEvents: "none",
        cursor: "default",
      }}
    />
  );
};

export default UTC;
