import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import TwoFactorAuth from "../../../../../../../api/two_factor_auth";
import useWindowDimensions from "../../../../../../Contexts/windowDimensions";
function Authenticate2Fa({ open, setOpen, setCanProcess }) {
  const { height, width } = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    width: width <= 767 ? width : 400,
    background: "#17181b",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };

  const [verifyOtp, setVerifyOtp] = useState();
  const handleClose = () => setOpen(false);

  const handleClick = () => {
    TwoFactorAuth.verify_otp({
      two_factor: {
        otp_response_code: verifyOtp,
      },
    }).then((res) => {
      handleClose();
      setCanProcess(true);
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="authenticate-withdraw">
            <div className="authenticate-withdraw-inner-container">
              <div className="authenticate-withdraw-content-container">
                <div className="authenticate-content-typo centered_text">
                  <span
                    style={{
                      fontSize: "13px",
                    }}
                    className="secondary_text"
                  >
                    For your account security,Please enter Google
                  </span>
                  <div className="width centered_text">
                    <span
                      className="secondary_text"
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      SECURITY-2FA
                    </span>
                  </div>

                  <div
                    style={{
                      marginTop: "30px",
                    }}
                    className="authenticate-input-container"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                      className="secondary_text"
                    >
                      GOOGLE SECURITY-2FA
                    </span>
                    <div
                      style={{
                        marginTop: "15px",
                      }}
                      className="input-container"
                    >
                      <input
                        value={verifyOtp}
                        onChange={(e) => {
                          setVerifyOtp(e.target.value);
                        }}
                        style={{
                          padding: "14px",
                          textAlign: "center",
                          boxSizing: "border-box",
                          background: "#111111",
                        }}
                        placeholder="x-x-x-x-x-x"
                        className="width"
                        type="number"
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                      }}
                      className="authenticate-button-container justified"
                    >
                      <button
                        className="white"
                        style={{
                          background:
                            "linear-gradient(to right, #6426d8 50%, #783beb 50%)",

                          padding: "15px 40px",
                        }}
                        onClick={handleClick}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Authenticate2Fa;
