import React, { useEffect, useState, memo, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import HttpsIcon from "@mui/icons-material/Https";
import BcwDataApi from "../../../api/bcw_data";
import { useDispatch } from "react-redux";
import { setbcwData } from "../../../store/user";
import UserWalletsAPI from "../../../api/user_wallets";
import { setUserWallets } from "../../../store/user";
import { modalsControl } from "../../Contexts/ModalsControl";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowDimensions from "../../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";

function BcdModal({ claimed, locked, unlocked }) {
  const { height, width } = useWindowDimensions();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#1e2024",
    border: "2px solid #000",
    boxShadow: 24,
    boxSizing: "border-box",
    width: width <= 767 ? width : 400,
    height: width <= 767 ? height : "590px",
    overflowX: "hidden",
  };

  const [open, setOpen] = useState(false);
  const { bcdModal, setBcdModal } = useContext(modalsControl);

  const [show, setShow] = useState(false);
  const handleOpen = () => {
    BcwDataApi.getBcdTransactions()
      .then((res) => {
        setBcdTransections(res.data.bcw_transactions);
        // setBcdTransections([bcdTransections]);
      })
      .catch((err) => {
        console.log(err);
      });
    setBcdModal(true);
  };
  const handleClose = () => setBcdModal(false);
  const [slideDetails, setSlideDetails] = useState(false);
  const [bcdTransections, setBcdTransections] = useState([]);

  const dispatch = useDispatch();
  const handleClain = () => {
    BcwDataApi.claimBcd()
      .then(() => {
        UserWalletsAPI.getUserWallets()
          .then((res) => {
            dispatch(
              setUserWallets({
                wallets: res.data,
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
        BcwDataApi.getBcwData().then((response) => {
          dispatch(
            setbcwData({
              bcwData: response.data.bcw,
            })
          );

          BcwDataApi.getBcdTransactions()
            .then((res) => {
              setBcdTransections(res.data.bcw_transactions);
              // setBcdTransections([bcdTransections]);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    BcwDataApi.getBcdTransactions()
      .then((res) => {
        setBcdTransections(res.data.bcw_transactions.reverse());
        // setBcdTransections([bcdTransections.reverse()]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSlideDetails = () => {
    slideDetails ? setSlideDetails(false) : setSlideDetails(true);
  };

  const { t } = useTranslation();
  return (
    <div>
      <button
        style={{
          color: "#fbcf12",
          position: "relative",
          zIndex: "10",
        }}
        className="justified bold"
        onClick={handleOpen}
      >
        <span>
          <HttpsIcon
            style={{
              maxWidth: "15px",
            }}
          />
        </span>
        {locked}
      </button>

      <Modal
        open={bcdModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modals-height-on-mobiles"
          style={{
            overflowY: slideDetails ? "hidden" : "scroll",
            borderRadius: "4px",
          }}
          sx={style}
        >
          <div
            style={{
              height: "100%",
              background: "#1e2024",
              top: "0",
              zIndex: "2",
              left: slideDetails ? "0" : "396px",
              transition: ".3s",
            }}
            className="bcd-unlock-details-slide absolute width"
          >
            <div className="bcd-unlock-details-slide-inner">
              <div className="bcd-unlock-details-content-container">
                <div
                  style={{
                    height: "50px",
                    position: "sticky",
                    top: "0px",
                    background: "#17181b",

                    padding: "0 20px",
                  }}
                  className="unlock-bcd-help-header my_row space-between"
                >
                  <span
                    style={{
                      textTransform: "uppercase",
                    }}
                    className="orbitron-font"
                  >
                    {t("header.bcwModal.aboutBcw")}
                  </span>

                  <button
                    className="secondary_text"
                    onClick={handleSlideDetails}
                  >
                    <CloseIcon />
                  </button>
                </div>

                <div
                  style={{
                    height: "515px",
                    overflowY: "auto",
                    fontSize: "14px",
                  }}
                  className="bcd-help-content"
                >
                  <div className="bcd-worth justified relative">
                    <img
                      style={{
                        maxWidth: "40%",
                      }}
                      src="/imgs/bcdWorth.png"
                      alt=""
                    />

                    <div
                      style={{
                        right: "15px",
                        top: "20px",
                      }}
                      className="back-to-bcd-wallet absolute"
                    >
                      <button
                        style={{
                          color: "#2fa5cd",
                        }}
                        onClick={handleSlideDetails}
                      >
                        {t("header.bcwModal.bcwUnlock")}
                      </button>
                    </div>
                  </div>

                  <div
                    style={{
                      marginBottom: "20px",
                      padding: "0px 20px",
                      textAlign: "justify",
                    }}
                    className="bcd-unlock-typo-container"
                  >
                    <h5
                      style={{
                        marginBottom: "10px",
                      }}
                      className="secondary_text"
                    >
                      {t("header.bcwModal.whatBcw")}
                    </h5>
                    <p className="text_color">
                      {t("header.bcwModal.paraWhatBcw")}
                    </p>
                  </div>

                  <div
                    style={{
                      padding: "0px 10px",
                      textAlign: "justify",
                    }}
                    className="bcdHelp-typo-formula"
                  >
                    <div
                      style={{
                        background: "#17181b",
                        padding: "10px",
                      }}
                      className="bcd-typo-formula-inner"
                    >
                      <h5
                        className="secondary_text"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {t("header.bcwModal.howToUnlock")}
                      </h5>
                      <p className="text_color">
                        {t("header.bcwModal.howToUnlock")}
                      </p>

                      <div
                        style={{
                          fontSize: "14px",
                          padding: "10px",
                          background: "#292e32",
                          margin: "10px 0",
                        }}
                        className="formula centered_text"
                      >
                        <p className="white">
                          {t("header.bcwModal.unlockAmount")}=
                          {t("header.bcwModal.wagerAmount")} * 1% * 20%
                        </p>
                      </div>

                      <h5
                        className="secondary_text"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {t("header.bcwModal.howToClaim")}
                      </h5>
                      <p className="text_color">
                        {t("header.bcwModal.hotToClaimPara")}
                      </p>
                      <h5
                        className="secondary_text"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {t("header.bcwModal.exchangeBcw")}
                      </h5>
                      <p className="text_color">
                        {t("header.bcwModal.exchangeBcwPara")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bcd-modal-main-container">
            <div className="bcd-modal-inner-container">
              <div className="bcd-modal-content-container">
                <div
                  style={{
                    padding: "15px",
                    boxSizing: "border-box",
                  }}
                  className="bcd-unlock-head-container my_row space-between"
                >
                  <div className="orbitron-font">
                    <span>{t("header.bcwModal.bcwUnlock")}</span>
                  </div>
                  <div className="close-icon">
                    <button className="text_color" onClick={handleClose}>
                      <CloseIcon />
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    background:
                      "linear-gradient(to bottom,#601b8c -27%,#1e2024 54%)",
                    padding: "5px 0px",
                  }}
                  className="bcd-modal-top-container"
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginBottom: "13px",
                    }}
                    className="how-to-unlock-details-container my_row width"
                  >
                    <button
                      style={{
                        color: "#2fa5cd",
                      }}
                      className="justified"
                      onClick={handleSlideDetails}
                    >
                      <span>{t("header.bcwModal.howToUnlock")}</span>
                      <span>
                        <ChevronRightIcon />
                      </span>
                    </button>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    className="amount-unlockable"
                  >
                    <span className="bold">
                      {t("header.bcwModal.amountUnlockable")}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                    className="unlockable-bcd-container justified"
                  >
                    <img
                      style={{
                        maxWidth: "20px",
                        marginRight: "5px",
                      }}
                      src="/imgs/AcceptedNetworks/Bcw.webp"
                      alt=""
                    />
                    <span
                      style={{
                        color: "#8410de",
                      }}
                      className="bold"
                    >
                      {locked}
                    </span>
                  </div>

                  <div className="bcd-platform-coin-container">
                    <div className="bcd-platform-container justified relative">
                      {/* <div
                        style={{
                          top: "40px",
                        }}
                        className="bcd-moving-img absolute justified"
                      >
                        <img
                          style={{
                            maxWidth: "45%",
                          }}
                          src="/imgs/bcdCoin.webp"
                          alt=""
                        />
                      </div> */}
                      <img
                        style={{
                          maxWidth: "40%",
                        }}
                        src="/imgs/platform.webp"
                        alt=""
                      />
                    </div>

                    <div
                      style={{
                        marginTop: "-23px",
                      }}
                      className="unlocked-bcd-container justified"
                    >
                      <span className="white bold">
                        {t("header.bcwModal.unlocked")}:
                      </span>
                      <img
                        style={{
                          maxWidth: "20px",
                          margin: "0 5px",
                        }}
                        src="/imgs/AcceptedNetworks/Bcw.webp"
                        alt=""
                      />
                      <span
                        style={{
                          color: "#8410de",
                        }}
                        className="bold"
                      >
                        {unlocked}
                      </span>
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                      }}
                      className="unlock-button-contaienr justified"
                    >
                      {parseFloat(unlocked) >= 10 && parseFloat(locked) > 0 ? (
                        <button
                          className="primary-btn primary-btn-success"
                          style={{
                            padding: "10px 30px",
                          }}
                          onMouseOver={() => {
                            setShow(true);
                          }}
                          onMouseOut={() => {
                            setShow(false);
                          }}
                          onClick={handleClain}
                        >
                          {t("header.bcwModal.claim")}
                        </button>
                      ) : (
                        <button
                          className="primary-btn primary-btn-disable"
                          style={{
                            padding: "10px 30px",
                          }}
                          onMouseOver={() => {
                            setShow(true);
                          }}
                          onMouseOut={() => {
                            setShow(false);
                          }}
                        >
                          {t("header.bcwModal.claim")}
                        </button>
                      )}
                    </div>

                    <div className="bcd-formula-container">
                      <div
                        style={{
                          background: "#141517",
                          width: "55%",
                          margin: "10px auto",
                          padding: "10px",
                        }}
                        className="bcd-formula-inner-container"
                      >
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                          className="bcd-formula-typo centered_text white bold"
                        >
                          <span>
                            {t("header.bcwModal.unlock")}=
                            {t("header.bcwModal.wager")} * 1% * 20%
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                          className="minimum centered_text"
                        >
                          <span className="secondary_text">
                            {t("header.bcwModal.minRequireToClaim")} :
                            <span
                              style={{
                                color: "rgb(132, 16, 222)",
                              }}
                            >
                              10
                            </span>
                            <span className="white">BCW</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: "10px",
                    padding: "10px",
                    position: "relative",
                    marginTop: show ? "-10px" : "-85px",
                    background: "#1e2024",
                    transition: ".3s",
                  }}
                  className="bcd-modal-bottom-container"
                >
                  <div className="bcd-bottom-head-container my_row space-between">
                    <div className="bcd-record" style={{ fontSize: "14px" }}>
                      <span className="text_color">
                        {t("header.bcwModal.bcwRecord")}
                      </span>
                    </div>
                    <div
                      className="total-claimed-bcd justified"
                      style={{ fontSize: "14px" }}
                    >
                      <span className="bold white">
                        {t("header.bcwModal.totalClaimed")}
                      </span>
                      <span className="justified">
                        <img
                          style={{ maxWidth: "20px", margin: "0 5px" }}
                          src="/imgs/AcceptedNetworks/Bcw.webp"
                          alt=""
                        />
                        <span
                          style={{
                            color: "#8410de",
                          }}
                        >
                          {claimed}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="bcd-bottom-data-container">
                    <div
                      style={{
                        fontSize: "14px",
                        background: "#24262a",
                        margin: "15px -10px 5px -10px",
                        padding: "10px",
                      }}
                      className="bcd-data-header my_row space-between secondary_text"
                    >
                      <span>{t("header.bcwModal.date")}</span>
                      <span>{t("header.bcwModal.type")}</span>
                      <span>{t("header.bcwModal.amount")}</span>
                    </div>

                    <div
                      style={{
                        height: "190px",
                        overflowY: "auto",
                        margin: "0 -10px",
                        padding: "0 10px",
                      }}
                      className="bcd-data"
                    >
                      {bcdTransections.map((type, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              height: "50px",
                              fontSize: "13px",
                            }}
                            className="bcd-data-header my_row space-between width"
                          >
                            <span className="text_color">
                              {new Date(type.created_at).toLocaleDateString(
                                "en-US"
                              )}
                              <div>
                                {new Date(type.created_at).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </div>
                            </span>
                            <span className="secondary_text">
                              {type.transaction_type === "deposit"
                                ? t("header.bcwModal.deposit")
                                : type.transaction_type === "claimed"
                                ? t("header.bcwModal.claimed")
                                : type.transaction_type === "referal-bonus"
                                ? "Referal Bonus"
                                : t("header.bcwModal.spinWheel")}
                            </span>
                            <span
                              style={{
                                color: "#2fa5cd",
                              }}
                              className="justified bold"
                            >
                              <img
                                style={{
                                  maxWidth: "20px",
                                  marginRight: "5px",
                                }}
                                src="/imgs/AcceptedNetworks/Bcd.webp"
                                alt=""
                              />
                              +{type.amount}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default memo(BcdModal);
