import React, { useState, useEffect, useContext } from "react";
import DepositHistoryAPI from "../../../../../api/deposit_history";
import { loadingContext } from "../../../../Contexts/Loading";
import Paginator from "../../../Paginator/Paginator";
import DataWrapper from "./dataWrapper/DataWrapper";

const DepositTransactions = ({ type }) => {
  const [depositHistory, setDepositHistory] = useState([]);
  const { loadingData, setLoadingData } = useContext(loadingContext);
  const [currentPage, handlePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastPage, setlastPage] = useState(false);
  useEffect(() => {
    setLoadingData(true);
    setlastPage(true);

    DepositHistoryAPI.get_deposit_histories_data(currentPage).then(
      (response) => {
        setDepositHistory(response.data.deposit_histories_data);
        handlePage(response.data.current_page);
        setTotalPages(response.data.total_pages);
        setlastPage(response.data.last_page);
        setLoadingData(false);
      }
    );
  }, [currentPage]);

  return (
    <>
      <DataWrapper
        transactions={depositHistory}
        loadingData={loadingData}
        type={type}
      />
      <div
        style={{
          position: "fixed",
          bottom: "21px",
          zIndex: 1,
          boxSizing: "border-box",
          width: "94%",
        }}
        className="pagination-position-assign"
      >
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          lastPage={lastPage}
          handlePage={handlePage}
        />
      </div>
    </>
  );
};

export default DepositTransactions;
