import { useState, useEffect, createContext } from "react";

const clientSocketContext = createContext();
var betCreaterChannel = null;
var betUpdaterChannel = null;
const SocketBetsConnection = ({ children }) => {
  const [createBetChannel, setCreateBetChannel] = useState(null);
  betCreaterChannel = createBetChannel;

  const [updateBetChannel, setUpdateBetChannel] = useState(null);
  betUpdaterChannel = updateBetChannel;

  return (
    <clientSocketContext.Provider
      value={{
        setCreateBetChannel,
        setUpdateBetChannel,
        createBetChannel,
        updateBetChannel,
      }}
    >
      {children}
    </clientSocketContext.Provider>
  );
};

export {
  clientSocketContext,
  SocketBetsConnection,
  betCreaterChannel,
  betUpdaterChannel,
};
