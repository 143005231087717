import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./StatsCss.css";
import MyHistory from "./Tabs/MyHistory";
import HistoryComp from "./Tabs/History";
import Highrollers from "./Tabs/Highrollers";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const [MyBets, setMyBets] = useState(true);
  const [History, setHistory] = useState(false);
  const [highRollers, sethighRollers] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleMyBets = () => {
    setMyBets(true);
    setHistory(false);
    sethighRollers(false);
  };
  const handleHistory = () => {
    setHistory(true);
    setMyBets(false);
    sethighRollers(false);
  };
  const handleHighrollers = () => {
    sethighRollers(true);
    setMyBets(false);
    setHistory(false);
  };

  const HandleClick = () => {
    showMore ? setShowMore(false) : setShowMore(true);
  };
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: showMore ? "auto" : "550px",
        // overflow: "hidden",
      }}
      className="stats-main-div relative"
    >
      <div className="stats-inner-div">
        <div className="history-myHistory-switch justified my_row_item">
          <button
            name="MyBets"
            className={
              MyBets
                ? "myHistory my_row_item_2 stats-button stats-button-active"
                : "myHistory my_row_item_2 stats-button "
            }
            onClick={handleMyBets}
          >
            {t("stats.mybetstxt")}
          </button>
          <button
            name="History"
            className={
              History
                ? "myHistory my_row_item_2 stats-button stats-button-active"
                : "myHistory my_row_item_2 stats-button "
            }
            onClick={handleHistory}
          >
            {t("stats.historytxt")}
          </button>
          <button
            name="highRollers"
            className={
              highRollers
                ? "myHistory my_row_item_2 stats-button stats-button-active"
                : "myHistory my_row_item_2 stats-button "
            }
            onClick={handleHighrollers}
          >
            {t("stats.highrollerstxt")}
          </button>
        </div>
        <div className="history-myHistory-data-container">
          {MyBets && <MyHistory />}
          {History && (
            <HistoryComp showMore={showMore} setShowMore={setShowMore} />
          )}
          {highRollers && (
            <Highrollers showMore={showMore} setShowMore={setShowMore} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Stats;
