import { useContext } from "react";
import { useState, useEffect, createContext } from "react";
import socketClusterClient from "socketcluster-client";
import {
  betStatusSubscribe,
  betCreater,
  betUpdater,
  allBetCatcher,
} from "../../channels/bet_status_channel";
import { clientSocketContext } from "./SocketBets";

const multiplierContext = createContext();
var clientSocketOutside = null;
const MultiplierProvider = ({ children }) => {
  const { setCreateBetChannel, setUpdateBetChannel } =
    useContext(clientSocketContext);
  const [clientSocket, setClientSocket] = useState(
    socketClusterClient.create({
      hostname: process.env.REACT_APP_BROKER_LINK,
    })
  );

  clientSocketOutside = clientSocket;
  const [myChannel, setMyChannel] = useState(null);
  const [stateTimeToTrigger, setStateTimeToTrigger] = useState(true);

  (async () => {
    for await (let { error } of clientSocket.listener("error")) {
      console.error(error);
    }
  })();

  (async () => {
    for await (let event of clientSocket.listener("disconnect")) {
      // setClientSocket(
      //   socketClusterClient.create({
      //     hostname: process.env.REACT_APP_BROKER_LINK,
      //   })
      // );
    }
  })();
  const reConnect = () => {
    setClientSocket(
      socketClusterClient.create({
        hostname: process.env.REACT_APP_BROKER_LINK,
      })
    );
  };

  return (
    <multiplierContext.Provider
      value={{
        clientSocket,
        setClientSocket,
        myChannel,
        setMyChannel,
        setCreateBetChannel,
        setUpdateBetChannel,
        stateTimeToTrigger,
        setStateTimeToTrigger,
        reConnect,
      }}
    >
      {children}
    </multiplierContext.Provider>
  );
};

export { multiplierContext, MultiplierProvider, clientSocketOutside };
