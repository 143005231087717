import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { modalsControl } from "../../../../../../Contexts/ModalsControl";
import TabNavItem from "../../../../../Tabs/TabNavItem";
import TabContent from "../../../../../Tabs/TabContent";
import Crypto from "./Crypto/Crypto";
import Fiat from "./Fiat/Fiat";
import "./WithDraw.css";
import { useTranslation, Trans } from "react-i18next";
import ShowComponent from "../../../../../ShowComponent/ShowComponent";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import UserWalletsAPI from "../../../../../../../api/user_wallets";

const WithDraw = () => {
  const user_id = useSelector((state) => state.user.currentUser?.user_id);
  const [fiatWithdrawMethod, setFiatWithdrawMethod] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [currentlyActive, setCurrentlyActive] = useState("");
  const [withdrawalError, setWithdrawalError] = useState([]);
  const { activeTab, setActiveTab, setFiatWithdraw } =
    useContext(modalsControl);
  const wallets = useSelector((state) => state.user.wallets);

  const formInitialValues = {
    description: "testing withdrawal",
    payMoney: "",
    bankName: "",
    bankCode: "945",
    bankCodeXpay: "101",
    idrWallet: "10001",
    email: "",
    phone: "",
    name: "",
    user_id: user_id,
    number: "",
  };
  const [min, setMin] = useState(null);
  const [minErr, setMinErr] = useState(false);
  const [maxErr, setMaxErr] = useState(false);
  const [responseErr, setResponseErr] = useState(false);
  const [responseErrBtn, setResponseErrBtn] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [max, setMax] = useState(null);
  const [formDataValues, setFormData] = useState(formInitialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formDataValues, [name]: value });
  };
  const handlePayMoneyBlur = (e) => {
    const { value, name } = e.target;
    if (!value || value == "0") {
      setFormData({ ...formDataValues, [name]: min });
    } else if (parseFloat(value) < 0) {
      setFormData({ ...formDataValues, [name]: min });
    }
  };
  const supported_deposit_methods = [
    {
      id: "idrWallet",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 20000,
      max: 50000000,
      differ: "idr",
    },
    {
      id: "idr",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 20000,
      max: 10000000,
      differ: "idr",
    },
    {
      id: "thb",
      bank: "bankTransfer",
      method: "BANKTRANSFER",
      min: 50,
      max: 100000,
      differ: "thb",
    },
  ];

  const [selectedBank, setSelectedBank] = useState("");

  const fiatWithdrawHandle = (e) => {
    const { id, name } = e.target;
    setCurrentlyActive(id);
    console.log("Here is the id ::: ", id);
    setSelectedBank(supported_deposit_methods.find((bank) => bank.id == id));
    setFiatWithdraw(true);
    setFiatWithdrawMethod(true);
  };
  const formCloseHandle = () => {
    setFiatWithdrawMethod(false);
    setFormData(formInitialValues);
  };
  useEffect(() => {
    // setFormData({ ...formDataValues, payMoney: selectedBank.min });
    setMin(selectedBank.min);
    setMax(selectedBank.max);
  }, [selectedBank]);

  const initiateWithdraw = (e) => {
    e.preventDefault();
    if (loadingWithdraw || responseSuccess || responseErrBtn) {
      return;
    }
    if (parseFloat(formDataValues.payMoney) < min) {
      setMinErr(true);
      return;
    } else {
      if (minErr) {
        setMinErr(false);
      }
    }
    if (parseFloat(formDataValues.payMoney) > max) {
      setMaxErr(true);
      return;
    } else {
      if (maxErr) {
        setMaxErr(false);
      }
    }

    if (currentlyActive === "idr" || currentlyActive === "idrWallet") {
      setLoadingWithdraw(true);

      const dataToPass = {
        withdraw_amount: {
          network_id:
            currentlyActive === "thb"
              ? "thai_baht"
              : currentlyActive === "php"
              ? "philippine_peso"
              : currentlyActive === "idr"
              ? "indonesian_rupiah"
              : idrWallets
              ? "indonesian_rupiah"
              : currentlyActive,

          amount: formDataValues.payMoney,
        },
      };
      UserWalletsAPI.withdrawalToWallet(dataToPass)
        .then((res) => {
          let data = new FormData();
          data.append("payMoney", formDataValues.payMoney);
          data.append(
            "bankCode",
            currentlyActive === "idrWallet"
              ? formDataValues.idrWallet
              : formDataValues.bankCode
          );
          data.append("email", formDataValues.email);
          data.append("phone", formDataValues.phone);
          data.append("name", formDataValues.name);
          data.append("user_id", user_id);
          data.append("number", formDataValues.number);
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PHP_BACKEND_LINK}/api/top-pay/withdrawal`,
            data: data,
          };
          axios
            .request(config)
            .then((response) => {
              setLoadingWithdraw(false);
              setResponseSuccess(true);
              setTimeout(() => {
                setResponseSuccess(false);
              }, 3000);
            })
            .catch((error) => {
              setLoadingWithdraw(false);
              setWithdrawalError(error.response.data.responseMessage);
              setResponseErr(true);
              setResponseErrBtn(true);
              setTimeout(() => {
                setResponseErrBtn(false);
              }, 2000);
            });
        })
        .catch((err) => {
          setResponseErr(true);
          setWithdrawalError(err.response.data.message);
          setLoadingWithdraw(false);
          setResponseErrBtn(true);
          setTimeout(() => {
            setResponseErrBtn(false);
          }, 2000);
        });
    } else {
      setLoadingWithdraw(true);
      const dataToPass = {
        withdraw_amount: {
          network_id:
            currentlyActive === "thb"
              ? "thai_baht"
              : currentlyActive === "php"
              ? "philippine_peso"
              : currentlyActive === "idr"
              ? "indonesian_rupiah"
              : idrWallets
              ? "indonesian_rupiah"
              : currentlyActive,

          amount: formDataValues.payMoney,
        },
      };
      UserWalletsAPI.withdrawalToWallet(dataToPass)
        .then((res) => {
          let data = new FormData();
          data.append("Amount", formDataValues.payMoney);
          data.append("user_id", user_id);
          data.append(
            "network_id",
            currentlyActive === "thb"
              ? "thai_baht"
              : currentlyActive === "php"
              ? "philippine_peso"
              : currentlyActive === "idr"
              ? "indonesian_rupiah"
              : idrWallets
              ? "indonesian_rupiah"
              : currentlyActive
          );
          data.append("bank_country", "php");
          data.append("user_name", formDataValues.name);
          data.append("card_no", formDataValues.number);
          data.append("bank_code", formDataValues.bankCode);

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_PHP_BACKEND_LINK}/api/ax-pay/withdrawal`,
            data: data,
          };
          axios
            .request(config)
            .then((response) => {
              setLoadingWithdraw(false);
              setResponseSuccess(true);
              setTimeout(() => {
                setResponseSuccess(false);
              }, 3000);
            })
            .catch((error) => {
              setLoadingWithdraw(false);
              setWithdrawalError(error.response.data.responseMessage);
              setResponseErr(false);
              setResponseErrBtn(true);
              setTimeout(() => {
                setResponseErrBtn(false);
              }, 2000);
            });
        })
        .catch((err) => {
          setResponseErr(true);
          setWithdrawalError(err.response.data.message);
          setLoadingWithdraw(false);
          setResponseErrBtn(true);
          setTimeout(() => {
            setResponseErrBtn(false);
          }, 2000);
        });
    }
  };

  const handleErrHide = () => {
    setResponseErr(false);
  };

  const banks = [
    {
      bankName: "Bank IBK Indonesia",
      bankCode: "945",
    },
    {
      bankName: "BANK AMAR INDONESIA",
      bankCode: "1162",
    },
    {
      bankName: "Bank ANZ Indonesia",
      bankCode: "061",
    },
    {
      bankName: "BANK BARCLAYS INDONESIA",
      bankCode: "945",
    },
    {
      bankName: "Bank Negara Indonesia (BNI)",
      bankCode: "009",
    },
    {
      bankName: "Bank Rakyat Indonesia (BRI)",
      bankCode: "002",
    },
    {
      bankName: "Bank Syariah Indonesia (BSI)",
      bankCode: "4510",
    },
    {
      bankName: "Bank Capital Indonesia",
      bankCode: "054",
    },
    {
      bankName: "CTBC Indonesia",
      bankCode: "949",
    },
    {
      bankName: "Bank DBS Indonesia",
      bankCode: "046",
    },
    {
      bankName: "Bank Dinar Indonesia",
      bankCode: "526",
    },
    {
      bankName: "Bank ICBC Indonesia",
      bankCode: "164",
    },
    {
      bankName: "Bank of India Indonesia",
      bankCode: "146",
    },
    {
      bankName: "Bank JTrust Indonesia",
      bankCode: "095",
    },
    {
      bankName: "Bank Maybank Syariah Indonesia",
      bankCode: "947",
    },
    {
      bankName: "Bank Maybank Syariah Indonesia UUS",
      bankCode: "0160",
    },
    {
      bankName: "Bank Mayora Indonesia",
      bankCode: "553",
    },
    {
      bankName: "Bank Muamalat Indonesia",
      bankCode: "147",
    },
    {
      bankName: "Bank Mizuho Indonesia",
      bankCode: "048",
    },
    {
      bankName: "BANK PUNDI INDONESIA",
      bankCode: "584",
    },
    {
      bankName: "QNB Indonesia",
      bankCode: "1670",
    },
    {
      bankName: "Bank Oke Indonesia",
      bankCode: "5260",
    },
    {
      bankName: "Rabobank International Indonesia",
      bankCode: "089",
    },
    {
      bankName: "Bank SBI Indonesia",
      bankCode: "498",
    },
    {
      bankName: "Bank Shinhan Indonesia",
      bankCode: "152",
    },
    {
      bankName: "Bank Sumitomo Mitsui Indonesia",
      bankCode: "045",
    },
    {
      bankName: "TMRW/Bank UOB Indonesia",
      bankCode: "023",
    },
    {
      bankName: "CCB Indonesia",
      bankCode: "088",
    },

    {
      bankName: "Bank Sumitomo Mitsui Indonesia",
      bankCode: "045",
    },

    {
      bankName: "Bank Mandiri",
      bankCode: "008",
    },
  ];
  const idrWallets = [
    {
      bankName: "OVO",
      bankCode: "10001",
    },
    {
      bankName: "DANA",
      bankCode: "10002",
    },
    {
      bankName: "GOPAY",
      bankCode: "10003",
    },
    {
      bankName: "SHOPE EPAY",
      bankCode: "10008",
    },
  ];
  const banksXPay = [
    {
      bankName: "Bangkok Bank",
      bankCode: "101",
    },
    {
      bankName: "Kasikorn Bank",
      bankCode: "102",
    },
    {
      bankName: "Siam Commercial Bank",
      bankCode: "103",
    },
    {
      bankName: "Krung Thai Bank",
      bankCode: "104",
    },
    {
      bankName: "TTB Bank",
      bankCode: "105",
    },
    {
      bankName: "Krungsri Bank Bank Of Ayudhya",
      bankCode: "106",
    },
    {
      bankName: "Thanachart Bank",
      bankCode: "107",
    },
    {
      bankName: "Bank for Agriculture and Agricultural Cooperatives",
      bankCode: "108",
    },
    {
      bankName: "Land and Houses Bank",
      bankCode: "109",
    },
    {
      bankName: "Government Housing Bank",
      bankCode: "110",
    },
    {
      bankName: "Kiatnakin Bank",
      bankCode: "111",
    },
    {
      bankName: "CIMB Bank",
      bankCode: "112",
    },
    {
      bankName: "United Overseas Bank",
      bankCode: "113",
    },
    {
      bankName: "Government Savings Bank",
      bankCode: "114",
    },
    {
      bankName: "Citibank National Association",
      bankCode: "115",
    },
    {
      bankName: "Mizuho Bank",
      bankCode: "116",
    },
    {
      bankName: "Islamic Bank of Thailand",
      bankCode: "117",
    },
    {
      bankName: "Tisco Bank",
      bankCode: "118",
    },
    {
      bankName: "ICBC (Thai) Leasing Company Limited",
      bankCode: "119",
    },
    {
      bankName: "The Thai Credit Retail Bank",
      bankCode: "120",
    },
    {
      bankName: "Sumitomo Mitsui Banking Corporation",
      bankCode: "121",
    },
    {
      bankName: "The Hongkong and Shanghai Banking Corporation Limited",
      bankCode: "122",
    },
    {
      bankName: "Deutsche Bank AG",
      bankCode: "123",
    },
    {
      bankName: "Bank of China",
      bankCode: "124",
    },
    {
      bankName: "Australia and New Zealand Banking Group Limited",
      bankCode: "125",
    },
    {
      bankName: "INDIAN OVERSEAS BANK",
      bankCode: "126",
    },
    {
      bankName: "STANDARD CHARTERED BANK THAI PCL",
      bankCode: "127",
    },
    {
      bankName: "TrueMoney Wallet",
      bankCode: "128",
    },
    {
      bankName: "LINE Pay Money",
      bankCode: "129",
    },
    {
      bankName: "Dolfin Wallet",
      bankCode: "130",
    },
    {
      bankName: "Air pay Wallet",
      bankCode: "131",
    },
  ];
  const { t } = useTranslation();
  return (
    <div
      className="withdraw-component-main-class wallet-scroll-whole relative text_color orbitron-font "
      style={{ fontSize: "12px" }}
    >
      {fiatWithdrawMethod ? (
        <div
          className="absolute width height"
          style={{
            left: fiatWithdrawMethod ? "0" : "-100%",
            opacity: fiatWithdrawMethod ? "100%" : "0%",
            transition: ".3s",
            zIndex: "20",
          }}
        >
          <div className="withdraw-component-top-fiat my_row space-between padding-left-right relative">
            <div>{t("header.wallet.bankwithdraw")}</div>

            <div onClick={formCloseHandle} style={{ cursor: "pointer" }}>
              X
            </div>
          </div>

          <form
            onSubmit={initiateWithdraw}
            id="formToSubmit"
            className="relative"
            style={{
              overflow: "hidden",
            }}
          >
            <div
              style={{
                background: "#0e0e0e",
                minHeight: "50px",
                zIndex: "15",
                padding: "15px",
                opacity: `${responseErr ? "100%" : "0"}`,
                top: `${responseErr ? "0px" : "-50px"}`,
              }}
              className="for-withdrawal-error absolute width darken-bg my_row space-between"
            >
              {Array.isArray(withdrawalError) ? (
                withdrawalError.map((err) => (
                  <div className="error-container width-80">
                    <div>
                      <span
                        className="bold"
                        style={{
                          color: "red",
                        }}
                      >
                        {err}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="error-container width-80">
                  <div>
                    <span
                      className="bold"
                      style={{
                        color: "red",
                      }}
                    >
                      {withdrawalError}
                    </span>
                  </div>
                </div>
              )}
              <div
                style={{
                  width: "5%",
                }}
                className="cross-icon-container"
              >
                <div className="white" onClick={handleErrHide}>
                  <CloseIcon />
                </div>
              </div>
            </div>
            <div className="withdraw-component-bottom-fiat modal-form-inputs-container padding-left-right ">
              <div className="withdraw-component-bottom-fiat-content modal-input login-email width">
                <div className="input-label-container input-color">
                  <div className="margin-left-10 width my_row space-between">
                    <label>
                    {t("header.wallet.transactionTab.amount")}({min} ~ {max})
                    </label>
                    <ShowComponent condition={minErr}>
                      <label className="rc">
                      {t("header.wallet.minamount")}  {min}
                      </label>
                    </ShowComponent>
                    <ShowComponent condition={maxErr}>
                      <label className="rc">
                        {t("header.wallet.maxamount")} {max}
                      </label>
                    </ShowComponent>
                  </div>
                  <div>
                    <input
                      type="number"
                      name="payMoney"
                      className="emailInp-text-input ModalInps input-color width"
                      value={formDataValues.payMoney}
                      onChange={handleInputChange}
                      onBlur={handlePayMoneyBlur}
                      required
                    />
                  </div>
                </div>
                <div className="input-label-container input-color">
                  <div className="margin-left-10">
                    <label>{t("header.wallet.name")}</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="name"
                      className="emailInp-text-input ModalInps input-color width"
                      value={formDataValues.name}
                      onChange={handleInputChange}
                      minLength="3"
                      required
                    />
                  </div>
                </div>

                <div className="input-label-container input-color">
                  <div className="margin-left-10">
                    <label>{t("header.wallet.bankname")}</label>
                  </div>
                  <div>
                    <ShowComponent condition={currentlyActive === "idr"}>
                      <select
                        className="emailInp-text-input ModalInps input-color width border-none"
                        name="bankCode"
                        id="bankCode"
                        value={formDataValues.bankCode}
                        onChange={handleInputChange}
                      >
                        {banks.map((bank) => (
                          <option value={bank.bankCode}>{bank.bankName}</option>
                        ))}
                      </select>
                    </ShowComponent>
                    <ShowComponent condition={currentlyActive === "thb"}>
                      <select
                        className="emailInp-text-input ModalInps input-color width border-none"
                        name="bankCodeXpay"
                        id="bankCode"
                        value={formDataValues.bankCodeXpay}
                        onChange={handleInputChange}
                      >
                        {banksXPay.map((bank) => (
                          <option value={bank.bankCode}>{bank.bankName}</option>
                        ))}
                      </select>
                    </ShowComponent>
                    <ShowComponent condition={currentlyActive === "idrWallet"}>
                      <select
                        className="emailInp-text-input ModalInps input-color width border-none"
                        name="idrWallet"
                        id="idrWallet"
                        value={formDataValues.idrWallet}
                        onChange={handleInputChange}
                      >
                        {idrWallets.map((bank) => (
                          <option value={bank.bankCode}>{bank.bankName}</option>
                        ))}
                      </select>
                    </ShowComponent>
                  </div>
                </div>

                <div className="input-label-container input-color">
                  <div className="margin-left-10">
                    <label>{t("header.referral.cardnumber")}</label>
                  </div>
                  <div>
                    <input
                      type="number"
                      name="number"
                      className="emailInp-text-input ModalInps input-color width"
                      value={formDataValues.number}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <ShowComponent
                  condition={
                    currentlyActive == "idr" || currentlyActive == "idrWallet"
                  }
                >
                  <div className="input-label-container input-color">
                    <div className="margin-left-10">
                      <label>{t("header.wallet.phone")}</label>
                    </div>
                    <div>
                      <input
                        type="tel"
                        name="phone"
                        className="emailInp-text-input ModalInps input-color width"
                        value={formDataValues.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </ShowComponent>
                <ShowComponent
                  condition={
                    currentlyActive == "idr" || currentlyActive == "idrWallet"
                  }
                >
                  <div className="input-label-container input-color">
                    <div className="margin-left-10">
                      <label>{t("header.signin.email")}</label>
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        className="emailInp-text-input ModalInps input-color width"
                        value={formDataValues.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </ShowComponent>
              </div>

              {/* <div className="justify my_row margin-bottom-10">
                {t("header.referral.fee")} &nbsp; <span className="gc"> 0</span>
              </div> */}

              <div className="justify my_row margin-bottom-10">
                <div
                  style={{
                    marginBottom: "10px",
                  }}
                  className="withdraw-fee-container width justified"
                >
                  <ShowComponent condition={currentlyActive === "idr"}>
                    <p className="secondary_text">
                      {t("header.referral.fee")} :{" "}
                      <span className="gc bold">
                        {formDataValues.payMoney
                          ? (
                              (parseFloat(formDataValues.payMoney) / 100) * 5 +
                              6000
                            ).toFixed(2)
                          : 6000}
                      </span>
                    </p>
                  </ShowComponent>
                  <ShowComponent condition={currentlyActive === "thb"}>
                    <p className="secondary_text">
                    {t("header.referral.fee")} :{" "}
                      <span className="gc bold">
                        {formDataValues.payMoney
                          ? (
                              (parseFloat(formDataValues.payMoney) / 100) *
                              5
                            ).toFixed(2)
                          : 0}
                      </span>
                    </p>
                  </ShowComponent>
                </div>
                <button
                  type="submit"
                  className={`primary-btn ${
                    responseSuccess
                      ? "primary-btn-success"
                      : responseErrBtn
                      ? "primary-btn-danger"
                      : "primary-btn-bg"
                  } orbitron-font`}
                >
                  {loadingWithdraw ? (
                    <div
                      style={{
                        top: "-45px",
                      }}
                      className="lds-ellipsis"
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : responseSuccess ? (
                    "Success"
                  ) : responseErrBtn ? (
                    "Failed"
                  ) : (
                    t("header.wallet.confirm")
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="withdraw-component-inner-class">
          <div className="withdraw-component-content-class">
            <div className="tabs-scroll">
              <div className="tabs-navs justified">
                <TabNavItem
                  title="Crypto"
                  id="crypto"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <TabNavItem
                  title="Fiat"
                  id="fiat"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
            <div className="withdraw-bottom-container">
              <TabContent id="crypto" activeTab={activeTab}>
                <div className="for-crypto">
                  <Crypto activeWallets={wallets} walletType={activeTab} />
                </div>
              </TabContent>
              <TabContent id="fiat" activeTab={activeTab}>
                <div className="for-fiat">
                  <Fiat
                    activeWallets={wallets}
                    walletType={activeTab}
                    handleClick={fiatWithdrawHandle}
                  />
                </div>
              </TabContent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithDraw;
