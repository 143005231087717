import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentUser: null,
  wheelStatus: null,
  wheelTime: null,
  token: null,
  two_fa_enabled: false,
  wallets: null,
  bcwData: null,
  selectedWallet: null,
  betWallet: null,
  loadingWalletInfo: false,
  betPlaced: false,
  betLoading: false,
  cashedOut: false,
  betAmount: 0,
  cashedOutMultiplier: 0,
  betHistory: [],
  user_profile: null,
  verified: false,
  converted_fake_amount: 0,
  swap_currencies_data: null,
  deposit_histories_data: null,
  withdrawal_histories_data: null,
  final_deposit_histories_data: null,
  final_withdrawal_histories_data: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
    },
    setWheelStatus: (state, action) => {
      state.wheelStatus = action.payload.wheelStatus;
    },
    setWheelTime: (state, action) => {
      state.wheelTime = action.payload.wheelTime;
    },
    setBetPlaced: (state, action) => {
      state.betPlaced = action.payload.betPlaced;
    },
    setBetLoading: (state, action) => {
      state.betLoading = action.payload.betLoading;
    },
    setCashedOut: (state, action) => {
      // if(state.cashedOut !== false) {
      state.cashedOut = action.payload.cashedOut;
      // }
    },
    setBetAmount: (state, action) => {
      state.betAmount = action.payload.betAmount;
    },
    setBetHistory: (state, action) => {
      const betHistory = action.payload.betHistory;
      betHistory.sort((a, b) => (a.id < b.id ? 1 : -1));
      state.betHistory = betHistory;
    },
    setTwoFAEnabled: (state, action) => {
      state.two_fa_enabled = action.payload.two_fa_enabled;
    },
    setVerified: (state, action) => {
      state.verified = action.payload.verified;
    },

    setCashedOutMultiplier: (state, action) => {
      state.cashedOutMultiplier = action.payload.cashedOutMultiplier;
    },
    setLoadingWalletInfo: (state, action) => {
      state.loadingWalletInfo = action.payload.loadingWalletInfo;
    },
    setUserWallets: (state, action) => {
      state.wallets = action.payload.wallets;
      const selectedWallet = action.payload.wallets.filter((obj) => {
        return obj.default_wallet == true;
      });
      state.selectedWallet = selectedWallet[0];
    },
    setBetWallet: (state, action) => {
      const selectedWallet = state.wallets.filter((obj) => {
        return obj.network_id == action.payload.network_id;
      });
      state.betWallet = selectedWallet[0];
    },
    setbcwData: (state, action) => {
      state.bcwData = action.payload.bcwData;
    },
    setDefaultWallet: (state, action) => {
      const wallets = state.wallets.map((obj) => {
        if (obj.id === action.payload.id) {
          obj.default_wallet = true;
          obj.is_usdt = action.payload.is_usdt;
          state.selectedWallet = obj;
        } else {
          obj.default_wallet = false;
          obj.is_usdt = false;
        }
        return obj;
      });
      state.wallets = wallets;
    },
    setUserProfile: (state, action) => {
      state.user_profile = action.payload.user_profile;
    },
    setConvertedFakeAmount: (state, action) => {
      state.converted_fake_amount = action.payload.converted_fake_amount;
    },
    setSwapCurrencyData: (state, action) => {
      state.swap_currencies_data = action.payload.swap_currencies_data;
    },
    setDepositHistoriesData: (state, action) => {
      state.deposit_histories_data = action.payload.deposit_histories_data;
    },
    setWithdrawalHistoriesData: (state, action) => {
      state.withdrawal_histories_data =
        action.payload.withdrawal_histories_data;
    },
    setFinalDepositHistoriesData: (state, action) => {
      state.final_deposit_histories_data =
        action.payload.final_deposit_histories_data;
    },
    setFinalWithdrawalHistoriesData: (state, action) => {
      state.final_withdrawal_histories_data =
        action.payload.final_withdrawal_histories_data;
    },
  },
});
export const {
  setAuthData,
  setBetPlaced,
  setBetLoading,
  setBetAmount,
  setCashedOut,
  setBetHistory,
  setCashedOutMultiplier,
  setLoadingWalletInfo,
  setUserWallets,
  setDefaultWallet,
  setUserProfile,
  setConvertedFakeAmount,
  setSwapCurrencyData,
  setDepositHistoriesData,
  setWithdrawalHistoriesData,
  setFinalDepositHistoriesData,
  setFinalWithdrawalHistoriesData,
  setbcwData,
  setTwoFAEnabled,
  setWheelTime,
  setWheelStatus,
  setVerified,
  setBetWallet,
} = userSlice.actions;
export default userSlice.reducer;
