import React, { useState, useEffect } from "react";
import HighRollersAPI from "../../../../api/high_rollers";
import { constants } from "../../../../store/constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const Highrollers = () => {
  const [highRollers, setHighrollers] = useState([]);
  const [fetchOnce, setFetchOnce] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const game_state = useSelector((state) => state.game.game_state);
  useEffect(() => {
    if (game_state === constants.MULTIPLIER_STOPPED || fetchOnce) {
      HighRollersAPI.getHighRollers().then((res) => {
        setHighrollers(res.data.high_rollers);
        setIsLoading(false);
        setFetchOnce(false);
      });
    }
  }, [game_state]);
  const { t } = useTranslation();
  return isLoading ? (
    <div
      style={{
        top: "190px",
        left: "50%",
      }}
      className="lds-ellipsis relative"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  ) : (
    <div className="high-rollers-mainROw">
      <div className="high-rollers-content-row">
        <div
          style={{
            padding: "10px 0px",
            fontSize: "14px",
          }}
          className="high-rollers-heading-row secondary_text my_row space-between"
        >
          <div className="high-rollers-heading-playerName width-25 my_row start">
            <span>{t("stats.highrollers.player")}</span>
          </div>
          <div className="high-rollers-heading-betAmmoung width-25 justified">
            <span>{t("stats.highrollers.betamount")}</span>
          </div>
          <div className="high-rollers-heading-multiplier width-25 justified">
            <span>{t("stats.highrollers.multiplier")}</span>
          </div>
          <div className="high-rollers-heading-profitAmmount width-25 my_row end">
            <span>{t("stats.highrollers.profitamount")}</span>
          </div>
        </div>

        <div
          style={{
            height: "405px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
          className="high-rollers-data bold"
        >
          {highRollers.length ? (
            highRollers.map((highRoller, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: "10px 0",
                  }}
                  className="high-rolers-data-player my_row space-between"
                >
                  <div
                    className="high-roller-data-player-name width-25 my_row start"
                    style={{ overflow: "hidden" }}
                  >
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>
                      {highRoller.username}
                    </span>
                  </div>
                  <div
                    style={{
                      gap: "5px",
                    }}
                    className="high-rollers-data-betAmmount my_row width-25 justified"
                  >
                    <div className="high-rollers-data-betAmmount">
                      <span>{highRoller.amount}</span>
                    </div>
                    <div className="high-rollers-data-betCurrency">
                      <div
                        style={{
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          className="highrollers-coin-img"
                          style={{
                            height: "1.2em",
                            width: "1.2em",
                            marginLeft: "5px",
                          }}
                          src={`/imgs/AcceptedNetworks/${highRoller.network_id}.webp`}
                          alt="BetCoin"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="high-rollers-data-multiplier width-25 justified secondary_text">
                    <span>{highRoller.cash_out.toFixed(2)}x</span>
                  </div>
                  <div
                    style={{
                      gap: "5px",
                    }}
                    className="high-rollers-data-profit-amount my_row width-25  my_row end"
                  >
                    <div className="high-rollers-data-profit-value">
                      <span
                        style={{
                          color: highRoller.profit > 0 ? "#3bc117" : "#ed6300",
                        }}
                      >
                        {highRoller.profit > 0
                          ? "+" + highRoller.profit
                          : "-" + highRoller.amount}
                      </span>
                    </div>
                    <div className="high-rollers-data-profit-currenct hide-on-720px">
                      <div
                        style={{
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          className="highrollers-coin-img"
                          style={{
                            height: "1.2em",
                            width: "1.2em",
                          }}
                          src={`/imgs/AcceptedNetworks/${highRoller.network_id}.webp`}
                          alt="BetCoin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                flexDirection: "column",
                marginTop: "60px",
              }}
              className="showEmpty-containern justified width"
            >
              <div className="show-empty-img-container">
                <img
                  className="empty-max-width"
                  src="/imgs/empty.png"
                  alt="empty"
                />
              </div>

              <div className="show-empty-typo">
                <span style={{ fontSize: "12px" }} className="text_color">
                  {t("header.referral.oop")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Highrollers;
