import React from "react";
import { useTranslation } from "react-i18next";

const Counter = ({ countDown, seconds, modulus }) => {
  const { t } = useTranslation();
  return (
    <div className="contdownMain">
      <div className="contdownInner">
        <p
          className="countdown-text orbitron-font"
          style={{
            fontSize: "20px",
          }}
        >
          {`${t("gamebox.graph.startsin")} ${countDown} s`}
          {/* {`${t("gamebox.graph.startsin")} ${window.counter} s`} */}
          {/* <div>{seconds + "Seconds"}</div>
          <div>{modulus + "Modulus"}</div> */}
        </p>
      </div>
    </div>
  );
};
export default Counter;
