import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { constants } from "../../../../../store/constants";
const Multiplier = ({ multiplier }) => {
  const [singleContainer, setSingleContainer] = useState(multiplier.toString());
  useEffect(() => {
    setSingleContainer(multiplier.toString());
  }, [multiplier]);
  useEffect(() => {
    setSingleContainer(multiplier.toString().split("."));
  }, [multiplier]);
  const game_state = useSelector((state) => state.game.game_state);
  const { t } = useTranslation();
  return (
    <div>
      {game_state === constants.MULTIPLIER_STOPPED ? (
        multiplier == "0" ? (
          <span
            className="orbitron-font graph-connecting-size"
            style={{
              fontSize: "30px",
              letterSpacing: "3px",
              textAlign: "center",
            }}
          >
            {t("gamebox.graph.connecting")}
          </span>
        ) : (
          <div
            style={{
              color: "orange",
            }}
            className="finalMultiplier bang-on-small-devices my_row"
          >
            <span className="finalMultiplier orbitron-font">
              {t("gamebox.graph.bang")} @
            </span>
            <span>
              <span
                className="multiplier-container orbitron-font"
                style={{
                  fontSize: "32px",
                }}
              >
                {multiplier}
              </span>
            </span>
            <span>x</span>
          </div>
        )
      ) : (
        <span
          className="orbitron-font multiplier--digits"
          style={{
            fontSize: "50px",
            letterSpacing: "3px",
            textAlign: "center",
          }}
        >
          {multiplier == "0" ? (
            <span
              className="graph-connecting-size orbitron-font"
              style={{ fontSize: "30px" }}
            >
              {t("gamebox.graph.connecting")}
            </span>
          ) : (
            <>
              <span className="orbitron-font multiplier-left-digits">
                {singleContainer[0]}
              </span>
              .
              <span className="orbitron-font multiplier-right-digits">
                {singleContainer[1][0]}
                {singleContainer[1][1]}
                <span className="multiplier-x" style={{ fontFamily: "'Source Sans 3', sans-serif", fontSize: "40px" }}>x</span>
              </span>
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default Multiplier;
