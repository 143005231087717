import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { modalsControl } from "../../../../../Contexts/ModalsControl";

const TrendsButton = () => {
  const location = useLocation();
  const [color, setColor] = useState("#60646e");
  const { trendModal, setTrendsModal } = useContext(modalsControl);
  useEffect(() => {
    // location.pathname === "/" ? setColor("#fff") : setColor("#60646e");
    trendModal ? setColor("#fff") : setColor("#60646e");
    return () => {
      setColor("#60646e");
    };
  }, [trendModal]);

  const { t } = useTranslation();
  const trendsActivator = () => {
    trendModal ? setTrendsModal(false) : setTrendsModal(true);
  };
  return (
    <div
      className="desktopTrendsButton"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#60646e",
      }}
      onClick={trendsActivator}
    >
      <Link
        style={{
          margin: "0 2px",
          verticalAlign: "middle",
          textDecoration: "none",
        }}
        // to="/"
      >
        <svg
          style={{
            maxWidth: "20px",
          }}
          id="e59U203AIH61"
          xmlns="http://www.w3.org/2000/svg"
          link="http://www.w3.org/1999/xlink"
          viewBox="0 0 300 300"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          fill={color}
        >
          <g transform="matrix(5.488765 0 0 5.404616-452.436422-405.000067)">
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(90.115286 82.621691)"
              strokeWidth="0"
            />
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(129.400907 82.621691)"
              strokeWidth="0"
            />
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(109.758097 102.690007)"
              strokeWidth="0"
            />
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(109.758097 82.621691)"
              strokeWidth="0"
            />
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(90.115286 122.758327)"
              strokeWidth="0"
            />
            <ellipse
              rx="7.685739"
              ry="7.685739"
              transform="translate(129.400907 122.758327)"
              strokeWidth="0"
            />
            <ellipse
              rx="17.417773"
              ry="19.998074"
              transform="matrix(.441216 0 0 0.384287 90.117503 102.689268)"
              strokeWidth="0"
            />
            <ellipse
              rx="17.417773"
              ry="19.998074"
              transform="matrix(.441216 0 0 0.384287 129.400168 102.689268)"
              strokeWidth="0"
            />
          </g>
        </svg>
        &nbsp;&nbsp;
        <span
          className="orbitron-font"
          style={{
            textTransform: "capitalize",
            color: color,
          }}
        >
          {t("gamebox.graphbar.trends")}
        </span>
      </Link>
    </div>
  );
};

export default TrendsButton;
