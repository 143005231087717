import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../../../../Contexts/windowDimensions";
import { useTranslation } from "react-i18next";

export default function BasicModal() {
  const { height, width } = useWindowDimensions();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width <= 767 ? width : 400,
    background: "#1e2024",
    padding: "25px",
    // height: width <= 767 ? height : "80%",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const hotKeys = [
    {
      operation: t("gamebox.modals.keysmodal.half"),
      key: "A",
    },
    {
      operation: t("gamebox.modals.keysmodal.double"),
      key: "S",
    },
    {
      operation: t("gamebox.modals.keysmodal.betoperation"),
      key: "B",
    },
  ];

  return (
    <div>
      <button className="text_color" onClick={handleOpen}>
        <KeyboardIcon />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals-height-on-mobiles">
          <div className="hotKeys-helpModal-main">
            <div className="hotKeys-helpModal-inner">
              <div className="hotKeys-helpModal-content">
                <div
                  style={{
                    padding: width <= 767 ? "0px" : "0 0px",
                    marginBottom: "20px",
                  }}
                  className="hotKeys-head-container my_row space-between"
                >
                  <span className="orbitron-font">
                    {t("gamebox.modals.keysmodal.hotkeys")}
                  </span>

                  <button className="secondary_text" onClick={handleClose}>
                    <CloseIcon />
                  </button>
                </div>

                {hotKeys.map((key, index) => {
                  return (
                    <div key={index} className="hotKeys-shotcuts-container">
                      <div
                        style={{
                          background: "#17181b",

                          boxSizing: "border-box",
                          padding: "5px 0",
                          margin: "10px 0",
                        }}
                        className="hotKey-shortcut width my_row space-between"
                      >
                        <div
                          style={{
                            width: "79%",
                            height: "100%",
                            borderRight: "1px solid #2c2929",
                            padding: "15px",
                            boxSizing: "border-box",
                            fontSize: "14px",
                          }}
                          className="key-detail"
                        >
                          <span className="text_color">{key.operation}</span>
                        </div>

                        <div className="_key width-20 justified">
                          <span className="white bold">Alt + {key.key}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
