import { useContext } from "react";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import UserProfilesAPI from "../../api/user_profiles";
import { modalsControl } from "../Contexts/ModalsControl";

const UserFeedback = () => {
  const { t, i18n } = useTranslation();
  const [userMessage, setUserMessage] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);
  const { setSignInOpen } = useContext(modalsControl);
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [emptyBox, setEmptyBox] = useState(false);
  const handleSendFeedback = () => {
    if (!currentUser) {
      setSignInOpen(true);
    } else {
      if (!userMessage) {
        setEmptyBox(true);
        setTimeout(() => {
          setEmptyBox(false);
        }, 2000);
      } else {
        UserProfilesAPI.set_user_feedback({
          message: {
            user_id: currentUser.user_id,
            message: userMessage,
          },
        })
          .then((res) => {
            setFeedbackStatus("sent");
            setTimeout(() => {
              setFeedbackStatus("");
            }, 2000);
          })
          .catch((err) => {
            setFeedbackStatus("err");
            setTimeout(() => {
              setFeedbackStatus("");
            }, 2000);
            console.log(err);
          });
      }
    }
  };
  return (
    <div
      style={{
        padding: "45px 0px 20px 0px",
      }}
      className="userFeedback-content-container my_row space-around align-start"
    >
      <div className="userFeedback-typo-container width-45">
        <div
          style={{
            marginBottom: "30px",
          }}
          className="userFeedback-heading-container white"
        >
          <h2
            style={{
              fontSize: "16px",
              fontFamily: "'Orbitron', sans-serif",
              letterSpacing: "1px",
            }}
          >
            {t("feedback.heading1")}
          </h2>
        </div>

        <div className="userFeedback-paragraphs-container text-justify secondary_text">
          <p
            style={{
              marginBottom: "27px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {t("feedback.para1")}
          </p>

          <p
            style={{
              marginBottom: "27px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {t("feedback.para2")}
          </p>
        </div>
      </div>

      <div className="userFeedback-form-container width-45">
        <div
          style={{
            marginBottom: "27px",
          }}
          className="userFeedback-formHeading-container white"
        >
          <h2
            style={{
              fontSize: "16px",
              fontFamily: "'Orbitron', sans-serif",
              letterSpacing: "1px",
            }}
          >
            {t("feedback.formTitle")}
          </h2>
          <textarea
            style={{
              backgroundColor: "#1c1e22",
              border: "none",
              borderRadius: "4px",
              marginTop: "30px",
              width: "93%",
              height: "120px",
              padding: "12px",
              fontSize: "14px",
              lineHeight: "1.5",
            }}
            className="white"
            placeholder={t("feedback.placeHolder")}
            name=""
            id=""
            cols="30"
            rows="10"
            onChange={(e) => {
              setUserMessage(e.target.value);
            }}
          ></textarea>

          <div
            style={{
              marginTop: "15px",
            }}
            className="userFeedback-form-action text-center"
          >
            <button
              style={{
                margin: "0 10px 0 0px",
              }}
              className="primary-btn primary-btn-bg leave-a-message-btn"
              onClick={handleSendFeedback}
            >
              {t("feedback.leaveMessage")}
            </button>
            {feedbackStatus === "sent" && (
              <span className="gc">{t("feedback.feedbackSent")}</span>
            )}
            {feedbackStatus === "err" && (
              <span className="errClr">{t("feedback.feedbackErr")}</span>
            )}
            {emptyBox && (
              <span className="errClr">{t("feedback.emptyBox")}</span>
            )}
            <span
              className="secondary_text"
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                display: "block",
                marginTop: "10px",
              }}
            >
              {t("feedback.getReward")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFeedback;
